import React, { Component } from 'react'

import { connect } from 'kea'
import { Link } from '../components/general/util/Links'
import application from '@otavamedia/om-component-library/lib/kea/application'
import track from 'react-tracking'
import CatchList from '../components/widgets/CatchList'
import WP from '@otavamedia/om-component-library/lib/lib/WP'
import some from 'lodash/some'
import ItemsCarousel from '@otavamedia/om-component-library/lib/ItemsCarousel'
import ArticleCard from '@otavamedia/om-component-library/lib/ArticleCard'
import { ArrowRightPrimaryIcon, CaretLeft, CaretRight } from '../components/widgets/Icons'
import HTMLParser from '@otavamedia/om-component-library/lib/lib/html-parser'

import './Readers.scss'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

export default @track({ gtmContext: ['Readers'] })
@connect({
  actions: [
    application, [
      'setViewData',
      'setRendered'
    ]
  ],
  props: [
    application, [
      'view',
    ],
  ]
})
  /**
   * This renders the /lukijoilta page. It contains some catch cards and listing of articles in a specific category.
   */
class Readers extends Component {
  static propTypes = {
    actions: PropTypes.object,
  }

  loaded = {
    list1: false,
    list2: true,
    list3: true,
    posts: false,
  }

  constructor (props) {
    super(props)
    this.state = { readersPosts: null }
    this.loadPosts()
  }

  async loadPosts () {
    const readers = await WP.getFromPostTypes(['post', 'om_digimag_post', 'om_theme_article'], {
      categories: 18,
    })
    const readersPosts = readers.data
    this.setState({ readersPosts }, () => this.doneLoading('posts'))
  }

  doneLoading (element) {
    if (this.loaded[element]) {
      return
    }
    this.loaded[element] = true
    if (!some(Object.values(this.loaded), (x) => !x)) {
      this.props.actions.setRendered(true)
    }
  }

  renderCategory (posts) {
    return <ItemsCarousel
      initialSlide={0}
      ItemComponent={ArticleCard}
      carouselSettings={{
        responsive:
          [{
            breakpoint: 9999,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              speed: 300,
            },
          }]
      }}
      arrowLeft={<CaretLeft color="blue"/>}
      arrowRight={<CaretRight color="blue"/>}
      itemProps={posts.map((post, idx) =>
        ({
          key: post.id,
          article: post,
          isLast: idx === posts.length - 1,
          LinkComponent: Link,
          htmlParser: HTMLParser.render,
          notShown: { ingress: true, topic: true, createdDate: true },
          displaySubscriberIcon: false,
          label: { to: {}, text: post.author.name }
        })
      )}
    />
  }

  render () {
    const { readersPosts } = this.state

    if (!readersPosts) {
      return null
    }
    return <div styleName="readers">
      <Helmet>
        <title>
          Lukijoilta - {window.om_constants.siteName}
        </title>
        <meta name="http:status" content="200"/>
      </Helmet>
      <h1>Kymppikerhot</h1>
      <CatchList cardsPerPage={4} type="fish" showHighlight={true} doneLoading={() => this.doneLoading('list1')}/>
      <div styleName="cta">
        <h3>Ilmoita hauki</h3>
        <p>Ilmoita mitat ja lähetä kuva saaliistasi ja osallistu haukien kymppikerhoon ja/tai mittakalakerhoon.
          Haukien kymppikerho käynnistyi vuonna 1989. Perinteisen kymppikerhon rinnalla on ollut vuodesta 2017
          mittakalakerho, johon hyväksytään vähintään 110-senttiset vapautetut hauet.<br/>
        <Link to={{ link: '/kymppikerhon-saannot/' }}><ArrowRightPrimaryIcon /> Kerhon säännöt</Link>
        </p>
        <Link to={{ link: '/kymppikerho-lomake' }} styleName="blue-button">Ilmoita haukesi</Link>
      </div>
      <CatchList cardsPerPage={4} type="elk" showHighlight={true} doneLoading={() => this.doneLoading('list2')}/>
      <div styleName="cta">
        <h3>Ilmoita kymppihirvi</h3>
        <p>Ilmoita vähintään 10-piikkisen hirvesi tiedot ja lähetä kuva kaadosta. Kerho on perustettu vuonna 2004.
          <br/><Link to={{ link: '/hirvikerhon-saannot/' }}><ArrowRightPrimaryIcon /> Kerhon säännöt</Link>
        </p>
        <Link to={{ link: '/hirvikerho-lomake' }} styleName="blue-button">Ilmoita hirvesi</Link>
      </div>
      <CatchList cardsPerPage={4} type="deer" showHighlight={true} doneLoading={() => this.doneLoading('list3')}/>
      <div styleName="cta">
        <h3>Ilmoita kymppipeura</h3>
        <p>Ilmoita vähintään 10-piikkisen peurasi tiedot ja lähetä kuva kaadosta. Kerho on perustettu vuonna 2008.
          <br/><Link to={{ link: '/peurakerhon-saannot/' }}><ArrowRightPrimaryIcon /> Kerhon säännöt</Link>
        </p>
        <Link to={{ link: '/peurakerho-lomake' }} styleName="blue-button">Ilmoita peurasi</Link>
      </div>
      <div styleName="reader-stories">
        <h2>Lukijoiden tarinat</h2>
        {this.renderCategory(readersPosts)}
      </div>
    </div>
  }
}
