import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import HTML from '@otavamedia/om-component-library/lib/util/HTML'
import { NavLink } from '../general/util/Links'
import { ArrowRightPrimaryIcon } from '../widgets/Icons'
import '../navigation/WeeklyMagazineContentsMenu.scss'
import track from 'react-tracking'

/**
 * TVTM Table Of Contents component
 **/
class ThemeTOC extends Component {
  renderVignetteAndTopic = item => {
    if (item.isEquation) {
      return <span styleName="vignette">Puheenaihe </span>
    }

    if (item.vignette && item.topic) {
      return (
        <Fragment>
          <span styleName="vignette"><HTML>{item.vignette}</HTML> </span>
          <span><HTML>{item.topic}</HTML> &mdash; </span>
        </Fragment>
      )
    } else if (item.vignette && !item.topic) {
      return <span styleName="vignette"><HTML>{item.vignette}</HTML> </span>
    } else {
      return null
    }
  }

  render () {
    const { onDarkBackground, contents } = this.props
    if (!this.props.contents || !this.props.contents[0]) {
      return null
    }
    const articles = contents
      .reduce((retList, section) => {
        return retList.concat(section.articles)
      }, [])
      .sort((a, b) => a.date < b.date ? 1 : -1)
      .slice(0, 5)

    return <Fragment>
      <ul styleName={onDarkBackground ? 'toc-list dark' : 'toc-list'}>
        {articles.map((item) => {
          return (
            <li styleName="article-link" key={`toc-item-${item.link}`}>
              <NavLink to={item}>
                <ArrowRightPrimaryIcon/>
                <span styleName="title">{this.renderVignetteAndTopic(item)}<HTML>{item.title}</HTML></span>
              </NavLink>
            </li>
          )
        })}
      </ul>
    </Fragment>
  }
}

ThemeTOC.propTypes = {
  contents: PropTypes.array.isRequired,
  addMainSectionTitle: PropTypes.bool,
  onDarkBackground: PropTypes.bool,
}

export default track({ gtmContext: ['ThemeTOC'] })(ThemeTOC)
