import React, { Component } from 'react'
import PropTypes from 'prop-types'

import TableOfContents from '../weeklyMagazine/TableOfContents'
import DigimagHero from './DigimagHero'
import { formatPublishDate } from '@otavamedia/om-component-library/lib/lib/utils'
import Modal from 'react-modal'
import { CrossIcon } from '@otavamedia/om-component-library/lib/Icons'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import merge from 'lodash/merge'
import { NavLink } from '../general/util/Links'
import { TextBlock, RectShape } from 'react-placeholder/lib/placeholders'
import Image from '../general/util/Image'
import { IMAGE_SIZE } from '@otavamedia/om-component-library/lib/entities/ImageModel'

import './TableOfContentsModal.scss'
import { connect } from 'kea'
import magazineStore from '@otavamedia/om-component-library/lib/kea/weeklyMagazine'
import WP from '@otavamedia/om-component-library/lib/lib/WP'

const modalStyle = (compact = false) => {
  const shared = {
    overlay: {
      background: 'rgba(0, 0, 0, 0.85)',
    },
    content: {
      border: 'none',
      borderRadius: 0,
      boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.1)',
      position: 'static',
      margin: '40px auto 0',
      padding: 0,
    }
  }

  const mobile = {
    overlay: {
      top: 0,
      bottom: 0,
    },

    content: {
      maxWidth: compact ? '680px' : '1440px',
    }
  }

  const desktop = {
    overlay: {
      top: 0,
    },

    content: {
      maxWidth: compact ? '680px' : '1440px', // Not very mobile friendly
    },
  }

  return window.innerWidth <= 991 ? merge({}, shared, mobile) : merge({}, shared, desktop)
}

export default @connect({
  props: [
    magazineStore, [
      'previousMagazines',
      'nextMagazines',
    ],
  ]
})
class ToCModal extends Component {
  static propTypes = {
    digimag: PropTypes.object.isRequired,
    toggleModal: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    previousMagazines: PropTypes.array,
    nextMagazines: PropTypes.array,
    compact: PropTypes.bool,
    isBrowsable: PropTypes.bool,
    showTM: PropTypes.bool,
  }

  static defaultProps = {
    compact: false,
    loading: false,
    showTM: true
  }

  constructor (props) {
    super()
    this.state = this.resetToLatestMagazine(props)
  }

  resetToLatestMagazine (props) {
    return {
      previousMagazines: props.previousMagazines,
      nextMagazines: props.nextMagazines,
      digimag: props.digimag,
      prevMag: this.prevMagazine(props.previousMagazines),
      nextMag: this.nextMagazine(props.nextMagazines)
    }
  }

  UNSAFE_componentWillReceiveProps (newProps) {
    if (newProps.open && !this.props.open) {
      this.setState(this.resetToLatestMagazine(newProps))
    }
  }

  prevMagazine = (previousMagazines) => {
    if (!previousMagazines || !previousMagazines.length) return null
    return previousMagazines[previousMagazines.length - 1]
  }

  nextMagazine = (nextMagazines) => {
    if (!nextMagazines || !nextMagazines.length || !nextMagazines[0].isPublished) return null
    return nextMagazines[0]
  }

  async getMagazine (slug) {
    this.setState({ loading: true })
    const magazine = await WP.getMagazine(slug)
    this.setState({
      digimag: magazine,
      loading: false
    })
  }

  getRightColumnArticles () {
    const { digimag } = this.state
    const contents = digimag

    const primary = { ...(get(contents, 'toc[0].articles[0]', {})) }
    const topic = {
      ...(contents.toc || []).reduce((result, section) => {
        return section.articles.find(article => article.isEquation) || result
      }, {})
    }
    const columns = [{
      title: false,
      articles: (contents.toc || []).reduce((result, section) => {
        return [
          ...result,
          ...section.articles.reduce((sectionResult, article) => {
            return article.inTocSidebar
              ? [...sectionResult, article]
              : sectionResult
          }, [])
        ]
      }, [])
    }]

    return { primary, topic, columns }
  }

  getParentNode () {
    return document.querySelector('div.App_app-entry-point')
  }

  closeModal () {
    this.setState(this.resetToLatestMagazine(this.props))
    this.props.toggleModal()
  }

  render () {
    const { open, compact, isBrowsable, showTM } = this.props
    const { digimag, prevMag, nextMag, loading } = this.state
    if (!digimag) return null
    const { toc } = digimag || { toc: null }

    const isAdMag = get(digimag, 'term.taxonomy') === 'om_ad_magazine'

    if (!open) {
      return null
    }

    const { primary, topic, columns } = this.getRightColumnArticles()

    return (
      <Modal
        isOpen={open}
        onRequestClose={() => this.closeModal()}
        style={modalStyle(compact)}
        contentLabel="Sisällysluettelo"
      >
        <div styleName={`modal-content ${compact ? 'compact' : ''} ${isAdMag ? ' is-ad' : ''}`}>
          <header styleName="header">
            <div styleName="title">
              <span>{formatPublishDate(digimag.publishDate)}</span>
              <h2>
                {isAdMag
                  ? `Mainos: ${digimag.name}`
                  : `${showTM ? window.om_constants.abbreviation : ''} ${digimag.name}`
                }
              </h2>
            </div>

            <button styleName="close" onClick={() => this.closeModal()}>
              Sulje

              <CrossIcon color="004f9d" width={24} height={24} />
            </button>
          </header>

          <div styleName="scroll-container">
            <div styleName="flex-container">
              <div>
                {loading
                  ? <div styleName="hero-loader">
                    <div>
                      <RectShape style={{ width: '100%', height: '100%' }}/>
                      <TextBlock rows={3} color='grey'/>
                    </div>
                  </div>
                  : <DigimagHero digimag={digimag} toc={true} showTM={showTM} closeParent={() => this.closeModal()}
                    mode={compact ? 'compact' : 'minimal'}
                    prevLink={!!prevMag && isBrowsable && (() => this.getMagazine(prevMag.slug))}
                    nextLink={!!nextMag && isBrowsable && (() => this.getMagazine(nextMag.slug))}/>}
              </div>

              <div styleName="toc-container">
                {loading ? <TextBlock rows={30} color='grey'/> : <TableOfContents contents={toc} />}
              </div>

              {!loading && <div styleName="sidebar-container">
                <NavLink styleName="primary-article" to={primary}>
                  <h2>{primary.title}</h2>
                  <Image data={primary.image} size={IMAGE_SIZE.MEDIUM_LARGE} sizes={'700px'}/>
                  <p>{primary.description}</p>
                </NavLink>

                {!isEmpty(topic) && (
                  <NavLink styleName="topic-article sidebar-block" to={topic}>
                    <h2 styleName="sidebar-header">Puheenaihe</h2>
                    <p>{topic.description}</p>
                  </NavLink>
                )}

                {columns && columns[0].articles && columns[0].articles.length
                  ? (
                    <div styleName="sidebar-block">
                      <h2 styleName="sidebar-header">Palstat</h2>
                      <TableOfContents contents={columns} />
                    </div>
                  )
                  : null}
              </div>}
            </div>
          </div>
        </div>
      </Modal>
    )
  }
}
