import React, { Component } from 'react'
import ClubForm from './ClubForm'

/**
 * Displays a form for the Fish club (haukikerho)
 */
export default class FishForm extends Component {
  render () {
    return <ClubForm formPages={formPages} type="fish" texts={texts}/>
  }
}

const texts = {
  intro: {
    smallTitle: 'Ilmoita saalis',
    mainTitle: 'Haukien 10-kerho ja mittakalakerho',
    ingress: 'Ilmoita haukesi pyyntivuotta seuraavan tammikuun loppuun mennessä.',
    text: 'Kysymme lomakkeessa yhteystietoja, jotta voimme lähettää kerhopinssin ja kerhovieheen ilmoittamaasi osoitteeseen.'
  },
  outro: {
    mainTitle: 'Kiitos ilmoittautumisestasi!'
  }
}

const formPages = [
  [ // page 1
    {
      topHeader: 'Kalastajan yhteystiedot',
      title: 'Haukien 10-kerho ja mittakalakerho',
      items: [
        {
          name: 'Nimi',
          field: 'kalastajan_nimi',
          noLabel: true,
          required: true,
        },
        {
          name: 'Puhelinnumero',
          field: 'puhelinnumero',
          noLabel: true,
          required: true,
          pattern: '^[0-9-+ ]+$'
        },
        {
          name: 'Sähköpostiosoite',
          field: 'sahkopostiosoite',
          noLabel: true,
          required: true,
          pattern: '^.+@.+\\..+$'
        },
        {
          name: 'Lähiosoite',
          field: 'lahiosoite',
          noLabel: true,
          required: true,
        },
        {
          type: 'row',
          items: [
            {
              name: 'Postinumero',
              field: 'postinumero',
              style: { width: '40%' },
              noLabel: true,
              required: true,
            },
            {
              name: 'Postitoimipaikka',
              field: 'postitoimipaikka',
              style: { width: '60%' },
              noLabel: true,
              required: true,
            },
          ]
        }
      ],
    },
  ],
  [ // page 2
    {
      topHeader: 'Saalistiedot',
      items: [
        {
          name: 'Ilmoitan saaliin (voit valita molemmat)',
          type: 'multiselect',
          field: 'kerhovalinta',
          required: true,
          items: [
            {
              name: 'Haukien 10-kerhoon',
              type: 'checkbox',
              field: 'kymppikerho'
            },
            {
              name: 'Mittakalakerhoon',
              type: 'checkbox',
              field: 'mittakalakerho'
            }
          ]
        },

      ],
    },
    {
      topHeader: 'Pyyntipaikka',
      title: 'Hauen tiedot',
      items: [
        {
          type: 'row',
          items: [
            {
              name: 'Paino',
              field: 'paino',
              style: { width: '50%' },
              required: true,
              unit: 'kg',
              pattern: '^[0-9]+([,.][0-9]+)?$',
              match: ',',
              replace: '.',
            },
            {
              name: 'Pituus',
              field: 'pituus',
              style: { width: '50%' },
              required: true,
              pattern: '^[0-9]+([,.][0-9]+)?$',
              match: ',',
              replace: '.',
              unit: 'cm'
            },
          ]
        },
        {
          name: 'Suurin ympärysmitta',
          field: 'suurin_ymparysmitta',
          unit: 'cm',
          pattern: '^[0-9]+([,.][0-9]+)?$',
          match: ',',
          replace: '.',
        },
        {
          name: 'Catch & release',
          type: 'radio-block',
          field: 'catch_release',
          options: [{ name: 'Kyllä', value: '1' }, { name: 'Ei', value: '0' }],
          required: true,
        },
        {
          type: 'row',
          items: [

            {
              name: 'Saantipäivä',
              field: 'saantipaiva',
              type: 'date',
              required: true,
              style: { width: '50%' },
            },
            {
              name: 'Kellonaika',
              field: 'saantiaika',
              style: { width: '50%' },
              pattern: '^[0-9]{1,2}[.:][0-9]{1,2}$',
              match: '.',
              replace: ':'
            },
          ]
        },
        {
          name: 'Saantitapa',
          type: 'select',
          field: 'saantitapa',
          required: true,
          options: ['Heitto', 'Moottoriuistelu', 'Soutu-uistelu', 'Onki', 'Pilkki', 'Perho']
        },
        {
          name: 'Sää',
          type: 'select',
          field: 'saa',
          required: false,
          options: ['Aurinkoinen', 'Pilvinen']
        },
        {
          name: 'Sade',
          type: 'select',
          field: 'sade',
          required: false,
          options: ['Poutaa', 'Sadetta']
        },
        {
          name: 'Tuulen suunta',
          field: 'tuuli',
          type: 'select',
          options: ['Koillinen', 'Itä', 'Kaakko', 'Etelä', 'Lounas', 'Länsi', 'Luode', 'Pohjoinen']
        },
        {
          name: 'Tuulen nopeus (m/s)',
          field: 'tuulennopeus',
        },
        {
          name: 'Ilman lämpötila',
          field: 'ilmanlampo',
        },
        {
          name: 'Veden lämpötila',
          field: 'vedenlampo',
        },
      ]
    },
    {
      title: 'Vieheen tiedot',
      items: [
        {
          name: 'Vieheen nimi',
          field: 'vieheen_nimi',
          required: true,
        },
        {
          type: 'row',
          items: [
            {
              name: 'Paino',
              field: 'vieheen_paino',
              style: { width: '50%' },
              pattern: '^[0-9]+([,.][0-9]+)?$',
              match: ',',
              replace: '.',
              unit: 'g'
            },
            {
              name: 'Pituus',
              field: 'vieheen_pituus',
              style: { width: '50%' },
              pattern: '^[0-9]+([,.][0-9]+)?$',
              match: ',',
              replace: '.',
              unit: 'mm'
            },
          ]
        },
        {
          name: 'Vieheen pääväri',
          field: 'vieheen_paavari'
        },
        {
          name: 'Vieheen muut värit',
          field: 'vieheen_muut_varit'
        },
      ]
    }
  ],
  [ // page 3
    {
      topHeader: 'Pyyntipaikka',
      items: [
        {
          name: 'Vesistö',
          type: 'radio-block',
          field: 'vesisto',
          options: [
            { name: 'Meri', value: 'Meri' },
            { name: 'Järvi', value: 'Järvi' },
            { name: 'Joki', value: 'Joki' }
          ],
          required: true,
        },
        {
          name: 'Anna tarkempi paikkatieto',
          type: 'checkbox',
          field: 'pyyntipaikka',
          items: [
            {
              name: 'Koordinaatit',
              type: 'map',
              field: 'koordinaatit'
            },
          ]
        },
        {
          name: 'Pyyntipaikan lisätiedot',
          type: 'textarea',
          field: 'pyyntipaikan_lisatiedot'
        }
      ]
    }
  ],
  [ // page 4
    {
      topHeader: 'Saaliskuvat',
      items: [
        {
          name: 'Lisää saaliskuvat',
          type: 'images',
          field: 'kuvat',
          required: true,
        },
        {
          name: 'Tapahtuman lisätiedot',
          type: 'textarea',
          field: 'lisatiedot'
        }
      ]
    }
  ]
]
