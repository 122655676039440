import React, { Component } from 'react'
import PropTypes from 'prop-types'

import './Settings.scss'
import { requestCache } from '@otavamedia/om-component-library/lib/lib/DiskStorage'

export default class SettingsComponent extends Component {
  static propTypes = {
    settings: PropTypes.object.isRequired,
    updateSettings: PropTypes.func.isRequired,
  }

  toggleCaching () {
    const { settings, updateSettings } = this.props
    const invert = !settings.caching

    if (!invert) { // If cache will be disabled, clear it also
      console.log('Clearing cache')
      requestCache.clear()
    }

    updateSettings({
      ...settings,
      caching: !settings.caching,
    })
  }

  render () {
    const { settings } = this.props
    const { caching } = settings

    return (
      <div styleName="settings">
        <button onClick={() => this.toggleCaching()}>
          {caching ? 'Disable cache' : 'Enable cache'}
        </button>

        <button onClick={() => requestCache.clear()}>
          Clear cache
        </button>
      </div>
    )
  }
}
