import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from '../util/Links'
import Image from '../util/Image'
import HTML from '@otavamedia/om-component-library/lib/util/HTML'
import { ErrorPlaceholderBox, withErrorBoundary } from '../util/ErrorBoundaries'
import './SpecialArticleListItem.scss'
import { IMAGE_SIZE } from '@otavamedia/om-component-library/lib/entities/ImageModel'

class SpecialArticleListItem extends Component {
  static propTypes = {
    title: PropTypes.any,
    article: PropTypes.object,
    context: PropTypes.any,
  }

  constructor (props) {
    super(props)
    this.state = { article: null }
  }

  render () {
    const { title, article } = this.props

    return article
      ? (
        <div key={article.id} styleName="sizing-box">
          <article styleName="article-list-item">
            <div styleName="item-image">
              <Link to={article}>
                {article.featuredMedia
                  ? <Image data={article.featuredMedia} size={IMAGE_SIZE.MEDIUM_LARGE} sizes={'350px'}/>
                  : null
                }
                <div styleName="overlay"/>
                {title ? <div styleName="title">{title}</div> : null}
              </Link>
            </div>
            <div styleName="article-content">
              <Link to={article}>
                <h2><HTML>{article.title}</HTML></h2>
              </Link>
            </div>
          </article>
        </div>
      )
      : null
  }
}

export default withErrorBoundary(
  SpecialArticleListItem,
  ErrorPlaceholderBox('Artikkelia ei saatu ladattua'),
)
