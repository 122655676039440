import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ConnectedAdSlot, { AD_DISPLAY_TYPE } from './ConnectedAdSlot'
import Breakpoint from '@otavamedia/om-component-library/lib/util/Breakpoint'
import { breakpoints } from '@otavamedia/om-component-library/lib/lib/utils'

// eslint-disable-next-line react/prop-types
export const AdsForDesktop = (props) => {
  return (
    <Breakpoint minBreakpoint={breakpoints.lg}>
      {props.children}
    </Breakpoint>
  )
}

AdsForDesktop.propTypes = {
  children: PropTypes.node,
  firstAd: PropTypes.bool,
  sizes: PropTypes.array
}

// eslint-disable-next-line react/prop-types
export const AdsForMobile = (props) => {
  return (
    <Breakpoint maxBreakpoint={breakpoints.lg - 1}>
      {props.children}
    </Breakpoint>
  )
}

AdsForMobile.propTypes = {
  children: PropTypes.node,
}

// eslint-disable-next-line react/prop-types
export const NativeAdSlot1 = (props) => {
  return (
    <ConnectedAdSlot
      slotId='native-card-1'
      adUnit='native-card-1'
      sizes={[[1, 1], ['fluid']]}
      displayType={AD_DISPLAY_TYPE.EXPAND}
      {...props}
      adContextOverride="native"
      noId={true}
    />
  )
}
// eslint-disable-next-line react/prop-types
export const NativeAdSlot2 = (props) => {
  return (
    <ConnectedAdSlot
      slotId='native-card-2'
      adUnit='native-card-2'
      sizes={[[1, 1], ['fluid']]}
      displayType={AD_DISPLAY_TYPE.EXPAND}
      {...props}
      adContextOverride="native"
      noId={true}
    />
  )
}

// eslint-disable-next-line react/prop-types
export const AdSlotDesk1 = (props) => {
  return <ConnectedAdSlot
    minSize={728}
    slotId='desk-1'
    adUnit='desk-1'
    sizes={[[980, 552], [980, 400], [980, 120], [728, 90]]}
    displayType={AD_DISPLAY_TYPE.EXPAND}
    {...props}
  />
}

// eslint-disable-next-line react/prop-types
export const AdSlotDesk2 = (props) => {
  return <ConnectedAdSlot
    minSize={728}
    slotId='desk-2'
    adUnit='desk-2'
    sizes={[[980, 400], [980, 120], [728, 90]]}
    displayType={AD_DISPLAY_TYPE.EXPAND}
    {...props}
  />
}

// eslint-disable-next-line react/prop-types
export const AdSlotInText = (props) => {
  return <ConnectedAdSlot
    minSize={300}
    slotId='in-text'
    adUnit='in-text'
    sizes={[[300, 250]]}
    displayType={AD_DISPLAY_TYPE.EXPAND}
    {...props}
  />
}

// eslint-disable-next-line react/prop-types
export const AdSlotInTextWide = (props) => {
  const sizes = props.firstAd ? [[620, 891], [468, 400], [300, 250]] : [[468, 400], [300, 250]]
  return <ConnectedAdSlot
    minSize={300}
    slotId='in-text'
    adUnit='in-text'
    sizes={sizes}
    displayType={AD_DISPLAY_TYPE.EXPAND}
    {...props}
  />
}

AdSlotInTextWide.propTypes = {
  firstAd: PropTypes.bool,
}
// eslint-disable-next-line react/prop-types
export const AdSlotRectangle1 = (props) => {
  return <ConnectedAdSlot
    minSize={300}
    slotId='rectangle-1'
    adUnit='rectangle-1'
    sizes={props.sizes || [[300, 750], [300, 600], [300, 500], [300, 300], [300, 250]]}
    displayType={AD_DISPLAY_TYPE.EXPAND}
    {...props}
  />
}

AdSlotRectangle1.propTypes = {
  sizes: PropTypes.array,
}

// eslint-disable-next-line react/prop-types
export const AdSlotRectangle2 = (props) => {
  return <ConnectedAdSlot
    minSize={300}
    slotId='rectangle-2'
    adUnit='rectangle-2'
    sizes={[[300, 750], [300, 600], [300, 500], [300, 300], [300, 250]]}
    displayType={AD_DISPLAY_TYPE.EXPAND}
    {...props}
  />
}

// eslint-disable-next-line react/prop-types
export const AdSlotSkyscraper1 = (props) => {
  return <ConnectedAdSlot
    minSize={160}
    slotId='sky-scraper-1'
    adUnit='sky-scraper-1'
    sizes={[[160, 600], [140, 350], [300, 600]]}
    displayType={AD_DISPLAY_TYPE.EXPAND}
    {...props}
  />
}

// eslint-disable-next-line react/prop-types
export const AdSlotMob1 = (props) => {
  return <ConnectedAdSlot
    minSize={300}
    slotId='mob-1'
    adUnit='mob-1'
    sizes={[[300, 300], [300, 250], [300, 150], [300, 600], [300, 431]]}
    displayType={AD_DISPLAY_TYPE.EXPAND}
    {...props}
  />
}

// eslint-disable-next-line react/prop-types
export const AdSlotMob2 = (props) => {
  return <ConnectedAdSlot
    minSize={300}
    slotId='mob-2'
    adUnit='mob-2'
    sizes={[[300, 300], [300, 250], [300, 150], [300, 431]]}
    displayType={AD_DISPLAY_TYPE.EXPAND}
    {...props}
  />
}

export const AdSlotArticleBody = (props) => {
  return <AdSlotInTextWide firstAd={props.firstAd}/>
}

AdSlotArticleBody.propTypes = {
  firstAd: PropTypes.bool,
}

// eslint-disable-next-line react/prop-types
export const AdSlotArticleBodyMobileOnly = ({ AdComponent }) => {
  // Breakpoint 1: Tablet width (two columns w/ sidebar) ad column width may wary based on in text elements
  // Breakpoint 2: Mobile width (single column w/o sidebar) ad should always have full column width in use
  return <Fragment>
    <Breakpoint minBreakpoint={breakpoints.md} maxBreakpoint={breakpoints.lg - 1}>
      <AdComponent loadInstantly={false}/>
    </Breakpoint>
    <Breakpoint maxBreakpoint={breakpoints.md - 1}>
      <AdComponent/>
    </Breakpoint>
  </Fragment>
}

export const AdSlotArticleBodyMob1 = () => {
  return <AdSlotArticleBodyMobileOnly AdComponent={AdSlotMob1}/>
}
