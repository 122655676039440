import React from 'react'
import PropTypes from 'prop-types'
import HTML from '@otavamedia/om-component-library/lib/util/HTML'

import './CrossLinkTopNotification.scss'

const CrossLinkTopNotification = ({ text }) => {
  return (<div styleName="main">
    <HTML>{text}</HTML>
  </div>)
}

CrossLinkTopNotification.propTypes = {
  text: PropTypes.string,
}

export default CrossLinkTopNotification
