import React, { Component } from 'react'
import PropTypes from 'prop-types'
import HTML from '@otavamedia/om-component-library/lib/util/HTML'
import './WeightTable.scss'

class WeightTable extends Component {
  static propTypes = {
    data: PropTypes.object
  }

  render () {
    const { data } = this.props
    if (!data) {
      return null
    }

    const { otsikko: title, kuvaus: description, taulukko: table, vertailuteksti: text } = data

    return <div className="weight-table">
      <h3><HTML>{title}</HTML></h3>
      <p><HTML>{description}</HTML></p>
      <HTML>{table}</HTML>
      <HTML>{text}</HTML>
    </div>
  }
}

export default WeightTable
