import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { connect } from 'kea'
import DfpAdSlot from './DfpAdSlot'
import { ErrorPlaceholderBox, withErrorBoundary } from '../util/ErrorBoundaries'
import './DfpAdSlot.scss'
import AD_CONTEXT from './AdContext'
import adsLogic from '@otavamedia/om-component-library/lib/kea/ads'
import AdDebugInfoBox from './AdDebugInfoBox'
import { isHeadless } from '@otavamedia/om-component-library/lib/util/env'

// ad counter for unique id to refresh (remount) DfpAdSlot component
let counter = 0

export const AD_DISPLAY_TYPE = {
  ALWAYS: 'always',
  EXPAND: 'expand', // expand the slot only when ad has been loaded
  COLLAPSE: 'collapse', // collapse the slot if no ad was received
}

const dfpNetworkId = window.om_constants.dfpNetworkId
const containerName = window.om_constants.containerName

@connect({
  props: [
    adsLogic, [
      'adRefreshKey',
      'adContext',
      'adsEnabled',
      'debugAds',
    ],
  ],
})
class ConnectedAdSlot extends Component {
  static propTypes = {
    slotId: PropTypes.string.isRequired,
    adUnit: PropTypes.string.isRequired,
    sizes: PropTypes.array.isRequired,
    displayType: PropTypes.string,
    adContext: PropTypes.string,
    adContextOverride: PropTypes.string,

    minSize: PropTypes.number,
    adRefreshKey: PropTypes.string,
    debugName: PropTypes.string,
    loadInstantly: PropTypes.bool,
    adsEnabled: PropTypes.bool,
    debugAds: PropTypes.bool,
    noId: PropTypes.bool,
    fallBackElements: PropTypes.array,
  }

  static defaultProps = {
    displayType: AD_DISPLAY_TYPE.ALWAYS,
    loadInstantly: false,
  }

  _mounted = false
  asyncSetState (...args) {
    // avoid setting state after unmount in case async operations attempts to do so
    if (this._mounted) this.setState(...args)
  }

  initPath
  initTimestamp
  lifetimeWarningInterval = 1000 // ms
  loadTimeout

  constructor (props) {
    super()
    // console.log('ConnectedAdSlot: Constructing  ' +props.adUnit + '_' + props.adUnitPath )
    this.initPath = window.location.pathname
    this.initTimestamp = Date.now()

    counter++

    this.state = {
      loading: true,
      empty: false,
      adBlocker: true,
      readyToShowAd: false,
      sizes: props.sizes
    }
  }

  componentDidMount () {
    // console.log(this.props.adUnit, ' ConnectedAdSlot componentDidMount ', this.props.adRefreshKey, this.props.adContext)
    this._mounted = true
    if (!this.props.minSize || (this.element && this.element.offsetWidth >= this.props.minSize)) {
      // Filter out ad sizes that won't fit in this ad slot
      let sizes = this.state.sizes
      sizes = sizes.filter((size) => !Number.isInteger(size[0]) || this.element.offsetWidth >= size[0])
      this.loadTimeout = setTimeout(() => {
        // console.log(this.props.adUnit, ' loadTimeout ', this.props.adRefreshKey, this.props.adContext)
        this.setState({ readyToShowAd: true, sizes })
        this.loadTimeout = null
      }, 200)
    }
    if (isHeadless() || (window.googletag && window.googletag.pubadsReady && window.cxSegmentIds)) {
      this.setState({ adBlocker: false })
    } else {
      const interval = setInterval(() => window.googletag && window.googletag.pubadsReady && window.cxSegmentIds &&
        this.setState({ adBlocker: false }) && clearInterval(interval), 1000)
    }
  }

  componentWillUnmount () {
    // console.log(this.props.adUnit, ' ConnectedAdSlot componentWillUnmount ')
    this._mounted = false
    // try to check if ad is being rapidly unmounted within the same location
    if (this.initPath === window.location.pathname && Date.now() - this.initTimestamp < this.lifetimeWarningInterval) {
      const uniqueId = this.getUniqueId()
      console.warn(`Ad slot '${uniqueId}' was unmounted just after it's mount. This may be caused by unnecessary renders and will negatively impact ad stats.`)
    }
  }

  deepCompare (obj1, obj2, path = '') {
    // Check if both objects are the same type
    if (typeof obj1 !== typeof obj2) {
      return [{ path, value1: obj1, value2: obj2 }]
    }

    // Check if the objects are primitive types or null
    if (obj1 === null || obj2 === null || typeof obj1 !== 'object' || typeof obj2 !== 'object') {
      if (obj1 !== obj2) {
        return [{ path, value1: obj1, value2: obj2 }]
      } else {
        return []
      }
    }

    // Compare the number of properties
    const keys1 = Object.keys(obj1)
    const keys2 = Object.keys(obj2)
    if (keys1.length !== keys2.length) {
      return [{ path, value1: obj1, value2: obj2 }]
    }

    let differences = []

    // Recursively compare each property
    for (const key of keys1) {
      const newPath = path ? `${path}.${key}` : key
      const propertyDifferences = this.deepCompare(obj1[key], obj2[key], newPath)
      differences = differences.concat(propertyDifferences)
    }

    return differences
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    // console.log('ConnectedAdSlot: ' + this.props.adUnit + ' UNSAFE_componentWillReceiveProps ')
    // console.log(this.getUniqueId() + ': ' + JSON.stringify(this.deepCompare(this.props, nextProps)))
    if (nextProps.adRefreshKey !== this.props.adRefreshKey && nextProps.adRefreshKey !== this.state.refreshKey) {
      // console.log('ConnectedAdSlot: ' + this.props.adUnit + ' updating refreshkey ')
      // this.setState({ readyToShowAd: true, sizes })
      this.incrementAdCounter()
    }
  }

  shouldComponentUpdate (nextProps, nextState) {
    /*
    if (nextProps.adRefreshKey !== this.props.adRefreshKey || nextProps.adContext !== this.props.adContext) {
      console.log('ConnectedAdSlot: ' + this.props.adUnit + ' shouldComponentUpdate? yes ')
    } else {
      console.log('ConnectedAdSlot: ' + this.props.adUnit + ' shouldComponentUpdate? No ')
    }
     */
    const shouldUpdate = nextProps.adRefreshKey !== this.props.adRefreshKey || nextProps.adContext !== this.props.adContext ||
      nextState.readyToShowAd !== this.state.readyToShowAd || nextState.loading !== this.state.loading
    // console.log(this.props.adUnit, ' shouldComponentUpdate ', shouldUpdate, nextProps.adRefreshKey !== this.props.adRefreshKey, nextProps.adContext !== this.props.adContext, nextState.readyToShowAd !== this.state.readyToShowAd)
    return shouldUpdate
  }

  incrementAdCounter () {
    counter++
    this.setState({
      loading: true,
      empty: false,
    })
  }

  getAdUnitPath () {
    const { adUnit, adContext, adContextOverride } = this.props
    return dfpNetworkId + '/' + containerName + '/' + (adContextOverride || adContext) + '/' + adUnit
  }

  getUniqueId () {
    const { debugName, slotId } = this.props
    const prefix = debugName ? debugName + '_ad_' : 'ad_'
    return prefix + slotId + '_' + counter
  }

  render () {
    const { empty, adBlocker, readyToShowAd, sizes } = this.state
    const {
      slotId,
      adContext,
      loadInstantly,
      adsEnabled,
      debugAds,
      adUnit,
      noId
    } = this.props
    const FallBackElement = this.props.fallBackElements ? this.props.fallBackElements[0] : null
    const FallBackElement2 = this.props.fallBackElements ? this.props.fallBackElements[1] : null
    // console.log('ConnectedAdSlot: ' +adUnit + ' connectedAdSlot rendering')
    if (!adsEnabled) {
      // console.log('ConnectedAdSlot: ' +adUnit +' mount: adsEnabled is false')
      return null
    }

    const displayType = debugAds ? AD_DISPLAY_TYPE.ALWAYS : this.props.displayType

    const classes = classnames('dfp-ad-slot', slotId, 'display-type--' + displayType, {
      unfilled: empty,
    })

    const adUnitPath = this.getAdUnitPath()
    const uniqueId = this.getUniqueId()

    // add data-ad-unit and data-ad-sizes property for easier debugging via dom
    return (
      <div ref={el => {
        this.element = el
      }}>
        {readyToShowAd && (adContext !== AD_CONTEXT.UNKNOWN) ? <div styleName={classes} data-ad-unique-id={uniqueId} data-ad-sizes={JSON.stringify(sizes)}>
          {debugAds && (
            <AdDebugInfoBox {...this.props} adUnit={adUnit} uniqueId={uniqueId}/>
          )}
          <div>
            <DfpAdSlot className={['adBox', slotId].join(' ')} key={uniqueId} sizes={sizes} adUnitPath={adUnitPath}
              adUnit={adUnit} loadInstantly={loadInstantly} onSlotRender={this.handleSlotRender}
              noId={noId} minSize={this.props.minSize}/>
          </div>
        </div> : null}
        {(!!empty || adBlocker) && !!FallBackElement && <FallBackElement fallbackElement={FallBackElement2}/>}
      </div>
    )
  }

  handleSlotRender = (renderInfo) => {
    const { event } = renderInfo
    this.asyncSetState({
      empty: event.isEmpty,
    })
  }
}

export default withErrorBoundary(
  ConnectedAdSlot,
  ErrorPlaceholderBox('-'),
)

/**
 * Util  function to get info about current page ads.
 * (Should be called only after page refresh.)
 * @returns {{info: Array, byContext: Object}}
 */
window.getAdsInfo = () => {
  const ads = window.dfpAdSlotObjects
  const info = Object.keys(ads).map(key => {
    const ad = ads[key]
    const { component } = ad
    const { adUnit, sizes, appContext } = component.props
    return {
      adUnit,
      sizes,
      appContext,
    }
  })

  const byContext = info.reduce((result, ad) => {
    const contextName = ad.appContext.join('>')
    const list = result[contextName] || []
    list.push(ad.adUnit)
    result[contextName] = list
    return result
  }, {})

  return {
    info,
    byContext,
  }
}

/**
 * Print all rendered page ads grouped by ads application context.
 * (Should be called only after page refresh.)
 */
window.printAdsByContext = () => {
  const info = window.getAdsInfo()
  let result = ''
  Object.keys(info.byContext).forEach(key => {
    const contextName = appContextMap[key] || key
    result += '\n\n' + contextName
    info.byContext[key].forEach(item => {
      result += '\n' + item.replace(dfpNetworkId + '/' + containerName, '')
    })
  })

  return result
}

// Map contexts to simplified context names
const appContextMap = {
  HeaderAds: 'Header',
  'Index>Sidebar': 'Upper Sidebar',
  'Article>Sidebar': 'Upper Sidebar',
  'Index>IndexSidebar>Sidebar': 'Sidebar',
  'Archive>IndexSidebar>Sidebar': 'Sidebar',
  'Index>Newsfeed': 'Newsfeed',
  'Archive>Newsfeed': 'Newsfeed',
  'Index>NewsfeedHeaderAds': 'Newsfeed header',
  'Article>LatestNews': 'Latest news block',
}
