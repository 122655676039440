import React, { Component } from 'react'
import WP from '@otavamedia/om-component-library/lib/lib/WP'
import articleTypes from '../components/general/article/ArticleTypes'
import HTML from '@otavamedia/om-component-library/lib/util/HTML'
import '../components/weeklyMagazine/MagazineArticle.scss'
import Paywall from '../components/widgets/Paywall'
import PropTypes from 'prop-types'

export default class Offer extends Component {
  static propTypes = {
    close: PropTypes.func,
  }

  constructor (props) {
    super(props)
    this.loadPage()
    this.state = { view: null }
  }

  async loadPage () {
    const page = await WP.getForURL(WP.url + '/tarjous')
    this.setState({ view: page.data })
  }

  renderHeader = (article, title, video, shouldRenderHeroImage) => {
    return (
      <header styleName="header">
        {shouldRenderHeroImage ? this.renderHeaderImage(video, article.featuredMedia, article.mobileHero) : null}
        <div style={{ textAlign: 'center' }}
          styleName={` ${shouldRenderHeroImage ? (article.mobileHero ? 'on-hero-image on-hero-image-mobile' : 'on-hero-image') : ''}`}>
          {article.groupHeader && <span styleName="group-header">{article.groupHeader}</span>}
          <h1 style={{ marginTop: '1rem' }} styleName={article.capitalizeTitle ? 'capitalized center-col' : 'center-col'}>
            <HTML>{title}</HTML></h1>
        </div>
      </header>
    )
  }

  render () {
    const {
      view: article
    } = this.state

    if (!article) {
      return null
    }

    const {
      articleType,
      content,
      pagination,
      headerVideoMp4,
      headerVideoWebm,
      title,
    } = article

    return <article styleName="article article-container" id="magazine-article">
      {this.renderHeader(article, title, headerVideoMp4 || headerVideoWebm, false)}
      <div styleName={`body-container ${articleType === articleTypes.MAGAZINE_COMMENT ? 'comment' : ''}`}>
        <div styleName="article-text" className={'article-body '}>
          <HTML options={{ pagination }}>{content}</HTML>
        </div>
        <div styleName="center-col">
          <Paywall disableAdCoin={true} titleChildren={<h2 key="2">Haluatko lukea tilaajille tarkoitettuja artikkeleita?</h2>}/>
        </div>
      </div>
    </article>
  }
}
