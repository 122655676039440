import React, { Component } from 'react'

import './WhyOrder.scss'
import eye from '../../assets/ui/nakoislehdet.jpg'
import book from '../../assets/ui/digilehdet.jpg'
import compass from '../../assets/ui/ilmiot.jpg'
import magnify from '../../assets/ui/vertailut.jpg'
import { Link } from '../general/util/Links'
import { connect } from 'kea'
import auth from '@otavamedia/om-component-library/lib/kea/auth'
import PropTypes from 'prop-types'

export default @connect({
  props: [
    auth, [
      'premiumUser',
    ],
  ]
})
/**
 * Displays a "Why order MK" box.
 */
class WhyOrder extends Component {
  static propTypes = {
    premiumUser: PropTypes.bool
  }

  render () {
    if (this.props.premiumUser) {
      return null
    }
    const blocks = [
      {
        text: 'Näköislehdet vuodesta 2003',
        img: eye,
        hover: 'Lue kaikki artikkelit 20 vuoden ajalta',
        url: '/arkisto/'
      },
      {
        text: 'Varustevertailuja ja reportaaseja',
        img: magnify,
        hover: 'Testaamme kiintoisimmat harrastajan varusteet',
        url: '/kategoria/varusteet/'
      },
      {
        text: 'Alan ilmiöt ja uutiset',
        img: compass,
        hover: 'Pysyt ajan tasalla alan tärkeimmistä uutisista',
        url: '/uusimmat/'
      },
      {
        text: '12 digilehteä vuodessa',
        img: book,
        hover: 'Jokainen lehti digitaalisessa muodossa ja lisäartikkeleita',
        url: '/digilehdet/'
      },
    ]
    return <div styleName="why-order">
      <h3>Miksi tilata M&K?</h3>
      <p>M&K ennakoi tulevat jahti- ja kalastuskaudet. Tekijöinä alan asiantuntijat.</p>
      <div styleName="blocks">
        {blocks.map((block, key) => <div key={key} styleName="hover-over">
          <div>
            <Link to={{ link: block.url }}>
              <img src={block.img} alt=""/>
              <p>{block.text}</p>
            </Link>
          </div>
          <div styleName="hover">{block.hover}
            <div styleName="caret"></div>
          </div>
        </div>
        )}
      </div>
      <Link to={{ link: 'https://tilaus.metsastysjakalastus.fi/tilaus?promoID=OM_tilaa-painike&promoName=OM_MK&promoCreative=onlinetarjous&promoPosition=syyt-tilata' }}>Tilaa Metsästys ja Kalastus</Link>
    </div>
  }
}
