import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import PopularPosts from '../general/popular/PopularPosts'
import LatestMagazine from '../weeklyMagazine/LatestMagazine'
import ForumBox from '../general/forum/ForumBox'
import './Sidebar.scss'
import Shortcuts from '../widgets/Shortcuts'
import track from 'react-tracking'
import SidebarArticles from './SidebarArticles'
import some from 'lodash/some'
import MagShortCut from '../widgets/MagShortCut'
import Breakpoint from '@otavamedia/om-component-library/lib/util/Breakpoint'
import GAMNativeAdHolder from '../widgets/GAMNativeAdHolder'

@track({ gtmContext: ['Sidebar'] })
/**
 * This component displays a sidebar which is used on for example the front page and in flow articles.
 * With parameters you can choose to display certain standard components (PopularPosts, LatestMagazine,
 * Shortcuts, SidebarArticles etc). You can also add custom content to the sidebar with child components.
 */
export default class Sidebar extends Component {
  loaded

  constructor (props) {
    super()
    this.loaded = {
      popular: !props.popular,
      shortcuts: !props.shortcuts,
      articles: !props.articles,
    }
  }

  UNSAFE_componentWillReceiveProps (props) {
    this.loaded = {
      popular: !props.popular,
      shortcuts: !props.shortcuts,
      articles: !props.articles,
    }
  }

  doneLoading (element) {
    this.loaded[element] = true
    if (!some(Object.values(this.loaded), (x) => !x)) {
      this.props.doneLoading()
    }
  }

  render () {
    const { latestMag, popular, forumPosts, category, shortcuts, articles, filteredArticles, latestMagazine, nativeAds, hideAds } = this.props
    return (
      <Fragment>
        {latestMag && (
          <div className="latest-mag">
            {latestMagazine ? <LatestMagazine/> : <MagShortCut/>}
          </div>
        )}
        {shortcuts
          ? <Shortcuts category={category} doneLoading={() => this.doneLoading('shortcuts')}/>
          : null
        }
        {popular
          ? <div styleName='popular'>
            <PopularPosts doneLoading={() => this.doneLoading('popular')} limit={10}/>
          </div>
          : null
        }
        {this.props.children}
        {nativeAds && !hideAds && <Breakpoint maxBreakpoint={767}>
          <GAMNativeAdHolder grow={true} type={2}/>
        </Breakpoint>}
        {articles
          ? <SidebarArticles doneLoading={() => this.doneLoading('articles')}/>
          : null }
        {filteredArticles
          ? <SidebarArticles filtered doneLoading={() => this.doneLoading('articles')}/>
          : null }
        {forumPosts
          ? <ForumBox/>
          : null
        }
      </Fragment>
    )
  }
}

Sidebar.propTypes = {
  children: PropTypes.any,
  latestMag: PropTypes.bool,
  shortcuts: PropTypes.bool,
  popular: PropTypes.bool,
  forumPosts: PropTypes.bool,
  category: PropTypes.number,
  doneLoading: PropTypes.func,
  articles: PropTypes.bool,
  nativeAds: PropTypes.bool,
  hideAds: PropTypes.bool,
  latestMagazine: PropTypes.bool,
  filteredArticles: PropTypes.bool
}
