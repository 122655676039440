import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'kea'
import { Carousel } from 'react-responsive-carousel'

import ProductComment from '../comments/ProductComment'
import galleryLogic from '@otavamedia/om-component-library/lib/kea/gallery'
import auth from '@otavamedia/om-component-library/lib/kea/auth'
import WP from '@otavamedia/om-component-library/lib/lib/WP'

import './Question.scss'

@connect({
  actions: [
    galleryLogic, [
      'openGallery',
    ],
    auth, [
      'updateModal'
    ]
  ],
  props: [
    auth, [
      'loggedIn',
      'username'
    ]
  ]
})
class ProductMemories extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    loggedIn: PropTypes.bool,
    isMemory: PropTypes.bool,
    username: PropTypes.string,
  }

  state = {
    open: false,
    okToSend: false,
    grade: 3,
    isWriting: false,
    selectedFile: null,
    comment: '',
    comments: [],
    readerGrade: 0,
    waitingApproval: false,
    page: 0
  }

  async loadComments (props) {
    let { data: comments } = await WP.getComments(this.props.id)
    comments
      .forEach((comment, idx) => {
        if (comment.parent) {
          const parent = comments.find(c => c.id === comment.parent)
          if (parent) {
            parent.reply = comment
            delete comments[idx]
          }
        }
      })
    comments = comments.filter(x => x) // remove undefined elements
    this.setState({ comments })
  }

  componentDidMount () {
    this.loadComments(this.props)
  }

  UNSAFE_componentWillReceiveProps (newProps) {
    if (newProps.id !== this.props.id) {
      this.loadComments(newProps)
    }
  }

  updateComment (newComment) {
    const idx = this.state.comments.findIndex((comment) => comment.id === newComment.id)
    if (idx >= 0) {
      const comments = this.state.comments
      comments[idx].acf = newComment.acf
      this.setState({ comments })
    }
  }

  render () {
    const { id } = this.props
    const { comments, page } = this.state
    if (!id || !comments.length) {
      return null
    }
    const perPage = 10
    const shownComments = comments.slice(page * perPage, (page + 1) * perPage)

    return (
      <div className="memories">
        <div styleName="title">Lukijoidemme muistoja</div>
        <div styleName="main">
          <Carousel showThumbs={false} showIndicators={false} showStatus={false} infiniteLoop={true} autoPlay={false}>
            {shownComments.map((comment, idx) =>
              <ProductComment
                key={comment.id}
                comment={comment}
                isLast={idx === comments.length - 1}
                admins={[]}
                hideActions
                quote={() => null}
                setComment={(comment) => this.updateComment(comment)}
                showTitle={true}
                showImage
              />
            )}
          </Carousel>
        </div>
      </div>
    )
  }
}

export default ProductMemories
