/* global googletag */
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import debounce from 'lodash/debounce'
import AdManager from './AdManager'

class AdObj {
  constructor () {
    this.adUnit = ''
    this.component = null
    this.loaded = false
  }
}

const ads = {}

window.dfpAdSlotObjects = ads

class DfpAdSlot extends Component {
  constructor (props) {
    super(props)
    // console.log('Constructing ' +props.adUnit + '_' + props.adUnitPath )

    this.manager = AdManager
    this.ad = null
    this.adUniqueIndex = 0

    this.state = {
      slotUniqueId: null,
      slotInternalId: null,
    }
  }

  getSlotUniqueId = () => {
    return this.props.adUnit + '_' + this.adUniqueIndex
  }

  getSlotInternalId = () => {
    return this.props.adUnit + '_' + this.adUniqueIndex + '_' + this.props.adUnitPath
  }

  render () {
    const { className } = this.props
    const { slotUniqueId } = this.state
    return !!slotUniqueId && (
      <div id={this.getSlotUniqueId()} className="adunitContainer">
        {this.manager.debug && <div>{slotUniqueId}</div>}
        <div id={slotUniqueId} className={ className }/>
      </div>
    )
  }

  /**
   * There can be multiple ad slots with same adUnit, but we still need to
   * identify them by unique index.
   * @param adUnit
   * @returns {number}
   */
  getAdUnitUniqueIndex (adUnit, adUnitPath) {
    let indexCounter = 0
    while (ads[adUnit + '_' + indexCounter + '_' + adUnitPath]) {
      if (ads[adUnit + '_' + indexCounter + '_' + adUnitPath].component) indexCounter++
      else break
    }
    return indexCounter
  }

  componentDidMount () {
    this.log('componentDidMount')

    this.defineOrReloadSlot()
  }

  defineOrReloadSlot () {
    const { adUnit, adUnitPath } = this.props

    this.adUniqueIndex = this.getAdUnitUniqueIndex(adUnit, adUnitPath)

    const uniqueId = this.getSlotUniqueId()
    const internalId = this.getSlotInternalId()

    const slotDefined = !!ads[internalId] && !!ads[internalId].slot

    if (slotDefined) {
      // Reuse ad slot if its already defined once.
      // Destroying ad slots caused pubmatic scripts to throw errors occasionally,
      // so that's why we reuse them.
      this.ad = ads[internalId]
    } else {
      this.ad = new AdObj()
      this.ad.adUnit = adUnit
    }

    this.ad.component = this
    ads[internalId] = this.ad

    this.setState({ slotUniqueId: uniqueId, slotInternalId: internalId }, () => {
      googletag.cmd.push(() => {
        if (!slotDefined) {
          this.defineSlot()
        }

        this.windowWidth = typeof window !== 'undefined' ? window.innerWidth : undefined
        this.handleResize = debounce(this.handleResize, 2000)

        window.addEventListener('resize', this.handleResize)

        this.manager.attachSlotRenderEnded(this.slotRenderEnded)
        this.manager.attachSlotIsViewable(this.slotIsViewable)

        this.reloadSlot()
      })
    })
  }

  componentWillUnmount () {
    this.log('componentWillUnmount')

    this.manager.detachSlotRenderEnded(this.slotRenderEnded)
    this.manager.detachSlotIsViewable(this.slotIsViewable)
    if (this.ad) {
      if (this.ad.slot) {
        googletag.destroySlots([this.ad.slot])
      }
      this.ad.component = null
      this.ad.loaded = false
      delete ads[this.state.slotInternalId]
    }

    window.removeEventListener('resize', this.handleResize)
  }

  shouldComponentUpdate (nextProps, nextState) {
    if (nextState.slotInternalId !== this.state.slotInternalId) {
      return true
    }
    return false
  }

  defineSlot = () => {
    const { slotUniqueId } = this.state
    const { adUnit, sizes, interstitial, adUnitPath } = this.props

    this.log('Define slot')
    if (interstitial) {
      // Define the ad slot
      this.ad.slot = googletag.defineOutOfPageSlot(
        adUnit,
        slotUniqueId
      )

      this.ad.slot.addService(googletag.pubads())
      this.ad.slot.setCollapseEmptyDiv(true)
    } else {
      // Define the ad slot
      this.ad.slot = googletag.defineSlot(
        adUnitPath,
        sizes,
        slotUniqueId
      )

      this.ad.slot.addService(googletag.pubads())
      this.ad.slot.setCollapseEmptyDiv(true)

      this.defineSizeMapping(googletag)
    }
  }

  reloadSlot = () => {
    if (!this.ad) return

    this.ad.loaded = false

    this.log('Reload ad slot')

    this.refreshSlot()
  }

  refreshSlot = () => {
    const { loadInstantly } = this.props

    if (this.ad.loaded) console.warn('Trying to load ad slot that has been already loaded! Slot:', this.props)

    this.ad.loaded = true
    this.ad.loadedTimestamp = Date.now()

    this.log('Refresh ad in slot - Instant refresh: ' + loadInstantly, 'slot:', this.ad.slot)

    this.manager.addSlotToRefreshQueue(this.ad.slot)

    // Some high priority top of the page ads can be set to load instantly instead of waiting other ads.
    // loadInstantly is false by default to avoid huge memory consumption by googletag.pubads().refresh()
    if (loadInstantly) {
      this.manager.refreshQueue()
    } else {
      this.manager.waitAndRefreshQueue()
    }
  }

  /**
   * This method needs to be called inside googletag.cmd.push()
   * to make sure pubads() script gets executed in proper order.
   */
  defineSizeMapping = (googletag) => {
    const { sizeMapping } = this.props

    if (this.ad.loaded) {
      googletag.pubads().clear([this.ad.slot])
    }

    const mapping = googletag.sizeMapping()

    sizeMapping.forEach((s) => {
      mapping.addSize([s.width, 0], s.sizes)
    })

    this.ad.slot.defineSizeMapping(mapping.build())
  }

  handleResize = () => {
    const { sizeMapping } = this.props
    const windowWidth = window.innerWidth
    const oldWindowWidth = this.windowWidth

    this.windowWidth = windowWidth

    if (!sizeMapping) return

    let minWidth = 0
    let oldMinWidth = 0

    sizeMapping.forEach((s) => {
      if (s.width > minWidth && s.width < windowWidth) minWidth = s.width
      if (s.width > oldMinWidth && s.width < oldWindowWidth) oldMinWidth = s.width
    })

    if (minWidth !== oldMinWidth) {
      this.reloadSlot()
    }
  }

  log (...args) {
    // to debug log, set AdManager debug = true
    if (this.manager.debug) {
      console.log(this.props.adUnit, ' - ', ...args)
    }
  }

  slotRenderEnded = (eventData) => {
    if (eventData.slotId === this.state.slotUniqueId) {
      if (this.props.onSlotRender !== undefined) {
        this.props.onSlotRender(eventData)
      }
    }
  }

  slotIsViewable = (eventData) => {
    if (eventData.slotId === this.state.slotUniqueId) {
      if (this.props.onSlotViewable !== undefined) {
        this.props.onSlotViewable(eventData)
      }
    }
  }
}

export default DfpAdSlot

DfpAdSlot.propTypes = {
  appContext: PropTypes.array,
  adUnit: PropTypes.string.isRequired,
  adUnitPath: PropTypes.string.isRequired,
  sizes: PropTypes.any,
  // sizeMapping defines browser and ad dimensions https://support.google.com/dfp_premium/answer/3423562?hl=en
  sizeMapping: PropTypes.arrayOf(PropTypes.shape({
    width: PropTypes.number.isRequired,
    sizes: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number))
  })),
  loadInstantly: PropTypes.bool,
  onSlotRender: PropTypes.func,
  onSlotViewable: PropTypes.func,
  className: PropTypes.string,
  interstitial: PropTypes.bool,
  noId: PropTypes.bool,
}

DfpAdSlot.defaultProps = {
  sizes: [],
  sizeMapping: [],
  loadInstantly: false,
}
