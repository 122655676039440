import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import format from 'date-fns/format'

const isPublishedToday = date => {
  const publishDate = format(date, 'DD.MM.YYYY')
  const today = format(new Date(), 'DD.MM.YYYY')
  return today === publishDate
}

const DateField = ({ date, dateOnly, timeOnly }) => {
  if (format(date, 'H:mm') === 'Invalid Date') return null

  if (timeOnly) {
    return format(date, 'H:mm')
  } else if (dateOnly) {
    return format(date, 'D.M.YYYY')
  }

  return (
    <Fragment>
      {date
        ? format(date, isPublishedToday(date)
          ? 'H:mm'
          : 'D.M.YYYY')
        : null
      }
    </Fragment>
  )
}

DateField.propTypes = {
  date: PropTypes.string,
  dateOnly: PropTypes.bool,
  timeOnly: PropTypes.bool,
}

export default DateField
