import PropTypes from 'prop-types'
import React from 'react'
import { Link } from '../util/Links'
import { ListBulletIcon } from '../../widgets/Icons'
import './Themebox.scss'

const Themebox = ({ data }) => {
  if (!data || !data.title || !data.links) return null

  return (
    <div styleName="themebox-box">
      <span styleName="themebox-header">{data.title}</span>
      {data.links && data.links.map(l =>
        <Link key={l.url} styleName='theme-box-link' to={{ link: l.url }}><ListBulletIcon/> {
          l.label
        }</Link>
      )}
    </div>
  )
}

Themebox.propTypes = {
  data: PropTypes.object
}

export default Themebox
