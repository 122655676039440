import React, { Component } from 'react'
import ClubForm from './ClubForm'

/**
 * Displays a form for the Deer club (peurakerho)
 */
export default class DeerForm extends Component {
  render () {
    return <ClubForm formPages={formPages} type="deer" texts={texts}/>
  }
}

const texts = {
  intro: {
    smallTitle: 'Ilmoita kaato',
    mainTitle: 'Peurojen kymppikerho',
    ingress: 'Ilmoita kaatosi helmikuun loppuun mennessä. ',
    text: 'Kysymme lomakkeessa yhteystietoja, jotta voimme lähettää kerhopinssin ilmoittamaasi osoitteeseen.'
  },
  outro: {
    mainTitle: 'Kiitos ilmoittautumisestasi!'
  }
}
const formPages = [
  [ // page 1
    {
      topHeader: 'Metsästäjän yhteystiedot',
      title: 'Peurojen kymppikerho',
      items: [
        {
          name: 'Nimi',
          field: 'kalastajan_nimi',
          noLabel: true,
          required: true,
        },
        {
          name: 'Puhelinnumero',
          field: 'puhelinnumero',
          noLabel: true
        },
        {
          name: 'Sähköpostiosoite',
          field: 'sahkopostiosoite',
          noLabel: true,
          required: true,
          pattern: '^.+@.+\\..+$',
        },
        {
          name: 'Lähiosoite',
          field: 'lahiosoite',
          noLabel: true,
          required: true,
        },
        {
          type: 'row',
          items: [
            {
              name: 'Postinumero',
              field: 'postinumero',
              style: { width: '40%' },
              noLabel: true,
              required: true,
            },
            {
              name: 'Postitoimipaikka',
              field: 'postitoimipaikka',
              style: { width: '60%' },
              noLabel: true,
              required: true,
            },
          ]
        }
      ],
    },
  ],
  [ // page 2
    {
      topHeader: 'Kaadon tiedot',
      items: [
        {
          type: 'row',
          items: [
            {
              name: 'Punnittu ruhopaino',
              field: 'ruhopaino',
              unit: 'kg',
              pattern: '^[0-9]+([,.][0-9]+)?$',
              match: ',',
              replace: '.',
              style: { width: '60%' },
            },
            {
              name: 'Sarvien leveys',
              field: 'sarvien_leveys',
              unit: 'cm',
              pattern: '^[0-9]+([,.][0-9]+)?$',
              match: ',',
              replace: '.',
              required: true,
              style: { width: '40%' },
            }
          ]
        },
        {
          name: 'Piikkien lukumäärä (väh 2,5cm piikit)',
          field: 'piikit',
          required: true,
          pattern: '^[0-9]+$',
        },
        {
          name: 'Saantipäivä',
          field: 'kaatopaiva',
          type: 'date',
          required: true,
        },
        {
          name: 'Kellonaika',
          field: 'kaatoaika',
          pattern: '^[0-9]{1,2}[.:][0-9]{1,2}$',
          match: '.',
          replace: ':'
        },
        {
          name: 'Paikkakunta',
          field: 'paikkakunta',
          required: true,
        },
        {
          name: 'Jahtitapa',
          field: 'jahtitapa',
          type: 'select',
          options: ['Miesajo', 'Koira-ajo', 'Kyttäysjahti', 'Houkuttelu', 'Naakiminen', 'Muu'],
          required: true,
        },
        {
          name: 'Muu jahtitapa, mikä',
          field: 'muu_jahtitapa',
          showIfField: 'jahtitapa',
          showIf: 'Muu'
        },
        {
          name: 'Koiran rotu',
          field: 'rotu'
        },
        {
          name: 'Koiran nimi',
          field: 'koiran_nimi'
        },
        {
          name: 'Sää',
          type: 'select',
          field: 'saa',
          required: false,
          options: ['Aurinkoinen', 'Pilvinen']
        },
        {
          name: 'Sade',
          type: 'select',
          field: 'sade',
          required: false,
          options: ['Poutaa', 'Sadetta', 'Lumisadetta']
        },
        {
          name: 'Tuuli',
          field: 'tuulenvoimakkuus',
          type: 'select',
          options: ['Tyyni', 'Kohtalainen', 'Kova tuuli']
        },
        {
          name: 'Lämpötila',
          field: 'ilmanlampo',
        },
        {
          name: 'Maa',
          field: 'maa',
          type: 'select',
          options: ['Sula maa', 'Lumipeite']
        },
      ],
    },
  ],
  [ // page 3
    {
      topHeader: 'Ase ja tähtäin',
      items: [
        {
          type: 'row',
          items: [
            {
              name: 'Ampumaetäisyys',
              field: 'ampumaetaisyys',
              style: { width: '50%' },
              unit: 'm',
              required: true,
              pattern: '^[0-9]+([,.][0-9]+)?$',
              match: ',',
              replace: '.'
            },
            {
              name: 'Laukausten määrä',
              field: 'laukausten_maara',
              style: { width: '50%' },
              unit: 'kpl',
              required: true,
              pattern: '^[0-9]+$'
            },
          ]
        },
        {
          name: 'Peuran kulku 1. osuman jälkeen',
          field: 'kulku',
          unit: 'm',
          required: true,
          pattern: '^[0-9]+([,.][0-9]+)?$',
          match: ',',
          replace: '.'
        },
        {
          name: 'Aseen merkki',
          field: 'aseen_merkki',
          required: true,
        },
        {
          name: 'Kaliiperi',
          field: 'kaliiperi',
          required: true,
        },

        {
          name: 'Patruunan tyyppi',
          type: 'radio-buttons',
          field: 'patruunan_tyyppi',
          options: ['Tehdaspatruuna', 'Itseladattu'],
          required: true,
        },
        {
          name: 'Käytetty luoti',
          field: 'kaytetty_luoti',
          showIfField: 'patruunan_tyyppi',
          showIf: 'Itseladattu'
        },
        {
          name: 'Käytetty patruuna',
          field: 'kaytetty_patruuna',
          showIfField: 'patruunan_tyyppi',
          showIf: 'Tehdaspatruuna'
        },
        {
          name: 'Luodin paino',
          field: 'luodin_paino',
          unit: 'g',
          pattern: '^[0-9]+([,.][0-9]+)?$',
          match: ',',
          replace: '.',
          showIfField: 'patruunan_tyyppi',
          showIf: 'Tehdaspatruuna'
        },
        {
          name: 'Tähtäin',
          type: 'radio-buttons',
          field: 'tahtain',
          options: ['Avo', 'Kiikaritähtäin']
        },
        {
          name: 'Tähtäimen merkki',
          field: 'tahtaimen_merkki',
          showIfField: 'tahtain',
          showIf: 'Kiikaritähtäin',
          required: true,
        },
        {
          name: 'Suurennos',
          field: 'suurennos',
          showIfField: 'tahtain',
          showIf: 'Kiikaritähtäin',
          required: true,
        },
      ]
    },
  ],
  [ // page 4
    {
      topHeader: 'Saaliskuvat',
      items: [
        {
          name: 'Lisää saaliskuvat',
          type: 'images',
          field: 'hirvikuvat',
          required: true,
        },
        {
          name: 'Tapahtuman lisätiedot',
          type: 'textarea',
          field: 'lisatiedot'
        }
      ]
    }
  ],
  [ // page 5
    {
      topHeader: 'Todistajat',
      items: [
        {
          name: 'Todistaja 1',
          field: 'todistaja_1',
          required: true,
        },
        {
          name: 'Puhelinnumero',
          field: 'tod_puhelin',
          pattern: '^[0-9-+ ]+$'
        },
        {
          name: 'Todistaja 2',
          field: 'todistaja_2',
          required: true,
        },
        {
          name: 'Puhelinnumero',
          field: 'tod_puhelin2',
          pattern: '^[0-9-+ ]+$'
        },

      ]
    }
  ],

]
