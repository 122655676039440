import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import './KeywordList.scss'
import { Link } from '../util/Links'
import HTML from '@otavamedia/om-component-library/lib/util/HTML'
import track from 'react-tracking'

const KeywordList = ({ keywords }) => {
  return keywords && keywords.length
    ? (
      <span styleName="keywords-field">
        {keywords.map((tag, idx) => (
          (tag.name === 'testipankki' || tag.name === 'huumori') ? <b key={idx}></b> : <Fragment key={idx}><Link to={tag} rel="tag"><HTML>{tag.name}</HTML></Link>&#32;</Fragment>
        ))}
      </span>
    )
    : null
}

KeywordList.propTypes = {
  keywords: PropTypes.array,
}

export default track({ gtmContext: ['KeywordList'] })(KeywordList)
