import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import { connect } from 'kea'
import application from '@otavamedia/om-component-library/lib/kea/application'
import auth from '@otavamedia/om-component-library/lib/kea/auth'
import HTML from '@otavamedia/om-component-library/lib/util/HTML'
import { Link } from '../components/general/util/Links'
import BestProductPageLoader from '../components/general/util/BestProductPageLoader'
import BestProductLoader from '../components/general/util/BestProductLoader'
import { CaretDown } from '../components/widgets/Icons'
import { ErrorPlaceholder, withErrorBoundary } from '../components/general/util/ErrorBoundaries'
import TopRowAd from '../components/general/util/TopRowAd'
import Image from '../components/general/util/Image'
import WP from '@otavamedia/om-component-library/lib/lib/WP'
import RenderedError from '../components/general/util/RenderedError'
import { STATUS } from '@otavamedia/om-component-library/lib/lib/request-state'
import TestCard from '../components/widgets/TestCard'
import { pushPageDataToGTM, stripHTML } from '@otavamedia/om-component-library/lib/lib/utils'
import find from 'lodash/find'

import './BestProductCollection.scss'

import { IMAGE_SIZE } from '@otavamedia/om-component-library/lib/entities/ImageModel'
import track from 'react-tracking'

const getPageFromUrl = (link) => {
  const regex = /^(.*\/)([0-9]+)(\/)$/
  return link && parseInt(link.replace(regex, '$2'))
}

@connect({
  actions: [
    application, [
      'setViewData',
    ]
  ],
  props: [
    application, [
      'view',
    ],
    auth, [
      'premiumUser',
    ],
  ]
})
class TestBankCategory extends Component {
  static propTypes = {
    view: PropTypes.object,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    setRendered: PropTypes.func.isRequired,
    premiumUser: PropTypes.bool,
  }

  tag = 'testipankki'
  categoryData = {
    autot: {
      subCategories: ['autot', 'moottoripyorat', 'polkupyorat', 'renkaat', 'tarvikkeet'],
      mainCategories: ['autot', 'moottoripyorat', 'polkupyorat', 'renkaat', 'tarvikkeet', 'koeajot'],
      catIds: [],
      subIds: [],
    },
    elektroniikka: {
      subCategories: ['mobiililaitteet', 'kamerat', 'televisiot', 'tietotekniikka', 'audio', 'kodintekniikka'],
      mainCategories: ['mobiililaitteet', 'kamerat', 'televisiot', 'tietotekniikka', 'audio', 'kodintekniikka', 'teknologia', 'testit'],
      catIds: [],
      subIds: [],
    }
  }

  state = {
    data: null,
    status: STATUS.NOT_REQUESTED,

    page: getPageFromUrl(window.location.href) || 1,
    maxPages: 1,

    categories: [], // Available filters
    category: null,
    mainTerm: null, // Used to get products
    secondaryTerm: '', // Used to filter products
    secondaryTermSlug: null,
    order: 'desc',
  }

  constructor (props) {
    super()
    this.state.category = props.match ? props.match.params.category : null
    this.state.secondaryTermSlug = props.match ? props.match.params.subcategory : null
  }

  componentDidMount () {
    pushPageDataToGTM({})
    this.loadData()
  }

  componentWillUnmount () {
    this.props.setRendered(false)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.location.key !== this.props.location.key) {
      this.setState({
        category: nextProps.match ? nextProps.match.params.category : null,
        secondaryTermSlug: nextProps.match ? nextProps.match.params.subcategory : null,
        data: null,
        page: getPageFromUrl(window.location.href) || 1,
      }, () => this.loadData())
    } // TODO: url doesn't update when selecting category
  }

  async loadData () {
    try {
      const [postReq, mainTermReq, subTermReq, mainCatReq] = await Promise.all([
        WP.getForURL(WP.url + '/testit-' + this.state.category),
        WP.getTerms('post_tag', {
          slug: this.tag,
          hide_empty: false,
          cache: {
            expireAfter: Date.now() + 3600000 * 24 // 1 day
          }
        }),
        WP.getTerms('category', {
          slug: this.categoryData[this.state.category].subCategories.join(','),
          hide_empty: false,
          cache: {
            expireAfter: Date.now() + 3600000 * 24 // 1 day
          }
        }),
        WP.getTerms('category', {
          slug: this.categoryData[this.state.category].mainCategories.join(','),
          hide_empty: false,
          cache: {
            expireAfter: Date.now() + 3600000 * 24 // 1 day
          }
        })
      ])
      const mainTerm = mainTermReq.data[0].id
      const subcategories = subTermReq.data
      const mainCategories = mainCatReq.data
      const post = postReq.data
      this.actions.setViewData(post)
      if (this.state.secondaryTermSlug) {
        this.setState({
          mainTerm,
          categories: subcategories,
          mainCategories,
          secondaryTerm: find(subcategories, { slug: this.state.secondaryTermSlug }).id
        }, this.getProducts)
      } else {
        this.setState({ mainTerm, categories: subcategories, mainCategories }, this.getProducts)
      }
    } catch (e) {
      this.setState({
        status: STATUS.ERROR,
        data: e,
      })
    }

    if (this.props.view.id) {
      WP.triggerHitCounter(this.props.view.id)
    }
  }

  async getProducts () {
    const { mainTerm, secondaryTerm, mainCategories, page, order } = this.state

    const params = {
      per_page: 12,
      categories: secondaryTerm || mainCategories.map(term => term.id).join(','),
      order: order || 'desc',
      orderby: 'date',
      tags: mainTerm,
      offset: ((page - 1) || 0) * 12
    }
    this.setState({ status: STATUS.REQUESTED })

    const r = await WP.getFromPostTypes(['post', 'om_digimag_post', 'om_theme_article'], params)
    const { data, headers } = r

    this.setState({
      data: [...(this.state.data || []), ...data],
      status: STATUS.DONE,
      maxPages: Math.min(parseInt(headers['x-wp-totalpages'], 10), 10),
    }, () => this.props.setRendered(true))
  }

  handleFormChange = (e) => {
    const { target } = e
    // console.log(target.name, target.value)

    this.setState({
      [target.name]: target.value,
      data: null,
      page: 1,
    }, this.getProducts)
  }

  addPage = () => {
    this.setState({
      page: this.state.page + 1
    }, this.getProducts)
  }

  renderProducts (products) {
    const { page, maxPages, status } = this.state

    if (!products.length) {
      return <div>Tästä kategoriasta ei löytynyt testejä.</div>
    }
    const items = products.map((p, i) => (
      <li styleName="result-item" key={`${i}-${p.id}`}>
        <Link to={p}>
          <TestCard article={p}/>
        </Link>
      </li>
    ))

    const loading = status === STATUS.REQUESTED

    return (
      <Fragment>
        <ul styleName="result-list" data-loading={loading}>
          {items}
        </ul>
        {
          loading
            ? <BestProductLoader />
            : (
              page < maxPages
                ? (
                  <div styleName="load-more">
                    <button styleName="load-more" onClick={() => this.addPage()}>
                      Lataa lisää
                    </button>
                  </div>
                )
                : null
            )
        }
      </Fragment>
    )
  }

  render () {
    const { status, data, categories } = this.state
    const { view } = this.props

    if (status !== STATUS.ERROR && (!view || !view.id)) {
      return <BestProductPageLoader />
    }

    const {
      content,
      title,
      excerpt,
      createdDate,
      modifiedDate,
      link,
      featuredMedia
    } = view

    const fullLink = window.location.origin + link
    const metaTags = {
      'og:locale': 'fi_FI',
      'og:type': 'article',
      'og:title': stripHTML(title),
      'og:description': stripHTML(excerpt),
      'og:url': fullLink,

      // og:type is article, more tags for it:
      'article:published_time': createdDate,
      'article:modified_time': modifiedDate,

      'http:status': 200,
    }
    /* eslint-disable jsx-a11y/no-onchange */
    /* eslint-disable jsx-a11y/label-has-for */
    return <Fragment>
      <Helmet>
        <title>
          {`Testit - ${stripHTML(title)} - ${window.om_constants.siteName}`}
        </title>
        <link rel="canonical" href={fullLink}/>
        <meta name="description" content={metaTags['og:description']}/>
        {Object.entries(metaTags).map(([key, value], i) => Array.isArray(value)
          ? (
            value.map(x => (
              <meta name={key} key={`${key}-${i}`} content={x}/>
            ))
          )
          : (
            <meta name={key} key={`${key}-${i}`} content={value}/>
          ))}
      </Helmet>
      <div styleName="main-image">
        {featuredMedia
          ? <Image data={featuredMedia} size={IMAGE_SIZE.LARGE} sizes={'(max-width: 1440px) 100vw, 1440px'}/>
          : null
        }
      </div>
      <div styleName='magazine-content'>
        <article styleName="article-container">
          <header>
            <h1><HTML>{title}</HTML></h1>
          </header>
          <div styleName='article-body'>
            <HTML>{content}</HTML>
          </div>
        </article>
        <form styleName="filterform">
          <div styleName="result-filters">
            <div>
              <label htmlFor="sort">Kategoria</label>
              <div styleName="select-div">
                <select id="category-select" name="secondaryTerm" onChange={this.handleFormChange} value={this.state.secondaryTerm}>
                  <option value="">Kaikki</option>
                  {categories && categories.length
                    ? categories.map((x, i) => (
                      <option value={x.id} key={i}>{x.name}</option>
                    ))
                    : <option value="">Kategorioita ladataan...</option>}
                </select>
                <div styleName="select-caret"><CaretDown color="red"/></div>
              </div>
            </div>

            <div>
              <label htmlFor="sort">Järjestä</label>
              <div styleName="select-div">
                <select id="sort-select" name="order" onChange={this.handleFormChange} defaultValue="desc">
                  <option value="desc">Uusimmat</option>
                  <option value="asc">Vanhimmat</option>
                </select>
                <div styleName="select-caret"><CaretDown color="red"/></div>
              </div>
            </div>
          </div>
        </form>

        <section styleName="results">
          {data
            ? (
              this.renderProducts(data)
            )
            : status === STATUS.ERROR
              ? (
                <RenderedError error={data}/>
              )
              : (
                <BestProductLoader/>
              )}
        </section>
      </div>
      <TopRowAd isArticleView={false} display={true}/>
    </Fragment>
  }
}

export default track({ gtmContext: ['TestBankCategory'] })(withErrorBoundary(
  TestBankCategory,
  ErrorPlaceholder()
))
