import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { connect } from 'kea'

import application from '@otavamedia/om-component-library/lib/kea/application'
import TopRowAd from '../components/general/util/TopRowAd'
import TopPicks from '../components/widgets/TopPicks'
import ItemsCarousel from '@otavamedia/om-component-library/lib/ItemsCarousel'

import WP from '@otavamedia/om-component-library/lib/lib/WP'

import {
  ErrorPlaceholderBox,
  withErrorBoundary
} from '../components/general/util/ErrorBoundaries'

import { STATUS } from '@otavamedia/om-component-library/lib/lib/request-state'
import RenderedError from '../components/general/util/RenderedError'

import './Index.scss'
import track from 'react-tracking'
import headerLogic from '@otavamedia/om-component-library/lib/kea/header'
import { CaretLeft, CaretRight } from '../components/widgets/Icons'
import ArticleCard from '@otavamedia/om-component-library/lib/ArticleCard'
import { Link } from '../components/general/util/Links'
import HTMLParser from '@otavamedia/om-component-library/lib/lib/html-parser'

@track({ gtmContext: ['StoryArchive'] })
@connect({
  actions: [
    application, [
      'setRendered'
    ],
  ],
  props: [
    headerLogic, [
      'menus',
      'searchOpen',
    ],
  ]
})
class StoryArchive extends Component {
  loaded = {
  }

  _mounted = false

  asyncSetState (...args) {
    // avoid setting state after unmount in case async operations attempts to do so
    if (this._mounted) this.setState(...args)
  }

  constructor (props) {
    super(props)
    this.state = {
      stickies: null,
      status: STATUS.NOT_REQUESTED,
      nativeAds: []
    }
  }

  static propTypes = {
    menus: PropTypes.array,
    error: PropTypes.any,
    setRendered: PropTypes.func,
    actions: PropTypes.object,
  }

  async componentDidMount () {
    this._mounted = true
    const { menus } = this.props
    const stories = menus.find((x) => x.name === 'Tarinat')
    const categoryPosts = await Promise.all(stories.children.map(async (category) =>
      (await WP.getFromPostTypes(['post', 'om_digimag_post', 'om_theme_article'], {
        categories: category.id,
      })).data))
    this.setState({ categoryPosts, categories: stories.children })
    this.props.actions.setRendered(true)
  }

  componentWillUnmount () {
    this._mounted = false
    this.props.actions.setRendered(false)
  }

  renderCategory (posts) {
    return <ItemsCarousel
      initialSlide={0}
      ItemComponent={ArticleCard}
      carouselSettings={{
        responsive:
          [{
            breakpoint: 9999,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              speed: 300,
            },
          }]
      }}
      arrowLeft={<CaretLeft color="blue"/>}
      arrowRight={<CaretRight color="blue"/>}
      itemProps={posts.map((post, idx) =>
        ({
          key: post.id,
          article: post,
          isLast: idx === posts.length - 1,
          LinkComponent: Link,
          htmlParser: HTMLParser.render,
          notShown: { ingress: true, topic: true, createdDate: true },
          displaySubscriberIcon: false,
          label: { to: {}, text: post.author.name },
          sizes: '320px'
        })
      )}
    />
  }

  render () {
    const { error } = this.props
    const { categoryPosts, error: stateError, categories } = this.state

    if (!categoryPosts) return null
    if (error) {
      return <RenderedError error={error}/>
    } else if (stateError) {
      throw stateError
    }

    return (
      <Fragment>
        <Helmet>
          <title>
            Tarinat - {window.om_constants.siteName}
          </title>
          <meta name="http:status" content="200"/>
        </Helmet>
        <TopPicks/>
        <TopRowAd isArticleView={false} display={true}/>
        <div styleName={'full-width-col'}>
          <div styleName="sub-container">
            <div styleName="story-archive">
              { categoryPosts.map((category, idx) => <div key={idx}>
                <h2 styleName="story-category">{categories[idx].name}</h2>
                {this.renderCategory(category)}
              </div>)}
            </div>
          </div>
        </div>

      </Fragment>
    )
  }
}

export default withErrorBoundary(
  StoryArchive,
  ErrorPlaceholderBox('Artikkeleita ei saatu ladattua'),
)
