import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'kea'
import track from 'react-tracking'
import headerLogic from '@otavamedia/om-component-library/lib/kea/header'
import application from '@otavamedia/om-component-library/lib/kea/application'
import NavigationBurger from '../navigation/NavigationBurger'
import NavigationBar from '../navigation/NavigationBar'
import './Header.scss'
// import WeeklyMagazineMenu from '../navigation/WeeklyMagazineMenu'
// import WeeklyMagazineArticleMenu from '../navigation/WeeklyMagazineArticleMenu'

import MagazineMenu from '../navigation/MagazineMenu'
import isEqual from 'lodash/isEqual'
import Breakpoint from '@otavamedia/om-component-library/lib/util/Breakpoint'

export const HEADER_STATE = {
  FULL: 'full',
  COLLAPSED: 'collapsed',
  TOP_COLLAPSED: 'topCollapsed',
}

export default @track({ gtmContext: ['Header'] })
@connect({
  props: [
    headerLogic, [
      'menus',
      'weeklyMagazineContentsMenuOpen',
      'searchOpen',
    ],
    application, [
      'isMagazineView',
      'isMagazineArticleView',
    ],
  ],
  actions: [
    headerLogic, [
      'addMenu',
    ],
  ]
})
class Header extends Component {
  constructor () {
    super()

    this.state = {
      headerState: HEADER_STATE.FULL,
    }

    this.prevScrollPos = -1
  }

  static propTypes = {
    menus: PropTypes.array,
    weeklyMagazineContentsMenuOpen: PropTypes.bool,
    isMagazineArticleView: PropTypes.bool,
    isMagazineView: PropTypes.bool,
    searchOpen: PropTypes.bool,
    pathname: PropTypes.string,
  }

  componentDidMount () {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    const {
      isMagazineView,
      isMagazineArticleView,
      searchOpen,
      weeklyMagazineContentsMenuOpen,
    } = this.props
    const { headerState } = this.state

    const doc = document.documentElement
    const position = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)

    this.prevScrollPos = this.prevScrollPos === -1 ? position : this.prevScrollPos

    const diff = position - this.prevScrollPos
    const scrollDir = diff > 0 ? 1 : -1
    const scrollLimit = 15

    let newHeaderState = headerState
    if (position < 50) {
      newHeaderState = HEADER_STATE.FULL
    } else if (position > 50) {
      if ((isMagazineView || isMagazineArticleView) && position > 50 && !searchOpen) {
        if (scrollDir === 1 && diff > scrollLimit) {
          newHeaderState = HEADER_STATE.COLLAPSED
        } else if (scrollDir === -1 && diff < -8 * scrollLimit) {
          newHeaderState = HEADER_STATE.TOP_COLLAPSED
        } else {
          newHeaderState = this.state.headerState
        }
      } else {
        newHeaderState = HEADER_STATE.TOP_COLLAPSED
      }
    }

    // don't allow bottom part of header to collapse in case the weekly magazine ToC is open
    if (weeklyMagazineContentsMenuOpen && newHeaderState === HEADER_STATE.COLLAPSED) {
      newHeaderState = HEADER_STATE.TOP_COLLAPSED
    }

    if (newHeaderState !== this.state.headerState) {
      this.setState({
        headerState: newHeaderState,
      })
    }

    this.prevScrollPos = position
  }

  renderWeeklyMagazineMenu = () => {
    return <MagazineMenu headerState={this.state.headerState} pathname={this.props.pathname}/>
  }

  shouldComponentUpdate (nextProps, nextState) {
    return !isEqual(this.state, nextState) || !isEqual(this.props, nextProps)
  }

  render () {
    const { isMagazineView, isMagazineArticleView, pathname } = this.props
    const { headerState } = this.state

    const headerStyles = `header ${headerState}`
    return (
      <header styleName={headerStyles}>
        <Fragment>
          <Breakpoint maxBreakpoint={991}>
            <NavigationBurger menus={this.props.menus} headerState={headerState} />
          </Breakpoint>
          <Breakpoint minBreakpoint={992}>
            <NavigationBar menus={this.props.menus} headerState={headerState} pathname={pathname} />
          </Breakpoint>
          {(!!isMagazineView || !!isMagazineArticleView) && this.renderWeeklyMagazineMenu()}
        </Fragment>
      </header>
    )
  }
}
