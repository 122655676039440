import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'kea'
import galleryLogic from '@otavamedia/om-component-library/lib/kea/gallery'


export default @connect({
  actions: [
    galleryLogic, [
      'addImage',
      'removeImage',
    ],
  ],
})
class Image extends Component {
  static propTypes = {
    data: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.number,
      PropTypes.bool,
    ]),
    size: PropTypes.string,
    sizes: PropTypes.string,
    isZoomed: PropTypes.bool,
    noSrcSet: PropTypes.bool,
    withCaption: PropTypes.bool,
    addToGallery: PropTypes.bool,
    actions: PropTypes.object,
    divStyle: PropTypes.string,
    noHeight: PropTypes.bool,
  }

  state = {}
  timeout = null

  constructor (props) {
    super(props)
    this.node = React.createRef()
    this.state = {
      image: this.maybeUseGlide(props.data)
    }
  }

  maybeUseGlide (image) {
    /*
    if (image && image.mediaType !== 'video' && image.src && image.src.includes('https://cdn.tekniikanmaailma.fi')) {
      image.src = image.src.replace('https://cdn.tekniikanmaailma.fi', 'https://wp.tekniikanmaailma.fi')
    }
     */
    if (image && image.mediaType !== 'video' && image.src && (image.src.includes('https://wp.metsastysjakalastus.fi/') ||
      image.src.includes('https://wp.metsastysjakalastus.asteaws.dev/'))) {
      image.src = image.src.replace(/\/nakoislehti\//, '/')
      image.src = image.src.replace(/(https:\/\/[^/]+)(\/[^/]+)?\/wp-content\/uploads\//, '$1/awpo/img/')
      image.glide = true
      delete image.srcSet
      delete image.sizes
    }
    return image
  }

  addToGallery () {
    const { addImage } = this.actions

    if (this.state.image) {
      addImage(this.state.image, true)
    }
  }

  removeFromGallery () {
    if (this.state.image) {
      this.actions.removeImage(this.state.image, true)
    }
  }

  setAspectRatio (props) {
    const sizes = props.data ? props.data.sizes : null
    if (sizes && sizes.medium) {
      this.setState({ aspect: sizes.medium.height ? sizes.medium.width / sizes.medium.height : 0 })
    }
  }

  componentDidMount () {
    if (this.props.addToGallery) {
      this.addToGallery()
    }
    this.setAspectRatio(this.props)
    this.setSize()
    this.timeout = setTimeout(() => this.setSize(true), 1000)
  }

  setSize (force = false) {
    if (!this.state.width && this.node && (this.node.offsetWidth || (this.node.parentNode && this.node.parentNode.clientWidth))) {
      if (this.timeout) {
        clearTimeout(this.timeout)
        this.timeout = null
      }
      this.setState({
        width: Math.ceil((this.node.offsetWidth || this.node.parentNode.clientWidth || 0) / 100) * 100,
        height: Math.ceil((this.node.offsetWidth || this.node.parentNode.clientHeight || 0) / 100) * 100,
      })
    } else if (force && !this.state.width) {
      this.setState({ width: 1000 })
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    this.setSize()
  }

  UNSAFE_componentWillReceiveProps (newProps) {
    if (newProps.addToGallery) {
      if (this.state.image && newProps.data && this.state.image.link !== newProps.data.link) {
        this.removeFromGallery()
      }
      this.setState({ image: this.maybeUseGlide(newProps.data) }, this.addToGallery)
    } else {
      this.setState({ image: this.maybeUseGlide(newProps.data) })
    }
    this.setAspectRatio(newProps)
  }

  componentWillUnmount () {
    const { removeImage } = this.actions
    const { data } = this.props

    if (data) {
      removeImage(data, true)
    }
  }

  render () {
    const {
      noSrcSet,
      withCaption,
      actions: discard,
      addToGallery,
      isZoomed,
      divStyle,
      noHeight,
      ...remaining
    } = this.props

    const { image } = this.state

    if (!image || !image.src) {
      return false
    } else if (typeof image === 'number') {
      if (image !== 0) {
        // WP returns 0 in case there's no image
        console.error('Image component only accepts image objects, number passed')
      }
      return false
    }

    const { alt, mediaType, focus } = image
    let { src, glide } = image

    delete remaining.data
    if (glide) {
      delete remaining.sizes
    }

    const condProps = { alt }
    if (addToGallery) {
      remaining.className = remaining.className ? remaining.className + ' gallery-image' : 'gallery-image'
    }

    if (alt.length === 0) {
      condProps.alt = ''
      condProps.role = 'presentation'
    }

    // remove dispatch prop provided by kea
    delete remaining.dispatch

    if (mediaType === 'video') {
      return         <div className={divStyle} dangerouslySetInnerHTML={{
          __html: `
          <video
            loop
            muted
            autoplay
            playsinline
            src="${src}"
          />`
        }}>
        </div>
          }

    if (focus) {
      remaining.style = { objectPosition: focus[0] + '% ' + focus[1] + '%' }
    }
    if (glide && !this.state.width) {
      src = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'
    }
    if (withCaption && image.caption) {
      const { figureClassName, ...rest } = remaining
      const { caption, copyright } = image

      let img
      if (glide) {
        // eslint-disable-next-line jsx-a11y/alt-text
        img = <img loading="lazy" src={src + (this.state.width ? ('?w=' + this.state.width + (noHeight || !this.state.height ? '' : '&fit=contain&h=' + this.state.height)) : '')} {...condProps} {...rest}
          ref={node => { this.node = node }} />
      } else {
        // eslint-disable-next-line jsx-a11y/alt-text
        img = <img loading="lazy" src={src} {...condProps} {...rest} ref={node => { this.node = node }} />
      }

      return (
        <figure {...{ className: figureClassName }}>
          {img}

          <figcaption>
            {caption}
          </figcaption>

          {copyright && (
            <div className="copyright">{copyright}</div>)
          }
        </figure>
      )
    }

    if (glide) {
      /* eslint-disable-next-line jsx-a11y/alt-text */
      return <img loading="lazy" src={src + (this.state.width ? ('?w=' + this.state.width + (noHeight || !this.state.height ? '' : '&fit=contain&h=' + this.state.height)) : '')} {...condProps} {...remaining}
        ref={node => { this.node = node }}/>
    } else {
      /* eslint-disable-next-line jsx-a11y/alt-text */
      return <img loading="lazy" src={src} {...condProps} {...remaining} ref={node => { this.node = node }}/>
    }
  }
}
