import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'

import WP from '@otavamedia/om-component-library/lib/lib/WP'
import './CustomForm.scss'
import { withRouter } from 'react-router-dom'
import { ThumbIcon } from '../../widgets/Icons'
import { connect } from 'kea'
import auth from '@otavamedia/om-component-library/lib/kea/auth'
import { getQuotedText } from '@otavamedia/om-component-library/lib/lib/utils'
import Parser from 'html-dom-parser'

@connect({
  actions: [
    auth, [
      'updateModal',
      'setCommenting'
    ]
  ],
  props: [
    auth, [
      'loggedIn',
    ]
  ]
})
class CustomForm extends Component {
  static propTypes = {
    id: PropTypes.number,
    loggedIn: PropTypes.bool,
    actions: PropTypes.object,
    thankYouTitle: PropTypes.string,
    thankYouText: PropTypes.string,
    header: PropTypes.string,
    instructions: PropTypes.string,
    showImage: PropTypes.bool,
    showTitle: PropTypes.bool,
    titleTitle: PropTypes.string,
    showStars: PropTypes.bool,
    autoOpen: PropTypes.bool,
    starsTitle: PropTypes.string,
    commentTitle: PropTypes.string,
    commentPlaceholder: PropTypes.string,
    quote: PropTypes.string,
    cancel: PropTypes.func,
    anonTitle: PropTypes.string,
    anonText: PropTypes.string,
    buttonText: PropTypes.string,
    image: PropTypes.any,
  }

  state = {
    open: false
  }

  constructor (props) {
    super(props)
    this.state = {
      open: !!props.autoOpen
    }
  }

  fileChanged (e) {
    let fileName = ''
    if (e.target.files && e.target.files.length > 1) {
      fileName = (this.getAttribute('data-multiple-caption') || '').replace('{count}', e.target.files.length)
    } else {
      fileName = e.target.value.split('\\').pop()
    }
    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i

    if (!allowedExtensions.exec(fileName)) {
      const file = document.getElementById('file')
      file.value = file.defaultValue
    } else {
      this.setState({ selectedFile: e.target.files[0], fileName, imagePreview: URL.createObjectURL(e.target.files[0]) })
    }
  }

  onTextChangeHandle (event) {
    this.setState({
      comment: event.target.value,
      okToSend: event.target.value.length > 10
    })
  }

  UNSAFE_componentWillReceiveProps (nextProps, nextContext) {
    if (nextProps.quote !== this.props.quote) {
      const text = nextProps.quote.author_name + ' kirjoitti:\n' + nextProps.quote.content.rendered
      const parsedText = getQuotedText(Parser(text), 1, true)

      this.setState({ comment: parsedText })
    }
  }

  onTitleChangeHandle (event) {
    this.setState({
      title: event.target.value,
    })
  }

  async submitComment () {
    this.setState({ okToSend: false, message: '' })
    const response = await WP.submitBlogComment({
      content: this.state.comment,
      post: this.props.id,
      otsikko: this.state.title,
      kuva: this.state.kuva,
      tahdet: this.state.grade,
      parent: this.props.quote ? this.props.quote.id : 0
    }, this.state.selectedFile)

    if (response.error) {
      this.setState({
        message: `Kommentin lähettäminen epäonnistui: ${response.error}`,
        error: true,
        okToSend: true
      })
    } else if (response.status === 'hold') {
      this.setState({
        message: 'Kommentti odottaa hyväksyntää',
        comment: '',
        error: false,
        isWriting: false,
        waitingApproval: true
      })
    } else if (response.status === 'approved') {
      this.setState({
        message: 'Kommentti lähetetty',
        comment: '',
        error: false,
        isWriting: false,
        waitingApproval: true
      })
    } else if (response.status === 'spam' || response.status === 'trash') {
      this.setState({
        message: 'Kommenttia ei hyväksytä',
        error: true,
        okToSend: true
      })
    } else if (response.message) {
      this.setState({
        message: response.message,
        error: true,
        okToSend: true
      })
    }
  }

  openForm () {
    this.setState({ open: true })
  }

  setStars (n) {
    this.setState({ grade: n })
  }

  render () {
    const { okToSend, comment, title, imagePreview, waitingApproval, grade, message, error, open } = this.state
    const {
      thankYouTitle, thankYouText, header, instructions, showImage, showTitle, titleTitle, commentTitle,
      commentPlaceholder, showStars, starsTitle, anonText, anonTitle, buttonText, image, loggedIn
    } = this.props

    return waitingApproval
      ? <div styleName="comment-sent">
        <div><ThumbIcon/></div>
        <div>
          <div styleName="title">{thankYouTitle}</div>
          <div>{thankYouText}</div>
        </div>
        <div>
          <button onClick={() => {
            this.setState({ waitingApproval: false })
            this.props.cancel()
          }}>Piilota
          </button>
        </div>
      </div>
      : (open
        ? <div styleName="write">
          <h3>{header}</h3>
          <p>{instructions}</p>
          {showImage ? <div styleName="file-input"
            style={imagePreview ? { backgroundImage: 'url(' + imagePreview + ')', border: 'none' } : null}>
            <input onFocus={() => this.openForm()} type="file" id="file" styleName="inputfile"
              accept="image/jpeg,.jpg,.jpeg,.jpe,image/png,.png,image/gif,.gif"
              onChange={(e) => this.fileChanged(e)}/>
            {/* eslint-disable-next-line jsx-a11y/label-has-for */}
            <label htmlFor="file">{imagePreview ? 'Vaihda kuva' : 'Lisää kuva'}</label>
          </div> : null}
          {showStars
            ? <div styleName="subtitle">{starsTitle}
              <span styleName="stars">
                <button styleName={grade > 0 ? 'yellow' : 'gray'} onClick={() => this.setStars(1)}>★</button>
                <button styleName={grade > 1 ? 'yellow' : 'gray'} onClick={() => this.setStars(2)}>★</button>
                <button styleName={grade > 2 ? 'yellow' : 'gray'} onClick={() => this.setStars(3)}>★</button>
                <button styleName={grade > 3 ? 'yellow' : 'gray'} onClick={() => this.setStars(4)}>★</button>
                <button styleName={grade > 4 ? 'yellow' : 'gray'} onClick={() => this.setStars(5)}>★</button>
              </span>
            </div>
            : null}
          {showTitle
            ? <Fragment>
              <div styleName="subtitle">{titleTitle}</div>
              <input type="text" onFocus={() => this.openForm()} onChange={(e) => this.onTitleChangeHandle(e)}
                value={title}/>
            </Fragment>
            : null}
          <div styleName="subtitle">
            {commentTitle}
          </div>
          <textarea onFocus={() => this.openForm()} onChange={(e) => this.onTextChangeHandle(e)} value={comment}
            placeholder={commentPlaceholder}></textarea>
          {error ? <div styleName="error">{message}</div> : null}
          <div styleName="buttons">
            <button styleName="cancel-button" onClick={() => this.props.cancel()}>Peruuta</button>
            <button disabled={!okToSend} onClick={() => this.submitComment()} styleName="submit-button">Lähetä
            </button>
          </div>
        </div>
        : <div styleName={image ? 'product-memory' : ''}>
          {image ? <div><img src={image} alt=""/></div> : null}
          <div styleName="blue-box">
            <h3>{anonTitle}</h3>
            <p>{anonText}</p>
            {loggedIn
            ? <button onClick={() => this.openForm()}>{buttonText}</button>
            : <a styleName="start-button" href={WP.getLoginUrl()}>
            Kirjaudu ensin sisään</a>}
          </div>
        </div>
      )
  }
}

export default withRouter(CustomForm)
