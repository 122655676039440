import React, { Component, Fragment } from 'react'
import WP from '@otavamedia/om-component-library/lib/lib/WP'
import ArticleListItem from '../general/article/ArticleListItem'
import './MostReadPaid.scss'
import PropTypes from 'prop-types'
import { IMAGE_SIZE } from '@otavamedia/om-component-library/lib/entities/ImageModel'
import track from 'react-tracking'

class MostReadPaid extends Component {
  _mounted = false
  state = {}

  static propTypes = {
    exclude: PropTypes.array.isRequired, // article id's to exclude
  }

  async loadData (props) {
    const articles = await WP.getMostReadPaid()
    if (this._mounted) {
      this.setState({ articles, exclude: props.exclude })
    }
  }

  componentDidMount () {
    this._mounted = true
    this.loadData(this.props)
  }

  componentWillUnmount () {
    this._mounted = false
  }

  UNSAFE_componentWillReceiveProps (props) {
    if (JSON.stringify(props) !== JSON.stringify(this.props)) {
      this.loadData(props)
    }
  }

  shouldComponentUpdate (nextProps, nextState) {
    return nextState !== this.state
  }

  render () {
    const { articles, exclude } = this.state
    if (!articles) {
      return null
    }
    const filteredArticles = articles.filter((article) => !exclude.includes(article.id))

    if (filteredArticles.length < 2) {
      return null
    }
    if (filteredArticles.length % 2 === 1) {
      filteredArticles.pop()
    }
    return (
      <Fragment>
        <div styleName="wrapper">
          <h2>Tilaajana pääset lukemaan myös nämä jutut:</h2>
          <div styleName="full-row">
            {filteredArticles.map((article, idx) => <div key={idx} styleName="half-col">
              <ArticleListItem article={article} size="md" relatedArticleListLimit={0}
                sizes="(max-width: 768px) 100vw, 400px" imageSize={IMAGE_SIZE.MEDIUM_LARGE}/>
            </div>)}
          </div>
        </div>
      </Fragment>
    )
  }
}

export default track({ gtmContext: ['MostReadPaid'] })(MostReadPaid)
