import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from '../general/util/Links'

import './PaywallBanner.scss'
import { connect } from 'kea'
import application from '@otavamedia/om-component-library/lib/kea/application'
import auth from '@otavamedia/om-component-library/lib/kea/auth'

@connect({
  props: [
    application, [
      'view',
    ],
    auth, [
      'shareTokenAccess',
    ],
  ],
})
class PaywallBanner extends Component {
  static propTypes = {
    showText: PropTypes.bool,
    shareTokenAccess: PropTypes.func,
    view: PropTypes.object
  }

  render () {
    const { showText, shareTokenAccess, view } = this.props

    if (!shareTokenAccess(view)) return null
    return <div styleName={'banner' + (showText ? '' : ' top-only')}>
      <div styleName="top-bar">Tämä on ilmaisnäyte M&K:n maksullisesta sisällöstä</div>
      {showText
        ? <div styleName="text">
          <p styleName="text-header">Kiitos, että viihdyit {window.om_constants.siteNameGen} artikkelin parissa!</p>
          <p>Tilaamalla {window.om_constants.siteNameGen} pääset lukemaan kaikkea tilaajille tarkoitettua sisältöämme,
             kuten kerran kuukaudessa ilmestyvän <Link to={{ link: '/digilehdet/' }}>digilehden</Link>,
             vuoteen 2003 ulottuvan <Link to={{ link: '/arkisto/' }}>lehtiarkistomme</Link> sekä
            kaikkea muuta digipalvelun sisältöä.</p>
          <p><Link to={{ link: 'https://tilaus.metsastysjakalastus.fi/tilaus?promoID=OM_linkki&promoName=OM_MK-shared&promoCreative=onlinetarjous&promoPosition=shared' }}>Tutustu tarkemmin uusille tilaajille tarkoitettuihin tarjouksiimme täällä!</Link></p>
        </div>
        : null
      }
    </div>
  }
}

export default PaywallBanner
