import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import solrSearch from '../../../lib/SolrSearch'
import { Link } from '../util/Links'
import { SearchIconBlue } from '../../widgets/Icons'
import classnames from 'classnames'
import './SearchMenu.scss'
import { ErrorPlaceholder, withErrorBoundary } from '../util/ErrorBoundaries'
import { connect } from 'kea'
import application from '@otavamedia/om-component-library/lib/kea/application'
import HTML from '@otavamedia/om-component-library/lib/util/HTML'
import format from 'date-fns/format'
import track from 'react-tracking'

const TRANSITION = {
  IN: 'transitionIn',
  OUT: 'transitionOut',
}

@connect({
  actions: [
    application, [
      'setViewData',
    ]
  ],
})
class SearchMenu extends Component {
  constructor (props) {
    super(props)

    this.resultNodes = []

    this.state = {
      open: false,
      searchString: '',
      style: TRANSITION.OUT,
      searchResults: [],
    }
  }

  static propTypes = {
    open: PropTypes.bool,
    closeMenu: PropTypes.func,
    history: PropTypes.object,
    transitionInStyle: PropTypes.object,
    transitionOutStyle: PropTypes.object,
    actions: PropTypes.object,
  }

  componentDidMount () {
    this.updateData(this.props)
    if (this.searchInput) this.searchInput.focus()
  }

  componentWillUnmount () {
    clearTimeout(this.transitionAction)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    this.updateData(nextProps)
  }

  updateData = (props) => {
    if (props.open) {
      this.setState({
        open: true,
      })

      if (this.searchInput) {
        this.searchInput.focus()
      }

      if (this.transitionAction) clearTimeout(this.transitionAction)
      this.transitionAction = setTimeout(this.transitionIn, 10)
    } else {
      this.transitionOut()
    }
  }

  transitionIn = () => {
    this.setState({
      style: TRANSITION.IN,
    })
  }

  transitionOut = () => {
    this.setState({
      style: TRANSITION.OUT,
      searchResults: [],
      showAutocomplete: false
    })
  }

  closeMenu = () => {
    this.props.closeMenu()
    this.setState({
      searchResults: [],
      showAutocomplete: false,
    })
  }

  handleInput = (event) => {
    this.setState({
      searchString: event.currentTarget.value,
    }, this.showAutocomplete.bind(this))
  }

  doSearch = () => {
    this.props.history.push('/haku?sort=&searchString=' + encodeURIComponent(this.state.searchString))
    this.props.actions.setViewData({ _meta: 'search' })
    this.props.closeMenu()
    this.setState({
      searchResults: [],
      showAutocomplete: false,
    })
  }

  searchOnEnter = (e) => {
    if (e.key === 'Enter') {
      this.doSearch()
    } else if (e.keyCode === 27) {
      this.props.closeMenu()
      this.setState({
        searchResults: [],
        showAutocomplete: false,
      })
    }
  }

  handleInputKeyDown = (e) => {
    const { searchResults } = this.state
    if ((e.key === 'ArrowDown')) {
      e.preventDefault()
      if (searchResults.length <= 0) return
      this.resultNodes[0].children[0].focus()
    }
  }

  handleItemKeyDown = (e) => {
    const { searchResults } = this.state
    if ((e.key === 'ArrowDown' || e.key === 'ArrowUp')) {
      e.preventDefault()
      if (searchResults.length <= 0) return

      const active = document.activeElement.parentNode
      const next = e.key === 'ArrowDown' ? active.nextSibling : active.previousSibling
      if (next) {
        next.children[0].focus()
      } else {
        if (e.key === 'ArrowUp') {
          this.searchInput.focus()
        }
      }
    }
  }

  async showAutocomplete () {
    if (this.state.searchString && this.state.searchString.length >= 3) {
      const searchResults = await WP.elasticSearch(
        this.state.searchString,
        'BEST_MATCH',
        {
          publishedAfter: '1953-01-01T00:00:00.000+02:00',
          publishedBefore: '2053-12-31T23:59:59.999+02:00',
          include: 'ALL'
        },
        1,
        10,
        null,
        null,
        'Metsästys ja Kalastus')
      this.setState({
        searchResults: searchResults && searchResults.hits,
        showAutocomplete: true,
      })
    } else {
      this.setState({
        searchResults: [],
        showAutocomplete: false,
      })
    }
  }

  transitionEnd = (e) => {
    if (e.target !== e.currentTarget) return // ignore transitions in children

    if (this.props.open) {
      this.searchInput.focus()
    } else {
      this.setState({
        open: false
      })
    }
  }

  replace (url) {
    if (url.includes('/digilehti') || url.includes('/nakoislehti')) {
      return url
    }
    return new URL(url).pathname
  }

  render () {
    const {
      transitionInStyle,
      transitionOutStyle,
    } = this.props

    const containerStyles = classnames('search-container', this.state.style, {
      open: this.state.open,
    })

    const transitionStyle = this.state.style === TRANSITION.IN ? transitionInStyle : transitionOutStyle

    return this.state.open && (
      <div onTransitionEnd={this.transitionEnd} styleName={containerStyles} style={transitionStyle}>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div styleName="menu" onKeyDown={(e) => e.stopPropagation()}
          onTouchStart={(e) => e.stopPropagation()}
          onTouchMove={(e) => e.stopPropagation()}
          onMouseDown={(e) => e.stopPropagation()}>
          <Fragment>
            <div styleName="input-row">
              <input
                ref={(input) => { this.searchInput = input }}
                onChange={this.handleInput}
                onKeyUp={this.searchOnEnter}
                onKeyDown={this.handleInputKeyDown}
                placeholder={`Hae ${window.om_constants.aboutSite}`}
              />
              <button styleName="search-button" onClick={this.doSearch}>
                <SearchIconBlue />
              </button>
            </div>
            <div styleName="close-button">
              <button onClick={this.closeMenu}>
                <span styleName="close-icon">
                  <span aria-hidden="true">+</span>
                </span>Sulje haku
              </button>
            </div>
            {this.state.showAutocomplete && this.state.searchResults && <ul styleName="search-autocomplete">
              {this.state.searchResults.map((result, idx) => {
                const title =
                  (result.customValues.issue_name ? result.customValues.issue_name + ', ' : '') + // eslint-disable-next-line eqeqeq
                  (result.title == parseInt(result.title)
                      ? 'sivu ' + result.title
                      : result.title
                  ) +
                  ' (' + format(new Date(result.published), 'DD.MM.YYYY') + ')'
                return (
                  <li
                    key={idx}
                    ref={(ref) => { this.resultNodes[idx] = ref }}
                  >
                    <Link
                      to={{ link: this.replace(result.url) }}
                      localContext="SearchMenu"
                      onClick={this.closeMenu}
                      onKeyDown={this.handleItemKeyDown}
                    >
                      <span><HTML>{title}</HTML></span>
                    </Link>
                  </li>
                )
              })}
            </ul>}
          </Fragment>
        </div>
      </div>
    )
  }
}

export default track({ gtmContext: ['SearchMenu'] })(withErrorBoundary(
  withRouter(SearchMenu),
  ErrorPlaceholder(null)
))
