import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'kea'
import ReactPlaceholder from 'react-placeholder'

// import Pagination from './Pagination'
import { ErrorPlaceholder, withErrorBoundary } from '../util/ErrorBoundaries'
import ArticleListItem from '../article/ArticleListItem'
import application, { errorPropTypes } from '@otavamedia/om-component-library/lib/kea/application'
import GAMNativeAd from '../../widgets/GAMNativeAd'
import NewsfeedLoader from '../util/NewsfeedLoader'
import NoAdBlocker from '../../widgets/NoAdBlocker'
import MagShortCut from '../../widgets/MagShortCut'
import { getType } from '@otavamedia/om-component-library/lib/lib/errors'
import RenderedError from '../util/RenderedError'
import { STATUS } from '@otavamedia/om-component-library/lib/lib/request-state'
import { IMAGE_SIZE } from '@otavamedia/om-component-library/lib/entities/ImageModel'
import { AdsForDesktop, AdsForMobile, AdSlotInText, AdSlotInTextWide, AdSlotMob2 } from '../ads/Ads'

import './PaginatingList.scss'
import track from 'react-tracking'

/* eslint-disable react/display-name */
export const defaultMessages = {
  offline: <p>{'Nettiyhteyttä ei ole, joten sisältöä ei pystytä lataamaan.'}</p>,
  noItems: <p>Ei ole enempää artikkeleita.</p>,
  error: <p>Virhe ladattaessa artikkeleita.</p>,
  pageInfo: (page, maxPages) => <p>Sivu {page} (yhteensä {maxPages} sivua).</p>,
  entryInfo: (maxItems) => <p>Yhteensä {maxItems} osumaa.</p>
}
/* eslint-enable react/display-name */

@connect({
  props: [
    application, [
      'settings',
      'error',
    ],
  ],
})
class PaginatingList extends Component {
  _mounted = false
  asyncSetState (...args) {
    // avoid setting state after unmount in case async operations attempts to do so
    if (this._mounted) this.setState(...args)
  }

  node = null
  status = STATUS.NOT_REQUESTED

  constructor (props) {
    super()
    this.node = React.createRef()
    this.state = {
      items: [],
      page: props.page || 1,
      maxPages: 0,
      maxItems: 0,
      link: undefined,
    }
  }

  static propTypes = {
    loadItems: PropTypes.func,
    ListItem: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.instanceOf(ArticleListItem)
    ]),
    messages: PropTypes.object,
    context: PropTypes.object.isRequired,
    className: PropTypes.string,
    paginationProps: PropTypes.object,
    page: PropTypes.number,
    error: errorPropTypes,
    settings: PropTypes.object,
    onLoad: PropTypes.func,
    displayQueryInfo: PropTypes.bool,
    wait: PropTypes.bool,
    blacklist: PropTypes.array,
    layout: PropTypes.object,
    disablePagination: PropTypes.bool,
    notShown: PropTypes.object,
    sizes: PropTypes.string
  }

  static defaultProps = {
    messages: defaultMessages,
    onLoad: () => null,
  }

  getMessageFromStatus () {
    const { items } = this.state
    const { status: statusCode } = this
    const { error, settings: { offline }, messages } = this.props

    switch (statusCode) {
    case STATUS.NOT_REQUESTED:
      return false
    case STATUS.REQUESTED:
      return false
    case STATUS.DONE:
      if (!items.length && offline) {
        return messages.offline
      } else if (!items.length) {
        return messages.noItems
      }

      return false
    case STATUS.ERROR:
      if (offline) {
        return messages.offline
      } else if (error) {
        if (getType(error.message)) {
          return <RenderedError error={error}/>
        }
      }

      console.log(error)
      return messages.error

    default:
      return false
    }
  }

  async reset () {
    this.status = STATUS.NOT_REQUESTED
    return new Promise(resolve => this.asyncSetState({
      link: this.props.context.link,
      items: [],
      page: this.props.page || 1,
      maxPages: 0,
      maxItems: 0
    }, resolve))
  }

  resetOnPageChange () {
    this.status = STATUS.NOT_REQUESTED
  }

  async populate () {
    const { context, loadItems, onLoad, wait, blacklist } = this.props
    const { page } = this.state

    if (wait) {
      return
    }

    if (!Object.keys(context).length) {
      this.reset()
      return false
    }

    try {
      if (this.status === STATUS.NOT_REQUESTED) {
        this.status = STATUS.REQUESTED
        let { items, maxPages, maxItems } = await loadItems(page, context, blacklist)
        this.status = STATUS.DONE
        if (items) {
          items = items.filter(i => i)
          this.asyncSetState({
            page, items, maxPages, maxItems
          })
        }
        onLoad(this.state)
      }
    } catch (e) {
      console.log(e)
      this.status = STATUS.ERROR
      this.asyncSetState({ page: 1, items: null, error: e }, () => onLoad(this.state))
    }
  }

  async componentDidUpdate (prevProps, prevState) {
    const pageSame = prevState.page === this.state.page
    const linkSame = this.state.link === this.props.context.link

    if (!linkSame && !this.props.wait) {
      await this.reset()
      await this.populate()
    } else if (!pageSame) {
      await this.resetOnPageChange()
      await this.populate()
    }
  }

  async componentDidMount () {
    this._mounted = true

    await this.populate()
  }

  componentWillUnmount () {
    this._mounted = false
  }

  UNSAFE_componentWillReceiveProps (newProps) {
    if (newProps.page !== this.props.page) {
      this.setState({ page: newProps.page })
    }
  }

  queryInfo () {
    const { messages } = this.props
    const { page, maxPages, maxItems } = this.state

    return (
      <div className="query-info">
        {messages.pageInfo(page, maxPages)}
        {messages.entryInfo(maxItems)}
      </div>
    )
  }

  renderContents (articles, layout) {
    const { ListItem, sizes } = this.props
    const { nativeAds, page } = this.state
    let articleId = 0
    let prevItem
    return layout.boxes.map((box, idx) => {
      if (!articles[articleId]) { // we don't have any more articles, so don't render ads either.
        return null
      }
      const boxContent = box.items.map((item, idx) => {
        const prev = prevItem
        prevItem = item

        let size = item.size
        if (size === 'auto') {
          switch (0) {
          case box.items.length % 3:
            size = 'three-columns'
            break
          case box.items.length % 4:
            size = 'four-columns'
            break
          case box.items.length % 2:
            size = 'sm'
            break
          default:
            size = 'three-columns'
          }
        }
        if (item.contentType === 'ad') {
          switch (size) {
          case 'lg':
          case 'xs':
            return (
              <Fragment key={idx}>
                <AdsForDesktop>
                  <AdSlotInTextWide fallBackElements={[NoAdBlocker]}/>
                </AdsForDesktop>
                {(!prev || prev.contentType !== 'ad') && (
                  <AdsForMobile>
                    <AdSlotMob2 fallBackElements={[NoAdBlocker]}/>
                  </AdsForMobile>
                )}
              </Fragment>
            )
          case 'md':
            return (
              <Fragment key={idx}>
                <AdsForDesktop>
                  <AdSlotInText fallBackElements={[NoAdBlocker]}/>
                </AdsForDesktop>
                {(!prev || prev.contentType !== 'ad') && (
                  <AdsForMobile>
                    <AdSlotMob2 fallBackElements={[NoAdBlocker]}/>
                  </AdsForMobile>
                )}
              </Fragment>
            )
          }
        } else if (item.contentType === 'nativeAd' && nativeAds.length) {
          return (
            <GAMNativeAd key={idx} fullSize={true} section="ArticleList" type={2}/>
          )
        } else if (item.contentType === 'magazine' && page === item.page) {
          return <div className="ArticleListItem_lg" key={idx}><MagShortCut className={item.className} isTheme={item.isTheme}/></div>
        }
        const article = articles[articleId++]
        return (
          <ListItem
            sticky={item.contentType === 'sticky' && article && article.sticky}
            article={article}
            size={size}
            key={idx}
            noImage={item.noImage}
            notShown={{ relatedArticles: !item.showRelated }}
            metaInsideContent={size === 'xs'}
            sizes={sizes}
            imageSize={IMAGE_SIZE.LARGE}
          />
        )
      })
      return <div styleName={box.boxSize} key={idx}>{boxContent}</div>
    })
  }

  render () {
    const { items, error } = this.state
    const { className, displayQueryInfo, layout } = this.props

    if (error) {
      throw error
    }
    const message = this.getMessageFromStatus() // false
    return (
      <div className={`${className || ''} paginating-list`} styleName="paginatingList"
        ref={(ref) => { this.node = ref }}>
        {message || (
          <ReactPlaceholder ready={this.status === STATUS.DONE} delay={0} customPlaceholder={<NewsfeedLoader />}>
            {this.renderContents(items, layout)}
            {displayQueryInfo ? this.queryInfo() : false}
            {/* disablePagination ? null : <Pagination page={page} maxPages={maxPages || 0} changePage={(page) => {
              this.status = STATUS.NOT_REQUESTED
              this.setState({
                page,
              })
            }} {...paginationProps} /> */}
          </ReactPlaceholder>
        )}
      </div>
    )
  }
}

export default track({ gtmContext: ['PaginatingList'] })(withErrorBoundary(
  PaginatingList,
  ErrorPlaceholder()
))
