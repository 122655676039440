import React, { Component } from 'react'
import PropTypes from 'prop-types'
import RelatedArticle from './RelatedArticle'
import './RelatedArticle.scss'
import track from 'react-tracking'

@track({ gtmContext: ['RelatedArticleList'] })
class RelatedArticleList extends Component {
  static propTypes = {
    related: PropTypes.array.isRequired,
    limit: PropTypes.number.isRequired,
  }

  static defaultProps = {
    limit: Number.POSITIVE_INFINITY,
  }

  render () {
    const { related, limit } = this.props
    return (
      <div styleName="also-read">
        <h2>Lue myös</h2>
        <ul styleName="list">
          {related.slice(0, limit).map(related => (
            <li key={related.id}>
              <RelatedArticle article={related}/>
            </li>
          ))}
        </ul>
      </div>
    )
  }
}

export default RelatedArticleList
