import React, { Component } from 'react'
import PropTypes from 'prop-types'
import HTML from '@otavamedia/om-component-library/lib/util/HTML'
import './SideStories.scss'

class SideStories extends Component {
  static propTypes = {
    sidestories: PropTypes.array,
    margins: PropTypes.bool,
  }

  render () {
    const { sidestories, margins } = this.props

    if (!sidestories || !sidestories.length) return null
    return <div styleName={`sidestories-container ${margins ? 'margins' : ''}`}>
      {sidestories.map((item, idx) => {
        return <div styleName="sidestory full-length" key={idx}><HTML>{item.sidestory}</HTML></div>
      })}
    </div>
  }
}

export default SideStories
