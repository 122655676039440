import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import './CatchCard.scss'
import DateField from '../widgets/DateField'
import WP from '@otavamedia/om-component-library/lib/lib/WP'
import get from 'lodash/get'
import { connect } from 'kea'
import auth from '@otavamedia/om-component-library/lib/kea/auth'
import { Link } from '../util/Links'
import ShareButtons from '../widgets/ShareButtons'
import { IMAGE_SIZE } from '@otavamedia/om-component-library/lib/entities/ImageModel'
import Image from '../util/Image'
import track from 'react-tracking'
import { withRouter } from 'react-router-dom'
import { DeerPin, ElkPin, FishPin } from '../../widgets/Icons'
import { formatNumString } from '@otavamedia/om-component-library/lib/lib/utils'

const ConnectedComponent = @track({ gtmContext: ['CatchCard'] }) @connect({
  props: [
    auth, [
      'userData',
    ],
  ],
  actions: [
    auth, [
      'setCommenting',
      'updateModal'
    ]
  ]
})
  /**
   * This displays a single catch card (saaliskortti). It can be displayed in different formats with the 'compact',
   * 'full' and 'highlight' parameters. Can also be displayed without any of those parameters.
   *
   * Full: displays a full page card.
   * Compact: displays a tiny card used in listings.
   * (no parameter): displays a medium size card used in listings
   * Highlight: displays a slightly larger size used in listings (to highlight the first item)
   *
   */
  class CatchCard extends Component {
  static propTypes = {
    data: PropTypes.object,
    setComment: PropTypes.func,
    setCommenting: PropTypes.func,
    isLast: PropTypes.bool,
    isReply: PropTypes.bool,
    userData: PropTypes.object,
    admins: PropTypes.array,
    showStars: PropTypes.bool,
    location: PropTypes.object,
    showTitle: PropTypes.bool,
    showImage: PropTypes.bool,
    hideActions: PropTypes.bool,
    text: PropTypes.string,
    compact: PropTypes.bool,
    full: PropTypes.bool,
    withForm: PropTypes.bool,
    highlight: PropTypes.bool,
    type: PropTypes.string,
    imagePreviews: PropTypes.array,
  }

  state = {}

  constructor (props) {
    super(props)
    this.state.thumbs = get(props, 'data.acf.peukut', [])
  }

  UNSAFE_componentWillReceiveProps (props) {
    const thumbs = get(props, 'data.acf.peukut', [])
    this.setState({ thumbs })
  }

  async toggleLike () {
    if (this.props.userData) {
      const like = !!this.props.userData && !!this.state.thumbs && this.state.thumbs.includes(this.props.userData.id)
      let thumbs = this.state.thumbs || []
      if (!like) {
        thumbs.push(this.props.userData.id)
      } else {
        thumbs = thumbs.filter(x => (x !== this.props.userData.id))
      }
      this.setState({ thumbs })
      await WP.likeBlogComment(this.props.data, !like)
    }
  }

  formatTime (value) {
    return value ? value.replace(/(\d+):(\d+):(\d+)/, '$1.$2') : ''
  }

  formatItem (value, item) {
    if (item.replace && (typeof value === 'string' || value instanceof String)) {
      return value.replace(item.match, item.replace)
    }
    return value
  }

  renderField (item, formAnswers, key) {
    if (item.type !== 'row' && (formAnswers[item.field] === '' || formAnswers[item.field] === null || formAnswers[item.field] === undefined)) {
      return null
    }

    switch (item.type) {
    case 'row':
      return item.items.map((subItem, idx3) => {
        const rowData = this.renderField(subItem, formAnswers)
        return rowData ? <div key={key + ' ' + idx3} styleName="summary-item">{rowData}</div> : null
      })
    case 'radio-block':
      return <div key={key} styleName="summary-item">
        {item.noLabel
          ? <b styleName="label">{formAnswers[item.field]}</b>
          : <Fragment><b styleName="label">{item.name}: </b>{item.options[formAnswers[item.field]]}
          </Fragment>}
      </div>
    case 'date':
      return <div key={key} styleName="summary-item">
        {item.noLabel
          ? <b styleName="label"><DateField date={formAnswers[item.field]} dateOnly/></b>
          : <Fragment><b styleName="label">{item.name}: </b><DateField
            date={formAnswers[item.field]}
            dateOnly/></Fragment>}
      </div>
    case 'time':
      return <div key={key} styleName="summary-item">
        {item.noLabel
          ? <b styleName="label">{this.formatTime(formAnswers[item.field])}</b>
          : <Fragment><b styleName="label">{item.name}: </b>{this.formatTime(formAnswers[item.field])}
          </Fragment>}
      </div>
    default:
      return <div key={key} styleName="summary-item">
        {item.noLabel
          ? <b styleName="label">{formAnswers[item.field]}</b>
          : <Fragment><b styleName="label">{item.name}: </b>{this.formatItem(formAnswers[item.field], item)} {item.unit}
          </Fragment>}
      </div>
    }
  }

  renderSummary (type) {
    const { data } = this.props
    const formAnswers = data.acf

    return textFields[type].map((formPage, page) =>
      <div key={'x' + page} styleName="column">
        {formPage.map((section, key) => {
          const itemData = section.items.map((item, key2) => this.renderField(item, formAnswers, key2)).filter(x => x)
          return itemData.length
            ? <div key={'x' + page + 'x' + key} styleName="">
              {section.topHeader && <h3 styleName="subtitle">{section.topHeader}</h3>}
              {section.title && <h3>{section.title}</h3>}
              {itemData}
            </div>
            : null
        }
        )}
      </div>
    )
  }

  renderImagePreview () {
    const imagePreviews = this.props.imagePreviews
    return <Fragment>
      <div styleName="image preview" style={{ backgroundImage: 'url(' + imagePreviews[0] + ')', border: 'none' }}>
      </div>
      <div styleName="more-images">
        {imagePreviews && imagePreviews.map((imagePreview, key) => !!key && <div styleName="additional-image preview" key={key}
          style={{ backgroundImage: 'url(' + imagePreview + ')', border: 'none' }}>
        </div>)}
      </div>
    </Fragment>
  }

  render () {
    const { data, isLast, userData, compact, full, withForm, type, highlight, imagePreviews } = this.props
    const { thumbs } = this.state

    if (!data) {
      return null
    }

    let url
    let title
    if (type === 'fish') {
      url = '/kymppikerho/'
      title = 'Haukien kymppikerho ja mittakalakerho'
    } else if (type === 'deer') {
      url = '/peurakerho/'
      title = 'Peurojen kymppikerho'
    } else {
      url = '/hirvikerho/'
      title = 'Hirvien kymppikerho'
    }

    const like = !!userData && !!thumbs && thumbs.includes(userData.id)
    const thumbCount = thumbs.length
    const kuvat = data.acf.kuvat
    const kuva = kuvat && kuvat[0]

    if (compact) {
      return <div styleName="main compact" id={isLast ? 'lastCard' : ''}>
        {kuva && <Link to={{ link: url + data.id }}>
          <div styleName="image">
            <Image data={kuva} size={IMAGE_SIZE.THUMBNAIL} sizes={'225px'}/>
          </div>
        </Link>}
        <div styleName="header">
          <div styleName="meta">
            <Link to={{ link: url + data.id }}>
              <ul>
                <li>
                  <div>{data.acf.kalastajan_nimi}</div>
                </li>
                <li>
                  <div>{type === 'fish' ? formatNumString(data.acf.paino, 1) : formatNumString(data.acf.ruhopaino)} kg</div>
                  <div>{type === 'fish' ? formatNumString(data.acf.pituus) : formatNumString(data.acf.sarvien_leveys)} cm</div>
                </li>
              </ul>
            </Link>
          </div>
        </div>
      </div>
    } else if (full) {
      return <div styleName={withForm ? 'full with-form' : 'full'}>
        <div styleName="top-meta">{withForm ? null : (type === 'fish' ? <FishPin/> : (type === 'elk' ? <ElkPin/> : <DeerPin/>))} {title}</div>
        <h1>{data.acf.kalastajan_nimi}</h1>
        <div styleName="meta">
          <ul>
            <li>
              <span>{type === 'fish' ? formatNumString(data.acf.paino, 1) + ' kg' : data.acf.piikit + ' piikkiä'}</span>
            </li>
            <li>
              <span>{type === 'fish' ? formatNumString(data.acf.pituus) : formatNumString(data.acf.sarvien_leveys)} cm</span>
            </li>
            <li>
              <span><DateField date={data.acf.saantipaiva} dateOnly/></span>
            </li>
            <li>
              <span>{type === 'fish' ? '' : data.acf.paikkakunta}</span>
            </li>
          </ul>
        </div>
        {imagePreviews
          ? this.renderImagePreview()
          : <Fragment>
            <div styleName="image">
              {kuva && <Image data={kuva} size={IMAGE_SIZE.MEDIUM_LARGE} sizes={'(min-width: 768px) 62vw, 100vw'} addToGallery/>}
            </div>
            <div styleName="more-images">
              {kuvat && kuvat.map((img, key) => !!key && <div styleName="additional-image" key={key}>
                <Image data={img} size={IMAGE_SIZE.THUMBNAIL} sizes={'225px'} addToGallery/>
              </div>)}
            </div>
          </Fragment>}
        {withForm
          ? null
          : <div styleName="footer">
            <div styleName={'like-button ' + (like ? 'i-like' : '')}>
              <button onClick={() => this.toggleLike()}>{'\uf164'}</button>
              {thumbCount > 0 ? thumbCount : null}
            </div>
            <div>
              <ShareButtons article={{ link: window.location.pathname }} shareCount={0}/>
            </div>
          </div>}
        <div styleName="texts">
          {this.renderSummary(type)}
        </div>
      </div>
    } else if (highlight) {
      return <div styleName={'main highlight'} id={isLast ? 'lastCard' : ''}>
        <div styleName="image">
          <Link to={{ link: url + data.id }}>
            {kuva && <Image data={kuva} size={IMAGE_SIZE.MEDIUM_LARGE} sizes={'(min-width: 768px) 62vw, 100vw'}/>}
          </Link>
        </div>
        <div styleName="header">
          <Link to={{ link: url + data.id }}>
            <div styleName="name">
              {data.acf.kalastajan_nimi}
            </div>
          </Link>
          <div>
            <div styleName={'like-button ' + (like ? 'i-like' : '')}>
              <button onClick={() => this.toggleLike()}>{'\uf164'}</button>
              {thumbCount > 0 ? thumbCount : null}
            </div>
            <div styleName="meta">
              <ul>
                <li>
                  <span>{type === 'fish' ? formatNumString(data.acf.paino, 1) + ' kg' : data.acf.piikit + ' piikkiä'}</span>
                </li>
                <li>
                  <span>{type === 'fish' ? formatNumString(data.acf.pituus) : formatNumString(data.acf.sarvien_leveys)} cm</span>
                </li>
                <li>
                  <span><DateField date={data.acf.saantipaiva} dateOnly/></span>
                </li>
                <li>
                  <span>{type === 'fish' ? '' : data.acf.paikkakunta}</span>
                </li>
              </ul>
            </div>
            {data.acf.catch_release
              ? <div styleName="catch-release">
                <span>C&amp;R</span>
              </div>
              : null}
          </div>
        </div>
      </div>
    }
    return <div styleName={'main'} id={isLast ? 'lastCard' : ''}>
      <div styleName="image">
        <Link to={{ link: url + data.id }}>
          {kuva && <Image data={kuva} size={IMAGE_SIZE.MEDIUM_LARGE} sizes={'(min-width: 768px) 62vw, 100vw'}/>}
        </Link>
      </div>
      <div styleName="header">
        <Link to={{ link: url + data.id }}>
          <div styleName="name">
            {data.acf.kalastajan_nimi}
          </div>
          <div styleName="meta">
            <ul>
              <li>
                <span>{type === 'fish' ? formatNumString(data.acf.paino, 1) + ' kg' : data.acf.piikit + ' piikkiä'}</span>
              </li>
              <li>
                <span>{type === 'fish' ? formatNumString(data.acf.pituus) : formatNumString(data.acf.sarvien_leveys)} cm</span>
              </li>
              <li>
                <span><DateField date={data.acf.saantipaiva} dateOnly/></span>
              </li>
              <li>
                <span>{type === 'fish' ? '' : data.acf.paikkakunta}</span>
              </li>
            </ul>
          </div>
        </Link>
      </div>
      <div styleName="footer">
        <div styleName={'like-button ' + (like ? 'i-like' : '')}>
          <button onClick={() => this.toggleLike()}>{'\uf164'}</button>
          {thumbCount > 0 ? thumbCount : null}
        </div>
        {data.acf.catch_release
          ? <div styleName="catch-release">
            <span>C&amp;R</span>
          </div>
          : null}
      </div>
    </div>
  }
}

export default withRouter(ConnectedComponent)

const textFields = {
  fish:
    [
      [ // page 2
        {
          title: 'Hauen tiedot',
          items: [
            {
              name: 'Paino',
              field: 'paino',
              style: { width: '50%' },
              match: '.',
              replace: ',',
              unit: 'kg'
            },
            {
              name: 'Pituus',
              field: 'pituus',
              style: { width: '50%' },
              match: '.',
              replace: ',',
              unit: 'cm'
            },
            {
              name: 'Suurin ympärysmitta',
              field: 'suurin_ymparysmitta',
              unit: 'cm',
              match: '.',
              replace: ',',
            },
            {
              name: 'Catch & release',
              type: 'radio-block',
              options: { false: 'Ei', true: 'Kyllä' },
              field: 'catch_release'
            },
            {
              name: 'Saantipäivä',
              field: 'saantipaiva',
              type: 'date',
              style: { width: '50%' },
            },
            {
              name: 'Kellonaika',
              field: 'saantiaika',
              type: 'time',
              style: { width: '50%' },
            },
            {
              name: 'Saantitapa',
              type: 'select',
              field: 'saantitapa',
              options: ['Heittokalastus', 'Moottoriuistelu', 'Soutu-uistelu', 'Onki', 'Pilkki', 'Perho']
            },
          ]
        },
        {
          topHeader: 'Vieheen tiedot',
          items: [
            {
              name: 'Vieheen nimi',
              field: 'vieheen_nimi'
            },
            {
              name: 'Paino',
              field: 'vieheen_paino',
              style: { width: '50%' },
              match: '.',
              replace: ',',
              unit: 'g'
            },
            {
              name: 'Pituus',
              field: 'vieheen_pituus',
              style: { width: '50%' },
              match: '.',
              replace: ',',
              unit: 'mm'
            },
            {
              name: 'Vieheen pääväri',
              field: 'vieheen_paavari'
            },
            {
              name: 'Vieheen muut värit',
              field: 'vieheen_muut_varit'
            },
          ]
        }
      ],
      [ // page 3
        {
          title: 'Pyyntipaikka',
          items: [
            {
              name: 'Vesistö',
              type: 'select',
              field: 'vesisto',
              options: ['Meri', 'Joki', 'Järvi']
            },
            {
              name: 'Paikan koordinaatit',
              field: 'koordinaatit'
            },
            {
              name: 'Pyyntipaikan lisätiedot',
              type: 'textarea',
              field: 'pyyntipaikan_lisatiedot'
            }
          ]
        },
        {
          topHeader: 'Lisätiedot',
          items: [
            {
              name: 'Lisätiedot',
              field: 'lisatiedot',
              noLabel: true
            },
          ]
        }
      ],
    ],
  elk: [
    [ // page 2
      {
        topHeader: 'Kaadon tiedot',
        items: [
          {
            type: 'row',
            items: [
              {
                name: 'Punnittu ruhopaino',
                field: 'ruhopaino',
                unit: 'kg',
                style: { width: '60%' },
                match: '.',
                replace: ',',
              },
              {
                name: 'Sarvien leveys',
                field: 'sarvien_leveys',
                unit: 'cm',
                style: { width: '40%' },
                match: '.',
                replace: ',',
              }
            ]
          },
          {
            name: 'Piikkien lukumäärä (väh 2,5cm piikit)',
            field: 'piikit',
          },
          {
            name: 'Saantipäivä',
            field: 'kaatopaiva',
            type: 'date',
          },
          {
            name: 'Kellonaika',
            field: 'kaatoaika',
            type: 'time',
          },
          {
            name: 'Paikkakunta',
            field: 'paikkakunta'
          },
          {
            name: 'Jahtitapa',
            field: 'jahtitapa',
            type: 'select',
            options: ['Koira-ajo']
          },
          {
            name: 'Muu jahtitapa, mikä',
            field: 'muu_jahtitapa'
          },
          {
            name: 'Koiran rotu',
            field: 'rotu'
          },
          {
            name: 'Koiran nimi',
            field: 'koiran_nimi'
          },
          {
            name: 'Sää',
            field: 'saa',
          }
        ],
      },
    ],
    [ // page 3
      {
        topHeader: 'Ase ja tähtäin',
        items: [
          {
            type: 'row',
            items: [
              {
                name: 'Ampumaetäisyys',
                field: 'ampumaetaisyys',
                style: { width: '50%' },
                match: '.',
                replace: ',',
                unit: 'm'
              },
              {
                name: 'Laukausten määrä',
                field: 'laukausten_maara',
                style: { width: '50%' },
                unit: 'kpl'
              },
            ]
          },
          {
            name: 'Hirven kulku 1. osuman jälkeen',
            field: 'kulku',
            match: '.',
            replace: ',',
            unit: 'm'
          },
          {
            name: 'Aseen merkki',
            field: 'aseen_merkki'
          },
          {
            name: 'Kaliiperi',
            field: 'kaliiperi'
          },

          {
            name: 'Patruunan tyyppi',
            type: 'select',
            field: 'patruunan_tyyppi',
            options: ['Tehdaspatruuna', 'Itseladattu']
          },
          {
            name: 'Käytetty luoti',
            field: 'kaytetty_luoti'
          },
          {
            name: 'Käytetty patruuna',
            field: 'kaytetty_patruuna'
          },
          {
            name: 'Luodin paino',
            field: 'luodin_paino',
            unit: 'g',
            match: '.',
            replace: ',',
          },
          {
            name: 'Tähtäin',
            type: 'select',
            field: 'tahtain',
            options: ['Avo', 'Kiikari']
          },
          {
            name: 'Tähtäimen merkki',
            field: 'tahtaimen_merkki'
          },
          {
            name: 'Suurennos',
            field: 'suurennos'
          }
        ]
      },
      {
        topHeader: 'Lisätiedot',
        items: [
          {
            name: 'Lisätiedot',
            field: 'lisatiedot',
            noLabel: true
          },
        ]
      },
    ],
  ],
  deer: [
    [ // page 2
      {
        topHeader: 'Kaadon tiedot',
        items: [
          {
            type: 'row',
            items: [
              {
                name: 'Punnittu ruhopaino',
                field: 'ruhopaino',
                unit: 'kg',
                style: { width: '60%' },
                match: '.',
                replace: ',',
              },
              {
                name: 'Sarvien leveys',
                field: 'sarvien_leveys',
                unit: 'cm',
                style: { width: '40%' },
                match: '.',
                replace: ',',
              }
            ]
          },
          {
            name: 'Piikkien lukumäärä (väh 2,5cm piikit)',
            field: 'piikit',
          },
          {
            type: 'row',
            items: [
              {
                name: 'Saantipäivä',
                field: 'kaatopaiva',
                type: 'date',
                style: { width: '60%' },
              },
              {
                name: 'Kellonaika',
                field: 'kaatoaika',
                style: { width: '40%' },
              },
            ]
          },
          {
            name: 'Paikkakunta',
            field: 'paikkakunta'
          },
          {
            name: 'Jahtitapa',
            field: 'jahtitapa',
            type: 'select',
            options: ['Koira-ajo']
          },
          {
            name: 'Muu jahtitapa, mikä',
            field: 'muu_jahtitapa'
          },
          {
            name: 'Koiran rotu',
            field: 'rotu'
          },
          {
            name: 'Koiran nimi',
            field: 'koiran_nimi'
          },
          {
            name: 'Sää',
            field: 'saa',
          },
        ],
      },
    ],
    [ // page 3
      {
        topHeader: 'Ase ja tähtäin',
        items: [
          {
            type: 'row',
            items: [
              {
                name: 'Ampumaetäisyys',
                field: 'ampumaetaisyys',
                style: { width: '50%' },
                unit: 'm',
                match: '.',
                replace: ',',
              },
              {
                name: 'Laukausten määrä',
                field: 'laukausten_maara',
                style: { width: '50%' },
                unit: 'kpl'
              },
            ]
          },
          {
            name: 'Peuran kulku 1. osuman jälkeen',
            field: 'kulku',
            unit: 'm',
            match: '.',
            replace: ',',
          },
          {
            name: 'Aseen merkki',
            field: 'aseen_merkki'
          },
          {
            name: 'Kaliiperi',
            field: 'kaliiperi'
          },

          {
            name: 'Patruunan tyyppi',
            type: 'select',
            field: 'patruunan_tyyppi',
            options: ['Tehdaspatruuna', 'Itseladattu']
          },
          {
            name: 'Käytetty luoti',
            field: 'kaytetty_luoti'
          },
          {
            name: 'Käytetty patruuna',
            field: 'kaytetty_patruuna'
          },
          {
            name: 'Luodin paino',
            field: 'luodin_paino',
            unit: 'g',
            match: '.',
            replace: ',',
          },
          {
            name: 'Tähtäin',
            type: 'select',
            field: 'tahtain',
            options: ['Avo', 'Kiikari']
          },
          {
            name: 'Tähtäimen merkki',
            field: 'tahtaimen_merkki'
          },
          {
            name: 'Suurennos',
            field: 'suurennos'
          }
        ]
      },
      {
        topHeader: 'Lisätiedot',
        items: [
          {
            name: 'Lisätiedot',
            field: 'lisatiedot',
            noLabel: true
          },
        ]
      },
    ],
  ]
}
