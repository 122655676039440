import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './ProductComment.scss'
import HTML from '@otavamedia/om-component-library/lib/util/HTML'
import WP from '@otavamedia/om-component-library/lib/lib/WP'
import get from 'lodash/get'
import { connect } from 'kea'
import auth from '@otavamedia/om-component-library/lib/kea/auth'
import application from '@otavamedia/om-component-library/lib/kea/application'
import { UserIcon } from '../../widgets/Icons'
import DateField from '../widgets/DateField'

const ConnectedComponent = @connect({
  props: [
    auth, [
      'userRoles',
      'userData',
    ],
    application, [
      'view',
    ],
  ],
  actions: [
    auth, [
      'setCommenting',
      'updateModal'
    ]
  ]
}) class ProductComment extends Component {
  static propTypes = {
    comment: PropTypes.object.isRequired,
    setComment: PropTypes.func,
    setCommenting: PropTypes.func,
    quote: PropTypes.func,
    isLast: PropTypes.bool,
    isReply: PropTypes.bool,
    userRoles: PropTypes.array,
    userData: PropTypes.object,
    view: PropTypes.object,
    admins: PropTypes.array.isRequired,
    showStars: PropTypes.bool,
    showTitle: PropTypes.bool,
    showImage: PropTypes.bool,
    hideActions: PropTypes.bool
  }

  state = {
    showModal: false,
    reportReason: '',
    email: ''
  }

  constructor (props) {
    super()
    this.state.thumbs = get(props, 'comment.acf.peukut', [])
  }

  UNSAFE_componentWillReceiveProps (props) {
    const thumbs = get(props, 'comment.acf.peukut', [])
    this.setState({ thumbs })
  }

  isAdminComment = () => {
    const { comment, admins } = this.props
    return admins.includes(comment.author) && comment.date > '2014-12'
  }

  async toggleLike () {
    if (this.props.userData) {
      const like = !!this.props.userData && !!this.state.thumbs && this.state.thumbs.includes(this.props.userData.id)
      let thumbs = this.state.thumbs || []
      if (!like) {
        thumbs.push(this.props.userData.id)
      } else {
        thumbs = thumbs.filter(x => (x !== this.props.userData.id))
      }
      this.setState({ thumbs })
      await WP.likeBlogComment(this.props.comment, !like)
    }
  }

  quote () {
    const { comment, quote, userData } = this.props
    if (userData) {
      if (document.querySelector('#commentBox')) {
        document.querySelector('#commentBox').scrollIntoView(false)
      } else if (document.querySelector('#CustomForm_subtitle')) {
        document.querySelector('#CustomForm_subtitle').scrollIntoView(false)
      }
      quote && quote(comment)
    }
  }

  render () {
    const { comment, isLast, isReply, showStars, showImage, showTitle, view } = this.props
    const image = get(comment, 'acf.kuva[0].url', get(comment, 'acf.kuva.url', get(view, 'featuredMedia.src', null)))
    const title = get(comment, 'acf.otsikko', '')

    return (
      <div styleName={'main' + (isReply ? ' reply' : '')} id={isLast ? 'lastComment' : ''}>
        {showImage && image
          ? <div styleName="image">
            <img src={image} alt=""/>
          </div>
          : null}
        <div styleName="header">
        </div>
        {showTitle && title ? <div styleName="title">{comment.acf.otsikko}</div> : null}
        <div styleName="text"><HTML>{comment.content.rendered}</HTML></div>
        <div styleName="footer">
          <div styleName="icon-box">
            <UserIcon />
          </div>
          <div styleName="meta">
            <span
              styleName={'author' + (this.isAdminComment() ? ' admin' : '')}>{comment.author_name}</span>
            {showStars ? <span styleName="stars">{'★'.repeat(get(comment, 'acf.tahdet') || 0)}</span> : null}
            {this.isAdminComment()
              ? <span styleName="is-admin-author">Toimituksen kommentti</span>
              : null
            }
            <div styleName="date"><DateField date={comment.date} dateOnly/> </div>
          </div>
        </div>
        {comment.reply
          ? <ConnectedComponent
            key={comment.reply.id}
            comment={comment.reply}
            isLast={true}
            admins={this.props.admins}
            setComment={comment => this.props.setComment(comment)}
            isReply={true}
            hideActions={true}
          />
          : null}
      </div>
    )
  }
}

export default ConnectedComponent
