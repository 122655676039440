import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'kea'

import HTML from '@otavamedia/om-component-library/lib/util/HTML'
import Image from '../util/Image'
import { IMAGE_SIZE } from '@otavamedia/om-component-library/lib/entities/ImageModel'
import galleryLogic from '@otavamedia/om-component-library/lib/kea/gallery'
import auth from '@otavamedia/om-component-library/lib/kea/auth'

import './GoodBad.scss'

@connect({
  actions: [
    galleryLogic, [
      'openGallery',
    ],
    auth, [
      'updateModal'
    ]
  ],
  props: [
    auth, [
      'loggedIn',
      'username'
    ]
  ]
})
class GoodBad extends Component {
  static propTypes = {
    data: PropTypes.object.isRequired,
    floated: PropTypes.bool,
    short: PropTypes.bool,
    loggedIn: PropTypes.bool,
    isMemory: PropTypes.bool,
    username: PropTypes.string,
    openGallery: PropTypes.func,
  }

  state = {
    open: false,
    okToSend: false,
    grade: 3,
    isWriting: false,
    selectedFile: null,
    comment: '',
    comments: [],
    readerGrade: 0,
    waitingApproval: false
  }

  render () {
    const { data, floated, short } = this.props
    if (!data || !data.images) {
      return null
    }

    return (
      <Fragment>
        <div styleName="good-bad-table">
          {data.title ? <div styleName="image-box">
            <div styleName="text-box">
              {/* data.grade && !short ? <div styleName="grade"><HTML>{data.grade}</HTML></div> : null */}
              <div>
                <h3><HTML>{data.title}</HTML></h3>
                {/* data.stars && !short
                  ? <div styleName="stars">{window.om_constants.abbreviation} ARVIO<span
                    styleName="star-icons">{data.stars}</span></div>
                  : <div styleName="stars">&nbsp;</div> */}
              </div>
            </div>
          </div> : null}
          <div styleName="table-content">
            <div styleName="main-content">
              {(data.goodList || data.badList || data.byNumbersList)
                ? <div>
                  {data.goodList && !short
                    ? <div styleName="good">
                      <HTML>{data.goodList}</HTML>
                    </div>
                    : null}
                  {data.badList && !short
                    ? <div styleName="bad">
                      <HTML>{data.badList}</HTML>
                    </div>
                    : null}
                  {data.byNumbersList && !short
                    ? <div styleName="by-numbers">
                      <h3>Numeroiden mukaan</h3>
                      <HTML>{data.byNumbersList}</HTML>
                    </div>
                    : null}
                </div>
                : null
              }
              {!floated && !!data.images.length && <div>
                <div styleName="gallery">
                  {data.images.map((image, index) => (
                    <div styleName="image-thumbnail" key={`image-${index}`}>
                      <Image addToGallery data={image} size={IMAGE_SIZE.THUMBNAIL} sizes="365px"/>
                    </div>
                  ))}
                </div>
              </div>
              }
            </div>
            {!data.details || short ? null : <div styleName="details-list"><HTML>{data.details}</HTML></div>}
            {data.details && data.bulletPoints && !short ? <hr/> : null}
            {!data.bulletPoints || short ? null : <div styleName="bullet-list"><HTML>{data.bulletPoints}</HTML></div>}
          </div>
        </div>
      </Fragment>
    )
  }
}

export default GoodBad
