import React, { Component } from 'react'
import WP from '@otavamedia/om-component-library/lib/lib/WP'
import PropTypes from 'prop-types'
import track from 'react-tracking'
import DateField from '../general/widgets/DateField'
import { Link } from '../general/util/Links'
import './Jahtimedia.scss'
import liitto from '../../assets/logos/liitto.png'

/**
 * Displays a list of articles fetched from jahtimedia.fi RSS feed through WP (because CORS)
 */
class Jahtimedia extends Component {
  _mounted = false
  state = {}

  static propTypes = {
    exclude: PropTypes.array, // article id's to exclude
    doneLoading: PropTypes.func,
  }

  async loadData (props) {
    const articles = await WP.getJahtimediaFeed()
    if (this._mounted) {
      this.setState({ articles: articles.slice(0, 3) })
    }
    this.props.doneLoading && this.props.doneLoading()
  }

  componentDidMount () {
    this._mounted = true
    this.loadData(this.props)
  }

  componentWillUnmount () {
    this._mounted = false
  }

  render () {
    const { articles } = this.state

    if (!articles) {
      return null
    }

    return <div styleName="jahtimedia">
      <div styleName="header">
        <img src={liitto} alt=""/>
        <div styleName="title"><Link to={{ link: 'https://metsastajaliitto.fi/' }}>Metsästäjäliitto</Link></div>
        <div styleName="right">Uutisia<br/>Jahtimediasta</div>
      </div>
      <div styleName="articles">
        {articles.map((article, key) =>
          <article key={key}>
            <div styleName="img">
              <Link to={{ link: article.link }} target="_blank" rel="noreferrer">
                <img src={article.thumbnail_url} alt=""/>
              </Link>
            </div>
            <div styleName="text">
              <Link to={{ link: article.link }} target="_blank" rel="noreferrer">
                <h4>
                  {article.title}
                </h4>
                <div styleName="meta">
                  JAHTIMEDIA &nbsp;<DateField date={article.pubDate} dateOnly/>
                </div>
              </Link>
            </div>
          </article>)}
      </div>
    </div>
  }
}

export default track({ gtmContext: ['Jahtimedia'] })(Jahtimedia)
