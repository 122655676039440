import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import WP from '@otavamedia/om-component-library/lib/lib/WP'
import { ErrorPlaceholder, withErrorBoundary } from '../general/util/ErrorBoundaries'
import HTML from '@otavamedia/om-component-library/lib/util/HTML'
import Image from '../general/util/Image'
import { IMAGE_SIZE } from '@otavamedia/om-component-library/lib/entities/ImageModel'
import './AuthorWidget.scss'
import { commentators, authors } from '../../lib/defaults'
import { Link } from '../general/util/Links'

class AuthorWidget extends Component {
  static propTypes = {
    author: PropTypes.object,
  }

  state = {}

  async componentDidMount () {
    const { author } = this.props
    const matchName = author.name.toLowerCase().replace('-', ' ')
    const authorKey = Object.keys(authors).find(name => matchName.includes(name))

    const authorId = authors[authorKey]
    if (authorId) {
      const commentatorId = commentators[authorId] || 999
      this.setState({ loading: true })
      const authorData = await WP.getAuthor(authorId)
      this.setState({
        loading: false,
        author: authorData,
        commentatorId,
      })
    }
  }


  render () {
    const { author } = this.state
    if (!author || !author.name || !author.ingress) return null
    const { name, image, ingress } = author
    const authorLink = '/toimittaja/' + name.toLowerCase().replace(' ', '-')

    return <div styleName="author">
      <div styleName="featured-image">
        <Link to={{link: authorLink}}>
        <Image addToGallery data={image} size={IMAGE_SIZE.MEDIUM_LARGE}
               sizes={'(min-width: 768px) 860px, 100vw'}/>
        </Link>
      </div>
      <div styleName="author-header">
        <Link to={{link: authorLink}}>
          <h3><HTML>{name}</HTML></h3>
          <HTML>{ingress}</HTML>
        </Link>
      </div>
    </div>

  }
}

export default withErrorBoundary(
  AuthorWidget,
  ErrorPlaceholder()
)
