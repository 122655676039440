import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { pushPageDataToGTM } from '@otavamedia/om-component-library/lib/lib/utils'
import LibreForm, { feedbackInputMutations } from '../components/libreform/LibreForm'

import './Feedback.scss'

export default class Feedback extends Component {
  static propTypes = {
    setRendered: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    form: PropTypes.string.isRequired,
  }

  shouldComponentUpdate () {
    if (this.submitted) { // prevent rendering the form again after submit
      return false
    }

    return true
  }

  componentDidMount () {
    pushPageDataToGTM({})
  }

  render () {
    const { setRendered, form } = this.props

    return <LibreForm
      form={form}
      onSubmitSuccess={response => {
        // console.log('submitted feedback')
        this.submitted = true
      }}
      onSubmitDenied={errOrResponse => {
        console.log(errOrResponse)
        alert('Jotain meni vikaan lomaketta lähettäessä.')
      }}
      afterLoad={(form, container) => {
        feedbackInputMutations(form, container)
        setRendered(true)
      }} />
  }
}
