import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import ReactPlaceholder from 'react-placeholder'
import './Sidebar.scss'
import SpecialArticleListItem from '../general/article/SpecialArticleListItem'
import SidebarLoader from '../general/util/SidebarLoader'
import { AdsForDesktop, AdsForMobile, AdSlotMob2, AdSlotRectangle1 } from '../general/ads/Ads'
import NoAdBlocker from '../widgets/NoAdBlocker'
import WP from '@otavamedia/om-component-library/lib/lib/WP'
import some from 'lodash/some'
import TotalPoll from '../general/comments/TotalPoll'

export default class SidebarArticles extends Component {
  state = {}

  componentDidMount () {
    WP.getSidebar(0).then((response) => {
      if (this.props.filtered) {
        this.setState({
          items: response.items.filter(item => !some(item.item.categories, (cat) => [15795, 17625].includes(cat.id))),
          poll: response.show_poll_in_sidebar
        })
      } else {
        this.setState({ items: response.items, poll: response.show_poll_in_sidebar })
      }
    })
      .finally(() => this.props.doneLoading && this.props.doneLoading())
  }

  UNSAFE_componentWillReceiveProps (props) {
    if (this.state.items && this.state.items.length) {
      this.props.doneLoading && this.props.doneLoading()
    }
  }

  render () {
    const { items, poll } = this.state

    return (
      <ReactPlaceholder ready={!!(items && items.length)} customPlaceholder={<SidebarLoader/>}>
        {poll
          ? <TotalPoll poll={poll}/>
          : null
        }
        {items
          ? items.map((item, idx) => (
            <Fragment key={idx}>
              {item.item ? <SpecialArticleListItem title={item.item_title || ''} article={item.item}/> : null}
              {idx === 0 && (
                <Fragment>
                  <AdsForDesktop>
                    <AdSlotRectangle1 debugName="sidebar-articles" fallBackElements={[NoAdBlocker]}/>
                  </AdsForDesktop>
                  <AdsForMobile>
                    <AdSlotMob2 fallBackElements={[NoAdBlocker]}/>
                  </AdsForMobile>
                </Fragment>)}
            </Fragment>
          ))
          : ''}
      </ReactPlaceholder>
    )
  }
}

SidebarArticles.propTypes = {
  filtered: PropTypes.bool,
  doneLoading: PropTypes.func
}
