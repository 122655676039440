import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CatchCard from '../components/general/comments/CatchCard'
import Sidebar from '../components/views/Sidebar'
import {
  AdsForDesktop,
  AdsForMobile, AdSlotMob2,
  AdSlotRectangle2,
  AdSlotSkyscraper1
} from '../components/general/ads/Ads'
import './Catch.scss'
import Newsletter from '../components/general/newsletter/Newsletter'
import NoAdBlocker from '../components/widgets/NoAdBlocker'
import track from 'react-tracking'
import WP from '@otavamedia/om-component-library/lib/lib/WP'
import { withRouter } from 'react-router-dom'
import CatchList from '../components/widgets/CatchList'
import Helmet from 'react-helmet'
import galleryLogic from '@otavamedia/om-component-library/lib/kea/gallery'
import { connect } from 'kea'

const ConnectedComponent = @track({ gtmContext: ['Catch'] }) @connect({
  props: [
    galleryLogic, ['getIndexFromImage'],
  ],
  actions: [
    galleryLogic, ['openGallery', 'addImage'],
  ],
}) class Catch extends Component {
  static propTypes = {
    match: PropTypes.object,
    doneLoading: PropTypes.func,
    getIndexFromImage: PropTypes.func,
  }

  state = {}

  constructor (props) {
    super(props)
    props.doneLoading(false)
    this.loadCard()
  }

  async loadCard () {
    const card = await WP.getComment(this.props.match.params.cardId)
    this.setState({ card })
    this.props.doneLoading(true)
  }

  componentDidMount () {
    if (this.container) {
      this.container.addEventListener('click', this.imageInterceptor)
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.props.match.params.cardId !== prevProps.match.params.cardId) {
      this.loadCard()
    }
    if (this.container) {
      this.container.addEventListener('click', this.imageInterceptor)
    }
  }

  componentWillUnmount () {
    if (this.container && this.container.removeEventListener) {
      this.container.removeEventListener('click', this.imageInterceptor)
    }
  }

  /**
   * Intercepts clicks to images in WP content,
   * and opens the Gallery component.
   */
  imageInterceptor = (ev) => {
    const { target } = ev
    const isImage = target.matches('.CatchCard_image img') || target.matches('.CatchCard_additional-image img')

    if (isImage) {
      const index = this.props.getIndexFromImage(target)

      if (index === -1) return // prevent crashing

      ev.preventDefault()
      ev.stopPropagation()

      this.actions.openGallery(null, index)
    }
  }

  render () {
    const { card } = this.state

    let type
    let name
    let title
    if (window.location.href.match(/\/kymppikerho/)) {
      type = 'fish'
      name = 'hauki kymppikerhoon'
      title = 'Haukien kymppikerho ja mittakalakerho'
    } else if (window.location.href.match(/\/peurakerho/)) {
      type = 'deer'
      name = 'peurojen kymppikerhoon'
      title = 'Peurojen kymppikerho'
    } else {
      type = 'elk'
      name = 'hirvien kymppikerhoon'
      title = 'Hirvien kymppikerho'
    }
    const kuvat = card && card.acf && card.acf.kuvat
    const kuva = kuvat && kuvat[0]

    if (!card) return null
    return <div styleName="catch">
      <Helmet>
        <title>
          {`${title} - ${card.acf.kalastajan_nimi} - ${window.om_constants.siteName}`}
        </title>

        <meta name="description" content={'Lue lukijoiden ' + name + ' päässeistä saaliista!'}/>
        <meta name="og:description" content={'Lue lukijoiden ' + name + ' päässeistä saaliista!'}/>
        <meta name="og:image" content={kuva && kuva.src}/>
      </Helmet>
      <div styleName="main" ref={n => { this.container = n }}>
        <CatchCard data={card} full type={type}/>
        <div styleName="catch-list">
          <CatchList type={type} cardsPerPage={4} withSort={false}/>
        </div>
      </div>
      <div styleName="side">
        <Sidebar popular doneLoading={() => null}>
          <AdsForDesktop>
            <Newsletter showAlways={true}/>
            <AdSlotRectangle2 debugName="index-main-sidebar" fallBackElements={[NoAdBlocker]} />
            <AdSlotSkyscraper1 fallBackElements={[NoAdBlocker]} />
          </AdsForDesktop>
          <AdsForMobile>
            <AdSlotMob2 fallBackElements={[NoAdBlocker]} />
            <Newsletter showAlways={true}/>
          </AdsForMobile>
        </Sidebar>
      </div>
    </div>
  }
}

export default withRouter(ConnectedComponent)
