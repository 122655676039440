import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import omit from 'lodash/omit'

import { connect } from 'kea'
import appLogic from '@otavamedia/om-component-library/lib/kea/application'
import auth from '@otavamedia/om-component-library/lib/kea/auth'
import { isAdministratorLikeUser } from '@otavamedia/om-component-library/lib/lib/userManagement'
import HTML from '@otavamedia/om-component-library/lib/util/HTML'
import { isDevelopment } from '@otavamedia/om-component-library/lib/util/env'
import { getType } from '@otavamedia/om-component-library/lib/lib/errors'
import './RenderedError.scss'

import Page404 from '../error/404-page'
import Page403 from '../error/403-page'
import Page401 from '../error/401-page'

export default @connect({
  actions: [
    appLogic, [
      'setRendered'
    ],
  ],
  props: [
    auth, [
      'userRoles',
    ],
  ],
})
class RenderedError extends Component {
  static propTypes = {
    error: PropTypes.instanceOf(Error),
    alternative: PropTypes.node,
    userRoles: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    setRendered: PropTypes.func,
  }

  state = {
    compact: !isDevelopment(),
  }

  toggleType = () => {
    const { compact } = this.state
    // console.log('called', !compact)

    this.setState({
      compact: !compact,
    })
  }

  renderStackTrace (error) {
    const { name, message, severity, data, stack } = error
    const { previousError } = data || {}

    return (
      <Fragment>
        <strong>Error: </strong>{name}<br />
        <strong>Message: </strong>{message}<br />
        <strong>Severity: </strong>{severity}<br />
        <strong>Data: </strong><br />
        <pre>
          <code>
            {JSON.stringify(omit(data, ['previousError']), null, 2)}
          </code>
        </pre>

        <strong>Stack: </strong>
        <HTML>{stack && stack.replace('\n', '<br />')}</HTML>

        {previousError
          ? (
            <div styleName="previousError">
              <strong>Previous error: </strong><br />
              {this.renderStackTrace(previousError)}
            </div>
          )
          : false}
      </Fragment>
    )
  }

  otherError ({ error }) {
    return (
      <div>
        <div styleName="meta">Hups!</div>
        <h1>Tapahtui virhe</h1>
        <p styleName="large-font">{error.message}</p>
      </div>
    )
  }

  render () {
    const { error, userRoles } = this.props
    const { setRendered } = this.actions
    const { compact } = this.state

    const type = getType(error.message)
    switch (type) {
    case 'FORBIDDEN': {
      return <Page403 setRendered={setRendered} />
    }

    case 'INVALID_CREDENTIALS': {
      return <Page401 setRendered={setRendered} />
    }

    case 'NOT_FOUND': {
      return <Page404 setRendered={setRendered} />
    }

    case 'RESOLVER_POST_NOT_FOUND': {
      return <Page404 setRendered={setRendered} />
    }

    default: {
      // continue
    }
    }

    return (
      <div styleName='error'>
        {compact ? this.otherError(this.props) : this.renderStackTrace(error)}
        <br/>
        {(isAdministratorLikeUser(userRoles) || !compact) && (
          <button key="errorToggle" onClick={() => this.toggleType()}>
            {compact ? 'Lisätietoja' : 'Vähemmän'}
          </button>
        )}
      </div>
    )
  }
}
