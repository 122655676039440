import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import { ErrorPlaceholder, withErrorBoundary } from '../general/util/ErrorBoundaries'
import ArchiveHighlightsLoader from '../general/util/ArchiveHighlightsLoaders'
import MagazineArchiveHighlightsLoader from './MagazineArchiveHighlightsLoader'
import MagazineHighlights from './MagazineHighlights'
import MagazineYearArchive from '../weeklyMagazine/MagazineYearArchive'
import TopRowAd from '../general/util/TopRowAd'
import { STATUS } from '@otavamedia/om-component-library/lib/lib/request-state'
import './MagazineArchiveHighlightsPage.scss'
import track from 'react-tracking'
import { pushPageDataToGTM } from '@otavamedia/om-component-library/lib/lib/utils'

class MagazineArchiveHighlightsPage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      openMagazine: props.match ? props.match.params.number : null
    }
    props.setRendered(false)
  }

  content = () => {
    const { setRendered } = this.props
    return (
      <MagazineArchiveHighlightsLoader onLoad={(state) => setRendered(true)} render={(state) => {
        const {
          magazines,
          status,
        } = state

        return (
          <Fragment>
            <div styleName="highlights-row">
              {
                status === STATUS.DONE
                  ? (
                    <MagazineHighlights
                      items={magazines.treasures.slice(0, 8)}
                      itemHeader="Arkiston aarteita"
                      coverTitle="Arkiston aarteita"
                      coverDescription={`Muistatko vielä nämä klassiset ${window.om_constants.siteNameGen} numerot?`}
                      openMagazineModal={this.openMagazineModal}
                    />
                  )
                  : <ArchiveHighlightsLoader />
              }
            </div>
            { /*
            <div styleName="highlights-row">
              {
                status === STATUS.DONE
                  ? (
                    <MagazineHighlights
                      items={magazines.retroTests.slice(0, 8)}
                      itemHeader={window.om_constants.archiveHighlights}
                      coverTitle={window.om_constants.archiveHighlights}
                      coverDescription={`${window.om_constants.siteNameGen} testejä ja -vertailuja vuosikymmenien varrelta.`}
                      openMagazineModal={this.openMagazineModal}
                    />
                  )
                  : <ArchiveHighlightsLoader />
              }
            </div>
            <div styleName="highlights-row">
              {
                status === STATUS.DONE
                  ? (
                    <MagazineHighlights
                      items={magazines.specialNumbers.slice(0, 8)}
                      itemHeader="Erikoisnumero"
                      coverTitle="Erikoisnumero"
                      coverDescription={window.om_constants.archiveHighlightsDesc}
                      openMagazineModal={this.openMagazineModal}
                    />
                  )
                  : <ArchiveHighlightsLoader />
              }
            </div>
            */ }
          </Fragment>
        )
      }}/>
    )
  }

  componentDidMount () {
    pushPageDataToGTM({})
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (this.props.match.params.number !== nextProps.match.params.number) {
      this.setState({
        openMagazine: nextProps.match.params.number
      })
    }
  }

  openMagazineModal = magazine => event => {
    if (!magazine.error) {
      this.props.setRendered(false)
      this.props.history.push(`/arkisto/aarteita/${magazine.slug}`)
    }
  }

  closeMagazine = () => {
    this.props.setRendered(false)
    this.props.history.push('/arkisto/aarteita/')
    this.props.setRendered(true)
  }

  render () {
    return (
      <Fragment>
        <Helmet>
          <title>
            {'Arkiston aarteita'}
          </title>
          {
            this.state.openMagazine
              ? <link rel="canonical" href={`/arkisto/${this.state.openMagazine}`} />
              : <link rel="canonical" href="/arkisto/aarteita/" />
          }
        </Helmet>
        <TopRowAd display={true}/>
        <div styleName="main-content-col">
          {this.content()}
        </div>
        <MagazineYearArchive number={this.state.openMagazine} closeModal={this.closeMagazine} setRendered={this.props.setRendered} />
      </Fragment>
    )
  }
}

MagazineArchiveHighlightsPage.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
  setRendered: PropTypes.func,
}

export default track({ gtmContext: ['MagazineArchiveHighlightsPage'] })(withErrorBoundary(
  MagazineArchiveHighlightsPage,
  ErrorPlaceholder(null)
))
