import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { CloseIconBlue, ListBulletIcon } from '../widgets/Icons'
import HTMLParser from '@otavamedia/om-component-library/lib/lib/html-parser'

import { ErrorPlaceholder, withErrorBoundary } from '../general/util/ErrorBoundaries'
import MagazineHighlight from '../weeklyMagazine/MagazineHighlight'
import TMWindowBreakpoints from '../general/util/TMWindowBreakpoints'

import './MagazineArchiveHighlights.scss'
import track from 'react-tracking'

class MagazineHighlights extends Component {
  static propTypes = {
    items: PropTypes.array,
    itemHeader: PropTypes.string,
    coverTitle: PropTypes.string,
    coverDescription: PropTypes.string,
    openMagazineModal: PropTypes.func,
  }

  constructor () {
    super()

    this.state = {
      expanded: false,
    }

    this.node = React.createRef()
  }

  scrollToAnchor = event => {
    if (event.target.attributes && event.target.attributes.href && event.target.attributes.href.nodeValue[0] === '#') {
      event.preventDefault()
      document.querySelector(event.target.attributes.href.nodeValue).scrollIntoView()
    }
  }

  renderCover = () => {
    const { coverTitle, coverDescription } = this.props
    return (
      <div styleName="highlight-container highlights-cover-container">
        <div styleName="highlights-cover">
          <h2>{coverTitle}</h2>
          <span>{coverDescription}</span>
        </div>
      </div>
    )
  }

  toggleExpand = (event) => {
    const expanded = !this.state.expanded

    this.setState({
      expanded,
    })

    if (!expanded) {
      const scrollY = this.node.getBoundingClientRect().top - 100
      window.scrollBy({ left: 0, top: scrollY, behavior: 'smooth', block: 'start' })
    }
  }

  renderExpandButton = () => {
    const { expanded } = this.state
    if (expanded) {
      return <button styleName="expand-button" onClick={this.toggleExpand}>
        Sulje <CloseIconBlue/>
      </button>
    } else {
      return <button styleName="expand-button" onClick={this.toggleExpand}>
        <ListBulletIcon/> Näytä lisää
      </button>
    }
  }

  render () {
    const { items, itemHeader, openMagazineModal } = this.props
    const { expanded } = this.state
    const classes = ['highlights-container', 'has-cover']
    if (expanded) classes.push('expanded')

    return (
      <Fragment>
        <div styleName={classes.join(' ')} ref={(ref) => { this.node = ref }}>
          {this.renderCover()}
          {items.map((item) => {
            return (
              <button styleName="highlight-container" key={item.slug} onClick={openMagazineModal(item)}>
                <MagazineHighlight
                  {...item}
                  htmlParser={HTMLParser.render}
                  header={itemHeader}
                />
              </button>
            )
          })}
        </div>
        <div styleName="actions">
          <TMWindowBreakpoints render={({ up }) => {
            // check that we have enough items to display "expand" button
            const minCount = up('sm') ? 3 : 2
            return items.length >= minCount ? this.renderExpandButton() : null
          }}/>
        </div>
      </Fragment>
    )
  }
}

export default track({ gtmContext: ['MagazineHighlights'] })(withErrorBoundary(
  MagazineHighlights,
  ErrorPlaceholder(null)
))
