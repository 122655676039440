import React, { Component } from 'react'
import PropTypes from 'prop-types'

import HTMLParser from '@otavamedia/om-component-library/lib/lib/html-parser'
import Modal from 'react-modal'
import { Link } from '../general/util/Links'
import { ArrowRightPrimaryIcon } from './Icons'

import './VideoOverlay.scss'

const customStyles = {
  content: {
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'transparent',
    zIndex: 100,
  }
}

class VideoOverlay extends Component {
  static propTypes = {
    activeVideo: PropTypes.object,
    closeVideo: PropTypes.func,
  }

  render () {
    const { activeVideo, closeVideo } = this.props

    return <Modal isOpen={true} onRequestClose={() => closeVideo()} style={customStyles}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div styleName="video-overlay" onClick={() => closeVideo()}>
        <div styleName="video-box">{HTMLParser.render(activeVideo.embed)}
        </div>
        <div styleName="video-button-bar">
          <div>
            {activeVideo.meta && activeVideo.meta.properties && activeVideo.meta.properties['Article link']
              ? <Link to={{ link: activeVideo.meta.properties['Article link'] }}><ArrowRightPrimaryIcon /> Lue
                artikkeli{activeVideo.meta.properties['Article title'] ? ': ' + activeVideo.meta.properties['Article title'] : null}</Link>
              : null
            }
          </div>
          <button type="button" aria-label="Sulje video"></button>
        </div>
      </div>
    </Modal>
  }
}

export default VideoOverlay
