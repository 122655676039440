import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'kea'
import appLogic from '@otavamedia/om-component-library/lib/kea/application'
import adminbarLogic from '@otavamedia/om-component-library/lib/kea/adminbar'
import WP from '@otavamedia/om-component-library/lib/lib/WP'
import { Link } from '../general/util/Links'

import './AdminBar.scss'
import magazineStore from '@otavamedia/om-component-library/lib/kea/weeklyMagazine'

@connect({
  props: [
    appLogic, [
      'view',
    ],
    magazineStore, [
      'magazine'
    ],
    adminbarLogic, [
      'flushRequested',
    ],
  ],
  actions: [
    adminbarLogic, [
      'nukeObjectCache',
    ]
  ]
})
class AdminBar extends Component {
  static propTypes = {
    view: PropTypes.object,
    userRoles: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    loggedIn: PropTypes.bool,
    flushRequested: PropTypes.bool,
    location: PropTypes.object,
    magazine: PropTypes.object,
  }

  state = {}

  getLink = (props) => {
    const adminURL = `${WP.url}/wp-admin/`
    const link = props.location.pathname

    if (!link) {
      return false
    }

    if (link.match(/\/kategoria\/[^/]*/) || link.match(/\/asiasana\/[^/]*/)) {
      const { id, taxonomy } = props.view
      return `${adminURL}term.php?taxonomy=${taxonomy}&tag_ID=${id}`
    }
    if (link.match(/\/lehti\/[^/]*\/$/) || link.match(/\/teemalehti\/[^/]*\/$/) || link.match(/\/mainoslehti\/[^/]*\/$/)) {
      if (!props.magazine.term) {
        return null
      }
      const { id, taxonomy } = props.magazine.term
      return `${adminURL}term.php?taxonomy=${taxonomy}&tag_ID=${id}`
    }

    const { id } = props.view
    return `${adminURL}post.php?post=${id}&action=edit`
  }

  UNSAFE_componentWillReceiveProps (props) {
    this.setState({ link: this.getLink(props) })
  }

  componentDidMount () {
    this.setState({ link: this.getLink(this.props) })
  }

  renderAdminLink = (link) => {
    if (!link) {
      return (
        <button styleName="button" disabled={true} title="Tämä näkymä ei ole muokattavissa.">
          Muokkaa </button>
      )
    }

    return (
      <Link to={{ link }} styleName="button">
        Muokkaa
      </Link>
    )
  }

  nukeObjectCache = (event) => {
    const { flushRequested } = this.props
    const msg = 'Oletko varma? Välimuistin tyhjentäminen hidastaa seuraavia sivulatauksia, ja hittilaskuri resetoituu muutaman minuutin takaiseen arvoon.'
    event.preventDefault()

    if (!flushRequested && confirm(msg)) {
      this.actions.nukeObjectCache()
    }
  }

  render () {
    const { flushRequested } = this.props
    const { link } = this.state

    return (
      <div styleName="bar">
        {this.renderAdminLink(link)}
        <Link to={{ link: '/asetukset' }} styleName="button">
          Asetukset
        </Link>
        <button styleName={`button nuke-object-cache ${flushRequested ? 'active' : ''}`} onClick={this.nukeObjectCache}>
          Tyhjennä WP:n välimuisti
        </button>
      </div>
    )
  }
}

export default AdminBar
