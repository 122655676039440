import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'kea'
import get from 'lodash/get'
import shuffle from 'lodash/shuffle'

import articleTypes from '../../components/general/article/ArticleTypes'
import application from '@otavamedia/om-component-library/lib/kea/application'
import ArticleListItem from '../../components/general/article/ArticleListItem'
import { IMAGE_SIZE } from '@otavamedia/om-component-library/lib/entities/ImageModel'
import { ErrorPlaceholder, withErrorBoundary } from '../../components/general/util/ErrorBoundaries'
import WP from '@otavamedia/om-component-library/lib/lib/WP'
import { getURL } from '@otavamedia/om-component-library/lib/lib/WPClient'

import './ReadMore.scss'
import track from 'react-tracking'

@track({ gtmContext: ['ReadMore'] })
@connect({
  props: [
    application, [
      'view',
    ],
  ],
})
class ReadMore extends Component {
  _mounted

  static propTypes = {
    view: PropTypes.object,
    canAccessArticle: PropTypes.func,
    isMagazineArticle: PropTypes.bool,
    excluded: PropTypes.array,
  }

  static defaultProps = {
    excluded: [],
  }

  constructor (props) {
    super()
    this.loadReadMore(props)
    this.state = {
      readMore: [],
    }
  }

  async loadReadMore (props) {
    if (!props.view.id || (props.view.id === get(this, 'props.view.id'))) {
      return
    }

    try {
      const exclude = props.excluded

      const mostPopular = await WP.getMostPopular()
      const mostPopularArticles = mostPopular.week.filter((article) => !exclude.includes(article.link))
      const popularArticlesShuffled = mostPopularArticles.length ? shuffle(mostPopularArticles) : []
      const mostPopularArticle = popularArticlesShuffled.length ? popularArticlesShuffled[0] : null
      const mostPopularArticle2 = popularArticlesShuffled.length > 1 ? popularArticlesShuffled[1] : null

      if (mostPopularArticle && mostPopularArticle.link) {
        exclude.push(mostPopularArticle.link)
      }

      const newState = {
        readMore: []
      }
      if (!get(props, 'view.categories[0].id') || get(props, 'view.categories[0].slug') === 'mainos') {
        // if this view doesn't have any categories (or category is native ad), show only popular articles
        newState.readMore = [
          (await WP.getForURL(getURL(mostPopularArticle.link))).data,
          (await WP.getForURL(getURL(mostPopularArticle2.link))).data,
        ]
      } else {
        const { data: categoryNewest } = await WP.getForContext({
          taxonomy: 'category',
          _meta: { type: 'term' },
          id: get(props, 'view.categories[0].id'),
        })

        const categoryNewestArticles = categoryNewest.filter((article) => !exclude.includes(article.link))
        const categoryNewestArticle = categoryNewestArticles.length ? shuffle(categoryNewestArticles)[0] : null

        newState.readMore = [
          categoryNewestArticle,
          (await WP.getForURL(getURL(mostPopularArticle.link))).data,
        ]
      }
      if (this.state && this._mounted) {
        this.setState(newState)
      } else {
        this.state = newState
      }
    } catch (e) {
      console.log('Failed loading read more', e)
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    this.loadReadMore(nextProps)
  }

  componentDidMount () {
    this._mounted = true
  }

  componentWillUnmount () {
    this._mounted = false
  }

  render () {
    const { view, isMagazineArticle } = this.props
    const { readMore } = this.state
    const {
      articleType,
    } = view

    const extraContainerStyle = articleType === articleTypes.SPECIAL || isMagazineArticle ? 'centered' : ''

    return <Fragment>
      {readMore && readMore.length
        ? <div styleName={`highlights ${extraContainerStyle}`}>
          <h2>Lisää {window.om_constants.aboutSite}</h2>
          <Fragment>
            <div styleName="full-row">
              <div styleName="half-col">
                <ArticleListItem article={readMore[0]} size="md" relatedArticleListLimit={0}
                  sizes="(max-width: 768px) 100vw, 400px" imageSize={IMAGE_SIZE.MEDIUM_LARGE}/>
              </div>
              <div styleName="half-col">
                <ArticleListItem article={readMore[1]} size="md" relatedArticleListLimit={0}
                  sizes="(max-width: 768px) 100vw, 400px" imageSize={IMAGE_SIZE.MEDIUM_LARGE}/>
              </div>
            </div>
          </Fragment>
        </div>
        : null }
    </Fragment>
  }
}

export default withErrorBoundary(
  ReadMore,
  ErrorPlaceholder()
)
