import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import Helmet from 'react-helmet'
import PropTypes from 'prop-types'
import chunk from 'lodash/chunk'
import solrSearch from '../../../lib/SolrSearch'
import { SearchIcon, CaretDown } from '../../widgets/Icons'
import ArticleMetaData from '../article/ArticleMetadata'
import TopRowAd from '../util/TopRowAd'
import NewsfeedLoader from '../util/NewsfeedLoader'
import { Link } from '../util/Links'
import './Search.scss'
import { connect } from 'kea'
import application from '@otavamedia/om-component-library/lib/kea/application'
import querystring from 'querystring'
import track from 'react-tracking'
import HTML from '@otavamedia/om-component-library/lib/util/HTML'
import { pushPageDataToGTM } from '@otavamedia/om-component-library/lib/lib/utils'

/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/no-onchange */

@connect({
  actions: [
    application, [
      'setRendered'
    ],
  ],
})
class Search extends Component {
  state = {
    sort: 'BEST_MATCH',
    searchString: '',
    page: 1,
    results: [],
    filter: ''
  }

  years = []
  node = null
  RESULTS_PER_PAGE = 10

  constructor (props) {
    super()
    this.node = React.createRef()
    this.searchInput = React.createRef()
    let i = new Date().getFullYear()
    this.state.endYear = i
    this.years = Array(i - 1952).fill(0).map(() => i--)
    this.state.startYear = i + 1
    this.state.loading = true
    this.state = {
      ...this.state,
      page: 1,
      ...this.getUrlParams(props.location.search)
    }
    if (this.state.q && !this.state.searchString) {
      this.state.searchString = this.state.q
    }
    if (this.state.searchString) {
      this.doSearch(true)
    } else {
      this.state.loading = false
    }
  }

  componentDidMount () {
    pushPageDataToGTM({})
    this.searchInput.focus()
    if (!this.state.loading) {
      this.actions.setRendered(true)
    }
  }

  componentWillUnmount () {
    this.actions.setRendered(false)
  }

  UNSAFE_componentWillReceiveProps (props) {
    const newState = { page: 1, ...this.getUrlParams(props.location.search) }
    if (newState.searchString !== this.state.searchString) {
      this.searchInput.focus()
    }

    let updateCounts = false
    if (newState.searchString !== this.state.searchString || newState.startYear !== this.state.startYear ||
      newState.endYear !== this.state.endYear) {
      updateCounts = true
    } else if (newState.category !== this.state.category) {
      newState.results = []
      newState.page = 1
    }
    this.setState(newState,
      () => this.state.searchString && this.doSearch(updateCounts))
  }

  getUrlParams (url) {
    const arr = url.slice(1).split(/[&=]/) // remove the "?", "&" and "="
    const params = {}
    chunk(arr, 2).forEach((item) => {
      params[item[0]] = decodeURIComponent(item[1])
    })

    return params
  };

  handleFormChange (doSearch, e) {
    const newState = { [e.target.id]: e.target.value }
    if (['searchString', 'startYear', 'endYear'].includes(e.target.id)) {
      newState.page = 1
    }
    if (doSearch) {
      newState.results = []
      this.initSearch({ ...this.state, ...newState })
    } else {
      this.setState(newState)
    }
  }

  selectCategory (e) {
    const newState = {
      ...this.state,
      page: 1,
      category: e.target.value === this.state.category ? undefined : e.target.value,
      results: []
    }
    this.initSearch(newState)
  }

  searchOnEnter (e) {
    if (e.key === 'Enter') {
      this.searchOnButtonClick()
    }
  }

  searchOnButtonClick () {
    const state = { ...this.state }
    state.searchString = this.searchInput.value
    state.category = undefined
    this.initSearch(state)
  }

  generateUrlParams (state) {
    return encodeURI(`?filter=${state.filter}&sort=${state.sort}&endYear=${state.endYear}&startYear=${state.startYear}&category=${state.category || ''}&searchString=${state.searchString}&page=${state.page}`)
  }

  nextPage (e) {
    const newPage = (1 + parseInt(this.state.page)) || 1
    const qs = querystring.parse(location.search.substring(1))
    qs.page = newPage
    const params = querystring.stringify(qs)

    this.setState({ page: newPage, loading: true })
    this.props.history.replace('/haku?' + params)
    e.preventDefault()
  }

  initSearch (newState) {
    newState.page = 1
    const searchParams = this.generateUrlParams(newState)
    newState.results = []
    newState.loading = true
    if (this.props.location.search !== searchParams) {
      this.props.history.push('/haku/' + searchParams)
    } else {
      this.setState(newState, () => this.doSearch(true))
    }
  }

  /**
   * Unless we are just sorting or selecting a category, we have to update the category counts,
   * so we run the search twice (first without category to get the numbers for each category,
   * and then the actual results for the selected one)
   *
   * @param newSearch
   * @return {Promise<void>}
   */
  async doSearch (newSearch = false) {
    const otherFilters = {
      publishedAfter: this.state.startYear + '-01-01T00:00:00.000+02:00',
      publishedBefore: this.state.endYear + '-12-31T23:59:59.999+02:00',
      include: 'ALL',
    }
    let page
    let pageSize
    const newState = this.state

    if (newSearch) {
      page = 1
      pageSize = this.RESULTS_PER_PAGE
    } else {
      page = this.state.page
      pageSize = this.RESULTS_PER_PAGE
    }
    const category = this.state.category
    const currentResults = parseInt(this.state.page) === 1 ? [] : this.state.results
    if (!currentResults.length && page > 1) {
      pageSize = page * this.RESULTS_PER_PAGE
      page = 1
    }
    !this.state.loading && this.setState({ loading: true })
    const searchResults = await WP.elasticSearch(
      this.state.searchString,
      this.state.sort ? this.state.sort : 'BEST_MATCH',
      otherFilters,
      page,
      pageSize,
      category,
      this.state.filter === 'paid',
      'Metsästys ja Kalastus')
    newState.results = currentResults.concat(searchResults.hits)
    newState.resultCount = searchResults.totalHits
    newState.loading = false
    /*
    if (!this.state.category) {
      newState.categories = searchResults.categories
      newState.category = searchResults.categories.includes(this.state.category) ? this.state.category : ''
    }
     */
    this.setState(newState)
  }

  compare (a, b) {
    const aNum = !(a.title || '').search(/^\d+$/) ? parseInt(a.title) : 10000
    const bNum = !(b.title || '').search(/^\d+$/) ? parseInt(b.title) : 10000
    return (aNum > bNum)
      ? 1
      : ((bNum > aNum)
        ? -1
        : (a.title > b.title ? 1 : (a.title < b.title ? -1 : 0)))
  }

  replace (url) {
    if (url.includes('/digilehti') || url.includes('/nakoislehti')) {
      return url
    }
    return url.replace(/https:\/\/wp\.metsastysjakalastus\.fi/, '').replace(/https:\/\/wp\.metsastysjakalastus\.asteaws\.dev/, '')
  }

  componentDidUpdate () {
    if (!this.state.loading) {
      this.actions.setRendered(true)
    }
  }

  renderResults () {
    const results = this.state.results
    const isPageNumber = (string) => !string.search(/^\d+$/)

    const render = results && results.map((result, idx) => {
      const url = result.url
        ? this.replace(result.url)
        : result.url

      const resultData = result.docs ? result.docs[0] : result
      resultData.postTitle = (isPageNumber(resultData.title) && 'Artikkeli lehden sivulla ' + resultData.title) ||
        resultData.title
      resultData.date = resultData.published
      resultData.icon = resultData.accessLevel > 1
      resultData.cats = resultData.mainCategory ? [{ name: resultData.mainCategory }] : (resultData.customValues.issue_name ? [{ name: resultData.customValues.issue_name }] : null)

      return <article key={idx} styleName={['result-item', result.postType === 'issue_page' ? 'issue' : (result.postType === 'om_ad_article' ? 'ad' : null)].join(' ')}>
        <div styleName="ad-title"><span>Mainos</span></div>
        <div>
          {resultData.thumbnailUrl && (result.postType !== 'om_ad_article') && <Link to={{ link: url }}>
            <img src={resultData.thumbnailUrl} alt={resultData.postTitle}/>
          </Link>}
        </div>
        <div>
          <header styleName="meta-row">
            {result.postType === 'om_ad_article' ?
              <span styleName="ad-owner">{resultData.taxonomies && resultData.taxonomies.om_ad_magazine && resultData.taxonomies.om_ad_magazine[0]}</span>
              :<ArticleMetaData categories={resultData.cats} date={resultData.date}
                                displaySubscriberIcon={resultData.icon}/>}
          </header>
          <Link to={{ link: url }}>
            <h3><HTML>{resultData.postTitle}</HTML></h3>
          </Link>
          <Link to={{ link: url }}>
            {resultData.contents[0] && <span><HTML>{resultData.contents[0]}</HTML></span>}
          </Link>
        </div>
      </article>
    })

    return <Fragment>{render}{this.state.loading ? <NewsfeedLoader/> : null}</Fragment>
  }

  render () {
    const { filter, startYear, endYear, loading, searchString, sort, category, categoryStats, resultCount, page } = this.state
    const nextState = { ...this.state }
    nextState.page++
    const nextPageUrl = '/haku/' + this.generateUrlParams(nextState)
    return <div styleName="main">
      <Helmet>
        <title>
          {`Haku: ${searchString} - ${window.om_constants.siteNameGen}`}
        </title>
      </Helmet>
      <TopRowAd display={true}/>
      <div styleName="search">
        <div styleName="search-container">
          <div styleName="search-row">
            <h1>Hae {window.om_constants.aboutSite}</h1>
            <div>
              <input
                ref={(ref) => { this.searchInput = ref }}
                placeholder="Hakusanat"
                defaultValue={searchString}
                id="searchString"
                onKeyPress={this.searchOnEnter.bind(this)}
              />
              <button styleName="big-red-button" onClick={() => this.searchOnButtonClick()}><SearchIcon/></button>
            </div>
          </div>
        </div>
      </div>
      {(!!searchString || loading) && <Fragment>
        <div styleName="result-container">
          <div styleName="result-row">
            <div styleName="result-col result-filters">
              <div>
                <label htmlFor="sort">Järjestä</label>
                <div styleName="select-div">
                  <select id="sort" onChange={this.handleFormChange.bind(this, true)} value={sort}>
                    <option value="BEST_MATCH">Paras osuma</option>
                    <option value="DATE_DESC">Uusimmat</option>
                    <option value="DATE_ASC">Vanhimmat</option>
                  </select>
                  <div styleName="select-caret"><CaretDown color="blue"/></div>
                </div>
              </div>
              <div>
                <label htmlFor="category">Kategoriat</label>
                <div styleName="select-div">
                  <select id="category" onChange={this.selectCategory.bind(this)} value={category}>
                    <option key={-1} value="">Kaikki</option>
                    {chunk(categoryStats, 2).map((category, idx) =>
                      <option key={idx} value={category[0]}>{category[0]} ({category[1]})</option>)}
                  </select>
                  <div styleName="select-caret"><CaretDown color="blue"/></div>
                </div>
              </div>
              <div>
                <label htmlFor="startYear">Aikarajaus</label>
                <div styleName="date-range">
                  <div styleName="select-div">
                    <label htmlFor="startYear" className="screen-reader-text">Aloitusvuosi</label>
                    <select id="startYear" value={startYear} // eslint-disable-line jsx-a11y/no-onchange
                      onChange={this.handleFormChange.bind(this, true)}>
                      {this.years.filter(x => x <= endYear).map((year) => (
                        <option key={year} value={year}>{year}</option>))}</select>
                    <div styleName="select-caret"><CaretDown color="blue"/>
                    </div>
                  </div>
                  <div styleName="select-div">
                    <label htmlFor="endYear" className="screen-reader-text">Loppuvuosi</label>
                    <select id="endYear" value={endYear} // eslint-disable-line jsx-a11y/no-onchange
                      onChange={this.handleFormChange.bind(this, true)}>
                      {this.years.filter(x => x >= startYear).map((year) => (
                        <option key={year} value={year}>{year}</option>))}</select>
                    <div styleName="select-caret"><CaretDown color="blue"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div styleName="result-row">
            <div styleName="result-col">
              <div>
                <div styleName="results-header">
                  <h2>Haun tulokset ({resultCount} kpl)</h2>
                  <div styleName="filter-buttons">
                    <div>Näytä:</div>
                    <button styleName={'toggle-button ' + (filter === '' ? 'selected' : '')}
                      onClick={() => this.initSearch({ ...this.state, filter: '' })}>Kaikki
                    </button>
                    <button styleName={'toggle-button ' + (filter === 'paid' ? 'selected' : '')}
                      onClick={() => this.initSearch({ ...this.state, filter: 'paid' })}>Vain maksulliset artikkelit
                    </button>
                  </div>
                </div>
                <div styleName="result-list" ref={(ref) => {
                  this.node = ref
                }}>
                  {this.renderResults()}
                </div>
                {Math.ceil(resultCount / this.RESULTS_PER_PAGE) > page
                  ? (
                    <div styleName="load-more-button">
                      <a onClick={(e) => this.nextPage(e)}
                        href={nextPageUrl}>Lisää tuloksia</a>
                    </div>)
                  : null}
              </div>
            </div>
          </div>
        </div>
      </Fragment>}
    </div>
  }
}

Search.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
}

export default track({ gtmContext: ['Search'] })(withRouter(Search))
