import styled from 'styled-components'
import MagazineCardCarousel from '@otavamedia/om-component-library/lib/MagazineCardCarousel'

const StyledMagazineCardCarousel = styled(MagazineCardCarousel)`
  .Icons_caret-icon {
    width: 1.5rem;
    height: 1.5rem;
  }
  
  .items-carousel {
    .nav-button-prev {
      flex: 0 0 40px;
      background-color: rgba(255, 255, 255, 0.8);
      margin-right: 0;
    }
    .nav-button-next {
      flex: 0 0 40px;
      background-color: rgba(255, 255, 255, 0.8);
      margin-left: 0;
    }
    
    @media (min-width: 1200px) {
      margin-left: rem(38px);
    }
  }
  
  .magazine-card {
    width: 100px;
    height: 165px;
    margin-right: 0;
    .title {
      text-align: center;
    }
    .name {
      font-size: 20px;
      @media (min-width: 991px) {
        font-size: 24px;
      }
    }
    
    @media (min-width: 1000px) {
      width: 225px;
      height: 330px;
    }

    .image {
      display: flex;
      justify-content: center;

      img {
        width: 215px;
        max-height: 280px;
        border: 1px solid #aaa;
      }
    }
  }
  
  &.highlight-selected {
    .slick-slide > div > div {
      opacity: 0.5;
      
      &.selected {
        opacity: 1;
      }
    }
  }

  .slick-slider {
    margin-left: -2.75rem;
  }
  
  .slick-slide {
    margin-right: 1.5rem;
    cursor: pointer;
  }
`

export default StyledMagazineCardCarousel
