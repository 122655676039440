import React from 'react'
import PropTypes from 'prop-types'
import HTML from '@otavamedia/om-component-library/lib/util/HTML'

import './CrossLinkBottomNotification.scss'

const CrossLinkBottomNotification = ({ text, title, href, linkText }) => {
  return (<div styleName="main">
    <h3>{title}</h3>
    <HTML>{text}</HTML>
    {href &&
      (<a href={href}>{linkText}</a>)
    }
  </div>)
}

CrossLinkBottomNotification.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  href: PropTypes.string,
  linkText: PropTypes.string,
}

export default CrossLinkBottomNotification
