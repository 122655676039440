import React, { Component, Fragment } from 'react'
import mapboxgl from 'mapbox-gl'
import PropTypes from 'prop-types'

mapboxgl.accessToken = 'pk.eyJ1IjoibWlyb3dpa2dyZW4iLCJhIjoiY2trY2N3ZXVvMGR1cjMxcXJzajE3bnFkeSJ9.HzSeXnVYbkQgQttjGNOPRA'

export default class Map extends Component {
  static propTypes = {
    lng: PropTypes.number,
    lat: PropTypes.number,
    zoom: PropTypes.number,
    updateState: PropTypes.func,
  }

  componentDidMount () {
    this.map = new mapboxgl.Map({
      container: this.mapContainer,
      style: 'mapbox://styles/mapbox/light-v10',
      center: [26, 63],
      zoom: 3.4
    })

    this.map.on('move', () => {
      this.props.updateState(this.map.getCenter().lng.toFixed(4) + '°E ' + this.map.getCenter().lat.toFixed(4) + '°N')
    })
  }

  setCurrentLocation () {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.map.jumpTo({
          center: [position.coords.longitude, position.coords.latitude],
          zoom: this.map.getZoom() < 8 ? 8 : this.map.getZoom(),
        })
      })
    }
  }

  render () {
    return <Fragment>
      <div ref={el => {
        this.mapContainer = el
      }} className='mapContainer'/>
      <div className="pointer">{'\uF276'}</div>
      <button className="location-button" onClick={() => this.setCurrentLocation()}>Nykyinen sijainti</button>
    </Fragment>
  }
}
