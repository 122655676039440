import React, { Fragment, Component } from 'react'
import PropTypes from 'prop-types'
import HTMLParser from '@otavamedia/om-component-library/lib/lib/html-parser'

import { connect } from 'kea'
import galleryLogic from '@otavamedia/om-component-library/lib/kea/gallery'
import ProductCard from '../../general/article/ProductCard'
import { Link } from '../../general/util/Links'
import { AdSlotArticleBody, AdSlotArticleBodyMob1 } from '../../general/ads/Ads'
import ImageCollage from '../../general/article/ImageCollage'
import LibreForm from '../../libreform/LibreForm'
import Image from '../../general/util/Image'
import MagazineMenu from '../../navigation/MagazineMenu'
import RandomArticle from '../../widgets/RandomArticle'
import ProductMemory from '../../general/article/ProductMemory'
import Question from '../../general/article/Question'

export default @connect({
  actions: [
    galleryLogic, [
      'addImage',
    ],
  ],
})
class ArticleHTML extends Component {
  static propTypes = {
    children: PropTypes.node,
    options: PropTypes.object,
  }

  render () {
    const { children, options = {} } = this.props
    const { addImage } = this.actions

    options.gallery = {
      addImage,
    }

    if (typeof children !== 'string') {
      return null
    }

    return <Fragment>{HTMLParser.render(children, {
      Link,
      AdSlotArticleBody,
      AdSlotArticleBodyMob1,
      ProductCard,
      ImageCollage,
      LibreForm,
      Image,
      MagazineMenu,
      RandomArticle,
      ProductMemory,
      Question,
      ...options
    })}</Fragment>
  }
}
