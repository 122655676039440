import React, { Fragment } from 'react'
import { TextBlock, RectShape } from 'react-placeholder/lib/placeholders'
import './HighlightsLoader.scss'
import Title from './Title'

const HighlightsLoader = () => (
  <Fragment>
    <div styleName="large-col">
      <div styleName="loader">
        <RectShape style={{ width: '100%', height: 477 }}/>
        <TextBlock rows={4} color='grey' />
      </div>
    </div>
    <div styleName="small-col">
      <div styleName="loader">
        <RectShape style={{ width: '100%', height: 150 }}/>
        <TextBlock rows={3} color='grey' />
      </div>
      <div styleName="loader">
        <RectShape style={{ width: '100%', height: 150 }}/>
        <TextBlock rows={3} color='grey' />
      </div>
    </div>
    <div styleName="slow-highlight-row">
      <div styleName="full-width">
        <Title text="Toimituksen valinnat" />
      </div>
      <div styleName="slow-highlight-col">
        <div styleName="slow-highlight">
          <RectShape style={{ width: '100%', height: 150 }}/>
          <TextBlock rows={3} color='grey' />
        </div>
      </div>
      <div styleName="slow-highlight-col">
        <div styleName="slow-highlight">
          <RectShape style={{ width: '100%', height: 150 }}/>
          <TextBlock rows={3} color='grey' />
        </div>
      </div>
      <div styleName="slow-highlight-col">
        <div styleName="slow-highlight">
          <RectShape style={{ width: '100%', height: 150 }}/>
          <TextBlock rows={3} color='grey' />
        </div>
      </div>
    </div>
  </Fragment>
)

export default HighlightsLoader
