import React, { Component } from 'react'
import { ErrorPlaceholder, withErrorBoundary } from '../../components/general/util/ErrorBoundaries'
import WP from '@otavamedia/om-component-library/lib/lib/WP'
import shuffle from 'lodash/shuffle'
import './RandomArticle.scss'
import track from 'react-tracking'
import { Link } from '../general/util/Links'
import PropTypes from 'prop-types'

@track({ gtmContext: ['RandomArticle'] })
  /**
   * Displays a block with a link to a random archive magazine (näköislehti) page.
   * This component can be inserted inline into the article with a shortcode (see html-parser.js)
   * or displayed as a block.
   */
class RandomArticle extends Component {
  static propTypes = {
    notWide: PropTypes.bool,
  }

  _mounted

  constructor (props) {
    super(props)
    this.loadRandomArticle(props)
    this.state = {
      page: null,
    }
  }

  async loadRandomArticle () {
    try {
      const year = 2003 + Math.floor(Math.random() * ((new Date()).getFullYear() - 2003))
      const numbersPerYear = year < 2014 ? 13 : 12
      const number = Math.floor(Math.random() * numbersPerYear) + 1
      const magazine = await WP.getArchivedMagazine((number < 10 ? '0' : '') + number + '-' + year)
      const articlePages = magazine.pages.filter((x) => !parseInt(x.title))
      let page
      if (articlePages.length) {
        page = shuffle(articlePages)[0]
      }
      if (this.state && this._mounted) {
        this.setState({ page })
      }
    } catch (e) {
      console.log('Failed loading random article', e)
    }
  }

  UNSAFE_componentWillReceiveProps () {
    this.loadRandomArticle()
  }

  componentDidMount () {
    this._mounted = true
  }

  componentWillUnmount () {
    this._mounted = false
  }

  render () {
    const { page } = this.state
    const { notWide } = this.props
    if (!page) {
      return null
    }

    return <div styleName={notWide ? 'random not-wide' : 'random'}>
      <div styleName="text-content">
        <div styleName="pad">
          <h2>Avaa satunnainen artikkeli</h2>
        </div>
        <div styleName="pad-button">
          <Link to={{ link: page.link }} styleName="button" onClick={() => this.loadRandomArticle()}>Kokeile onneasi</Link>
        </div>
      </div>
    </div>
  }
}

export default withErrorBoundary(
  RandomArticle,
  ErrorPlaceholder()
)
