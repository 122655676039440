import { Component } from 'react'
import PropTypes from 'prop-types'

import ArchiveMagazineModel from '@otavamedia/om-component-library/lib/entities/ArchiveMagazineModel'
import WP from '@otavamedia/om-component-library/lib/lib/WP'
import { STATUS } from '@otavamedia/om-component-library/lib/lib/request-state'
import { getRandomInt } from '@otavamedia/om-component-library/lib/lib/utils'
import { ErrorPlaceholder, withErrorBoundary } from '../general/util/ErrorBoundaries'
import { SPECIAL_NUMBERS } from '../../lib/defaults'

class MagazineArchiveHighlightsLoader extends Component {
  static propTypes = {
    render: PropTypes.func,
    onLoad: PropTypes.func,
  }

  _mounted = false
  asyncSetState (...args) {
    // avoid setting state after unmount in case async operations attempts to do so
    if (this._mounted) this.setState(...args)
  }

  constructor () {
    super()

    this.state = {
      magazines: {
        treasures: [],
        retroTests: [],
        mostCommented: [],
      },
      status: STATUS.NOT_REQUESTED,
      specialNumberIndex: -1,
    }
  }

  modelify = ({ issue, data }) => !issue
    ? {
      error: true,
      name: data.issueNumber
    }
    : new ArchiveMagazineModel({ ...issue, ...data })

  async componentDidMount () {
    const { onLoad } = this.props
    this._mounted = true

    try {
      this.setState({
        status: STATUS.REQUESTED,
      })
      const { data } = await WP.get('/wp-json/om/v1/archive/treasures', { cache: false })
      const { treasures: treasuresData, retroTests: retroTestsData } = data

      const specialNumberIndex = getRandomInt(SPECIAL_NUMBERS.length)
      const treasures = treasuresData.map(this.modelify)
      const retroTests = retroTestsData.map(this.modelify)
      /*
      const specialNumbers = shuffle(SPECIAL_NUMBERS.map((special) => new ArchiveMagazineModel(special)).map((special) => {
        return {
          ...special,
          link: special.slug ? `/arkisto/${special.slug.split('-')[1]}/${special.slug}` : '/arkisto/',
        }
      }))
       */

      this.asyncSetState({
        magazines: {
          treasures,
          retroTests,
          specialNumbers: [], // specialNumbers,
        },
        status: STATUS.DONE,
        specialNumberIndex,
      }, () => onLoad(this.state))
    } catch (e) {
      this.asyncSetState({
        magazines: e,
        status: STATUS.ERROR,
      })
    }
  }

  componentWillUnmount () {
    this._mounted = false
  }

  render () {
    return this.props.render(this.state)
  }
}

export default withErrorBoundary(
  MagazineArchiveHighlightsLoader,
  ErrorPlaceholder()
)
