import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './ReportError.scss'

class ReportError extends Component {

  render () {
    const { article } = this.props
    if (!window.location.origin) {
      window.location.origin = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '')
    }
    const articleLinkPlain = window.location.origin + article.link + '?' + (article.shareToken ? 'shared=' + article.shareToken : '')
    const articleLink = encodeURIComponent(articleLinkPlain)
    const title = encodeURIComponent(article.title)

    return (
      <div styleName="report-error">
        <a href={'mailto:asiavirheet@otava.fi?subject=MK:%20Ilmoita asiavirheestä&body=Url: ' + articleLink + '\nOtsikko:' + title}
          target="_blank" rel="noopener noreferrer">Ilmoita asiavirheestä</a>
      </div>
    )
  }
}

ReportError.propTypes = {
  article: PropTypes.object.isRequired,
}

export default ReportError
