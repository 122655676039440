import 'regenerator-runtime/runtime'
import React from 'react'
import ReactDOM from 'react-dom'
import { Route } from 'react-router-dom'
import { ScrollContext } from 'react-router-scroll-4'

import { Provider } from 'react-redux'
import { ConnectedRouter } from 'react-router-redux'
import { store, history } from './store'
// import OfflinePluginRuntime from 'offline-plugin/runtime'
import 'kea-saga'
import './config'

// Import CSS before App or any CSS loaded by App or children of it will appear
// earlier, possibly breaking everything.
import 'normalize.css'
import './bootstrap-grid.scss'
import './styles/util.scss'
import './styles/wp-globals.scss'
import './styles/loader-globals.scss'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import './index.scss'

import { isDevelopment } from '@otavamedia/om-component-library/lib/util/env'
import { setupSentry } from '@otavamedia/om-component-library/lib/lib/sentry'
import App from './containers/App'
import { logException } from '@otavamedia/om-component-library/lib/lib/errors'

window.startTime = Date.now()

/**
 * Initialize prerender globals. RUNNING_HEADLESS is set once and read with isHeadless() throughout the codebase,
 * the headless parameter tends to disappear from the URL after a redirect, so it's not viable to check the URL in isHeadless().
 */
window.RUNNING_HEADLESS = window.location.search.includes('headless')
window.READY_TO_RENDER = false

setupSentry()

const isDev = isDevelopment()

// Saves application to disk and serves it from the disk instead of loading
// the application from the internet. Should update automatically.
/*
async function registerServerWorker () { // eslint-disable-line no-unused-vars
  OfflinePluginRuntime.install({
    onInstalled () {
      console.log('Application is ready to be used offline')
    },
    onUpdateReady () {
      console.log('Service worker update ready, applying')
      OfflinePluginRuntime.applyUpdate()
    },
    onUpdating () {
      console.log('Starting service worker update')
      render({ updating: true })
    },
    onUpdated () {
      console.log('Service worker updated, reloading the window')
      // Allow users to see loading message
      setTimeout(() => {
        window.location.reload()
      }, 1000)
    },
    onUpdateFailed () {
      console.log('Service worker update failed')
    }
  })
}
*/

function render (props) {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <ScrollContext shouldUpdateScroll={(prevRouterProps, { location, history }) => history.action !== 'REPLACE'}>
          <Route component={App} />
        </ScrollContext>
      </ConnectedRouter>
    </Provider>,
    document.getElementById('app')
  )

  // Move the styled-components <style> block to beginning of the <head> to ensure existing css comes after it.
  // This allows easy overriding of components when needed.
  const styledTag = document.querySelector('style[data-styled-components]')
  styledTag.parentNode.insertBefore(styledTag, styledTag.parentNode.firstChild)
}

async function main () {
  try {
    if (isDev) {
      console.log('Running development build')
      const a11y = (await import('react-a11y')).default
      a11y(React, ReactDOM, {
        rules: {
          'img-uses-alt': 'warn',
        }
      })
    } else {
      console.log('Running production build')
      /*
      if ('serviceWorker' in navigator) {
        window.addEventListener('load', () => {
          navigator.serviceWorker.register('/sw.js').then(registration => {
            const sw = registration.installing || registration.waiting
            if (!sw || sw.onstatechange) return
            let stateChangeHandler
            if (registration.active) {
              onUpdateStateChange()
              stateChangeHandler = onUpdateStateChange
            } else {
              onInstallStateChange()
              stateChangeHandler = onInstallStateChange
            }
            sw.onstatechange = stateChangeHandler

            function onUpdateStateChange () {
              switch (sw.state) {
              case 'redundant': {
                console.log('redundant')
                console.log(sw)
                break
              }
              case 'installing': {
                console.log('installing')
                break
              }
              case 'installed': {
                console.log('installed')
                registration.waiting.postMessage({
                  action: 'skipWaiting'
                })
                break
              }
              case 'activated': {
                console.log('activated')
                break
              }
              }
            }

            function onInstallStateChange () {
              switch (sw.state) {
              case 'redundant': {
                // Failed to install, ignore
                console.log('redundant')
                console.log(sw)
                sw.onstatechange = null
                break
              }
              case 'installing': {
                // Installing, ignore
                console.log('installing')
                console.log(sw)
                break
              }
              case 'installed': {
                console.log('installed')
                // Installed, wait activation
                break
              }
              case 'activated': {
                console.log('activated')
                sw.onstatechange = null
                break
              }
              }
            }
            return registration
          }).catch(registrationError => {
            console.log('SW registration failed: ', registrationError)
          })
        })
      }
      */
      // await registerServerWorker()
    }
  } catch (e) {
    logException(e)
  }

  if (document.readyState === 'interactive' || document.readyState === 'complete') {
    render({ updating: false })
  } else {
    document.addEventListener("DOMContentLoaded", () => {
      render({ updating: false })
    })
  }
}

main()
