import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { connect } from 'kea'
import Image from '../components/general/util/Image'
import application from '@otavamedia/om-component-library/lib/kea/application'
import auth from '@otavamedia/om-component-library/lib/kea/auth'
import HTML from '@otavamedia/om-component-library/lib/util/HTML'
import { Link } from '../components/general/util/Links'
import BestProductPageLoader from '../components/general/util/BestProductPageLoader'
import BestProductLoader from '../components/general/util/BestProductLoader'
import { ErrorPlaceholder, withErrorBoundary } from '../components/general/util/ErrorBoundaries'
import TopRowAd from '../components/general/util/TopRowAd'
import TestCard from '../components/widgets/TestCard'
import WP from '@otavamedia/om-component-library/lib/lib/WP'
import RenderedError from '../components/general/util/RenderedError'
import { STATUS } from '@otavamedia/om-component-library/lib/lib/request-state'
import { pushPageDataToGTM, stripHTML } from '@otavamedia/om-component-library/lib/lib/utils'
import { ShowAll } from '../components/widgets/ShowAll'

import './BestProductCollection.scss'
import { IMAGE_SIZE } from '@otavamedia/om-component-library/lib/entities/ImageModel'
import track from 'react-tracking'

const getPageFromUrl = (link) => {
  const regex = /^(.*\/)([0-9]+)(\/)$/
  return link && parseInt(link.replace(regex, '$2'))
}

@connect({
  actions: [
    application, [
      'setViewData',
    ]
  ],
  props: [
    application, [
      'view',
    ],
    auth, [
      'premiumUser',
    ],
  ]
})
class TestBank extends Component {
  static propTypes = {
    view: PropTypes.object,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    setRendered: PropTypes.func.isRequired,
    premiumUser: PropTypes.bool,
  }

  state = {
    status: STATUS.NOT_REQUESTED,
    page: getPageFromUrl(window.location.href) || 1,
    tag: 'testipankki',
    categories: [
      {
        title: 'Suomen parhaat tuotteet',
        categories: ['testivoittaja'],
        noTag: true,
        catIds: [],
        items: [],
        link: '/testivoittajat/',
        isBest: true
      },
      {
        title: 'Autot ja moottoripyörät',
        categories: ['autot', 'moottoripyorat', 'polkupyorat', 'renkaat', 'tarvikkeet', 'koeajot'],
        categoryNames: ['Autot', 'Moottoripyörät', 'Polkupyörät', 'Renkaat', 'Lisävarusteet'],
        catIds: [],
        items: [],
        link: '/testit-autot/',
      },
      {
        title: 'Elektroniikka ja teknologia',
        categories: ['mobiililaitteet', 'kamerat', 'televisiot', 'tietotekniikka', 'audio', 'kodintekniikka', 'teknologia', 'testit'],
        categoryNames: ['Mobiililaitteet', 'Kamerat', 'Televisiot', 'Tietotekniikka', 'Audio', 'Kodintekniikka'],
        catIds: [],
        items: [],
        link: '/testit-elektroniikka/',
      },
    ]
  }

  componentDidMount () {
    pushPageDataToGTM({})
    this.loadData()
  }

  componentWillUnmount () {
    this.props.setRendered(false)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.location.key !== this.props.location.key) {
      this.loadData()
    }
  }

  async loadData () {
    try {
      const categoryIds = await Promise.all(this.state.categories.map(async (cat) => {
        const categoryData = await WP.getTerms('category', {
          slug: cat.categories.join(','),
          hide_empty: false,
          cache: {
            expireAfter: Date.now() + 3600000 * 24 // 1 day
          }
        })
        return categoryData.data.map((x) => x.id)
      }))
      const newCats = this.state.categories.map((cat, i) => { cat.catIds = categoryIds[i]; return cat })

      const tagId = (await WP.getTerms('tags', {
        slug: this.state.tag,
        hide_empty: false,
        cache: {
          expireAfter: Date.now() + 3600000 * 24 // 1 day
        }
      })).data[0].id

      this.setState({ categories: newCats, tagId }, () => this.getArticles())

      const postReq = await WP.getForURL(WP.url + '/testit')
      const post = postReq.data
      this.actions.setViewData(post)
    } catch (e) {
      this.setState({
        status: STATUS.ERROR,
        data: e,
      })
    }

    if (this.props.view.id) {
      WP.triggerHitCounter(this.props.view.id)
    }
  }

  async getArticlesForCategories (categories, tag) {
    const params = {
      per_page: 3,
      categories: categories.join(','),
      order: 'desc',
      orderby: 'date',
      _embed: 1
    }
    if (tag) {
      params.tags = tag
    }

    return WP.getFromPostTypes(['post', 'om_digimag_post', 'om_theme_article'], params)
  }

  async getArticles () {
    const { categories, tagId } = this.state

    try {
      this.setState({ status: STATUS.REQUESTED })
      const updatedCategories = await Promise.all(categories.map(async (cat) => {
        const articles = await this.getArticlesForCategories(cat.catIds, cat.noTag ? null : tagId)
        const newCat = { ...cat }
        newCat.items = articles.data
        return newCat
      }))
      this.setState({ categories: updatedCategories }, () => this.props.setRendered(true))
    } catch (e) {
      console.log(e)
      this.setState({
        status: STATUS.ERROR,
        data: e,
      })
    }
  }

  renderArticles (categories) {
    const { status } = this.state

    const items =
      categories.map((cat, i) => {
        return <div key={i} styleName="item-category">
          <div styleName="item-header">
            <Link to={{ link: cat.link }}><h3>{cat.title}</h3></Link>
            <ShowAll url={cat.link} inline />
          </div>
          <div styleName="links">
            {cat.categoryNames && cat.categoryNames.map((name, i) => <Link key={i}
              to={{ link: cat.link + cat.categories[i] }}>{name} </Link>
            )}
          </div>
          <ul styleName="result-list">
            {cat.items.map((p, i) => (
              <li styleName="result-item" key={`${i}-${p.id}`}>
                <Link to={p}>
                  <TestCard article={p} isBest={cat.isBest}/>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      })

    const loading = status === STATUS.REQUESTED

    return (
      <Fragment>
        <div data-loading={loading}>
          {items}
        </div>
      </Fragment>
    )
  }

  render () {
    const { status, categories } = this.state
    const { view } = this.props

    if (status !== STATUS.ERROR && (!view || !view.id)) {
      return <BestProductPageLoader />
    }

    const {
      content,
      title,
      excerpt,
      link,
      featuredMedia,
    } = view

    const fullLink = window.location.origin + link
    const metaTags = {
      'og:locale': 'fi_FI',
      'og:type': 'article',
      'og:title': stripHTML(title),
      'og:description': stripHTML(excerpt),
      'og:url': fullLink,
      'http:status': 200,
    }
    return (
      <Fragment>
        <Helmet>
          <title>
            {`${stripHTML(title)} - ${window.om_constants.siteName}`}
          </title>
          <link rel="canonical" href={fullLink} />
          <meta name="description" content={metaTags['og:description']}/>
          {Object.entries(metaTags).map(([key, value], i) => Array.isArray(value)
            ? value.map(x => <meta name={key} key={`${key}-${i}`} content={x} />)
            : <meta name={key} key={`${key}-${i}`} content={value} />
          )}
        </Helmet>
        <div styleName="main-image">
          {featuredMedia
            ? <Image data={featuredMedia} size={IMAGE_SIZE.LARGE} sizes={'(max-width: 1440px) 100vw, 1440px'}/>
            : null
          }
        </div>
        <div styleName='magazine-content'>
          <article styleName="article-container">
            <header>
              <h1><HTML>{title}</HTML></h1>
            </header>
            <div styleName='article-body'>
              <HTML>{content}</HTML>
            </div>
          </article>
          <section styleName="results">
            {categories.length
              ? this.renderArticles(categories)
              : status === STATUS.ERROR
                ? <RenderedError error={categories}/>
                : <BestProductLoader/>
            }
          </section>
        </div>
        <TopRowAd isArticleView={false} display={true}/>
      </Fragment>
    )
  }
}

export default track({ gtmContext: ['TestBank'] })(withErrorBoundary(
  TestBank,
  ErrorPlaceholder()
))
