import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ArticleAuthor from './ArticleAuthor'
import './ArticleMeta.scss'

const ArticleAuthorList = ({ author, photographer, assistants }) => {
  return (
    <Fragment>
      <div id="article-author">
        <span styleName="heading">Teksti</span> <ArticleAuthor author={author}/>
      </div>
      {photographer && photographer.name
        ? (
          <div id="article-photograph-author">
            <span><span styleName="heading">Kuvat</span> <ArticleAuthor author={photographer}/></span>
          </div>
        )
        : null
      }
      {assistants && assistants.length
        ? assistants.map((assistant, index) => (
          <div key={`assistant-${index + 1}`}>
            <span><span styleName="heading">{assistant.title}</span> <ArticleAuthor author={assistant}/></span>
          </div>
        ))
        : null
      }
    </Fragment>
  )
}

ArticleAuthorList.propTypes = {
  author: PropTypes.object,
  photographer: PropTypes.object,
  assistants: PropTypes.array,
}

export default ArticleAuthorList
