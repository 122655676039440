import React, { Component } from 'react'
import PropTypes from 'prop-types'
import format from 'date-fns/format'
import get from 'lodash/get'

import TableOfContents from '../weeklyMagazine/TableOfContents'
import ThemeTOC from '../weeklyMagazine/ThemeTOC'
import Image from '../general/util/Image'
import ToCModal from './TableOfContentsModal'
import { Link } from '../general/util/Links'
import { ArrowRightPrimaryIcon, CaretLeft, CaretRight } from '../widgets/Icons'
// import { DownloadIssue } from '../widgets/OfflineWidgets'

import './DigimagHero.scss'

export default class DigimagHero extends Component {
  static propTypes = {
    digimag: PropTypes.object.isRequired,
    closeParent: PropTypes.func,
    prevLink: PropTypes.any,
    nextLink: PropTypes.any,
    toc: PropTypes.bool,
    mode: PropTypes.string,
    showTM: PropTypes.bool,
    isThemeMag: PropTypes.bool,
  }

  static defaultProps = {
    toc: true, // Show ToC by default
    mode: 'normal', // 'normal' | 'compact' | 'minimal' | 'block'
    showTM: true,
    isThemeMag: false,
  }

  state = {
    tocOpen: false,
  }

  toggleToc = () => {
    if (!this.element) { // If element isn't mounted, skip
      return
    }

    this.setState({
      tocOpen: !this.state.tocOpen
    })
  }

  /**
   * If this component is rendered through ToCModal, and if
   * the user is already in the magazine that the hero is rendered for
   * and clicks the read button, nothing happens.
   */
  closeParent = () => {
    if (!this.element) { // If element isn't mounted, skip
      return
    }

    if (this.props.closeParent) {
      this.props.closeParent()
    }
  }

  getArchiveToc (digimag) {
    return [{
      articles: digimag.pages.filter((page) => page.showInToc).slice(0, 5).map((page) => {
        if (page.excerpt) {
          page.vignette = page.title
          page.title = page.excerpt
        }
        return page
      })
    }]
  }

  render () {
    const { digimag, toc, mode, showTM, isThemeMag } = this.props
    const { tocOpen } = this.state
    const isAdMag = get(digimag, 'term.taxonomy') === 'om_ad_magazine'
    const isArchiveMag = get(digimag, 'archiveMag') === true
    const magLink = { link: isArchiveMag ? '/arkisto/' + digimag.slug : digimag.link }
    const archiveLink = { link: isArchiveMag ? digimag.archiveUrl : digimag.link }

    const firstTocSection = isArchiveMag ? this.getArchiveToc(digimag) : [get(digimag, 'toc[0]')]

    return (
      <div styleName={'digimag-hero mode-' + mode + ' ' + (isArchiveMag ? 'archive-mag' : '')} ref={(n) => { this.element = n }}>
        <div styleName="container">
          <div styleName="nav-link">{!!this.props.prevLink && <button onClick={this.props.prevLink}><CaretLeft color="blue"/></button>}</div>
          {mode === 'block' && <div styleName="toc-link"><Link to={digimag}><ArrowRightPrimaryIcon/> Digilehden etusivulle</Link></div>}
          <div styleName="cover">
            <Link to={archiveLink} onClick={this.closeParent}>
              <Image data={digimag.coverImage} noHeight/>
            </Link>
          </div>
          {mode !== 'block'
            ? <div styleName="content">
              <div styleName="header">
                <div styleName="left">
                  <span styleName="date">
                    {format(digimag.publishDate, 'DD.MM.YYYY')}
                  </span>

                  <h2 styleName="title">
                    {isAdMag
                      ? `Mainos: ${digimag.name}`
                      : (isArchiveMag
                        ? `Näköislehti ${window.om_constants.abbreviation} ${digimag.name}`
                        : `${showTM ? `Digilehti ${window.om_constants.abbreviation}` : ''} ${digimag.name}`)
                    }
                  </h2>
                </div>

                <div styleName="right">
                  {mode === 'normal' && toc && (isArchiveMag
                    ? <Link to={magLink}>Sisällysluettelo</Link>
                    : <button onClick={this.toggleToc}>
                      Sisällysluettelo </button>
                  )}
                </div>
              </div>

              {mode === 'normal' && toc && (
                <div styleName="toc">
                  {digimag && (isThemeMag
                    ? <ThemeTOC contents={digimag.toc}/>
                    : <TableOfContents contents={firstTocSection}/>)}
                </div>
              )}

              <div styleName="cta">
                {isArchiveMag
                  ? <Link to={archiveLink} styleName="open-magazine-button">
                    Lue {` ${showTM ? window.om_constants.abbreviation : ''} ${digimag.name}`}
                  </Link>
                  : <Link to={magLink} styleName="open-magazine-button" onClick={this.closeParent}>
                    Lue {isAdMag ? '' : ` ${showTM ? window.om_constants.abbreviation : ''} ${digimag.name}`}
                  </Link>
                }
                {!!digimag.legacyMaglink && mode !== 'normal' &&
                <div>
                  <Link to={{ link: digimag.legacyMaglink }} styleName="open-legacy-button">
                    M&K Näköislehti
                  </Link>
                </div>}
              </div>
            </div>
            : <button onClick={this.toggleToc}>Sisällysluettelo</button> }
          <div styleName="nav-link">{!!this.props.nextLink && <button onClick={this.props.nextLink}><CaretRight color="blue"/></button>}</div>
        </div>

        <ToCModal open={tocOpen} toggleModal={this.toggleToc} digimag={digimag} compact={true} isBrowsable={true} showTM={false}/>
      </div>
    )
  }
}
