import React from 'react'
import PropTypes from 'prop-types'
import siteLogo from '../../../assets/logos/site-logo-crop.svg'
import HTML from '@otavamedia/om-component-library/lib/util/HTML'
import './Title.scss'

const Title = ({ text, displayLogo }) => {
  return (
    <div styleName="title-container">
      <div styleName="title-icon">
        {displayLogo
          ? <img styleName="logo" src={siteLogo} alt={window.om_constants.abbreviation} />
          : null
        }
      </div>
      <h2 styleName="title-content">
        <HTML>{text}</HTML>
      </h2>
    </div>
  )
}

Title.propTypes = {
  text: PropTypes.string.isRequired,
  displayLogo: PropTypes.bool,
}

export default Title
