window.om_constants = {
  siteName: 'Metsästys ja Kalastus',
  siteNameGen: 'Metsästys ja Kalastuksen',
  aboutSite: 'M&K:sta',
  toSite: 'Metsästys ja Kalastukseen',
  magName: 'Metsästys ja Kalastus DIGI',
  magNameGen: 'Metsästys ja Kalastus DIGIn',
  archiveHighlights: 'M&K testaa - Arkistot kertovat',
  archiveHighlightsDesc: 'Selaile M&K:n erikoisnumeroita vuosien varrelta.',
  abbreviation: 'M&K',
  paywallHost: 'metsastysjakalastus.fi',
  paywallBrandId: 'MK',
  offerId: '73721',
  offerIdDev: '5191',
  clientId: '9180c21d-34be-4390-a345-46671e6f17bc',
  clientIdDev: '2c198232-52c6-4a80-bc54-2d89db5e4222',
  newsletterText: 'Tilaa Metsästyksen ja Kalastuksen kerran viikossa lähetettävä uutiskirje, niin pysyt ajan tasalla metsästämiseen ja kalastamiseen liittyvistä uutisista ja uusimmista jutuistamme!',
  ctaText1: ', vastuullinen luontosuhde perustuu oikeaan tietoon. Mitä enemmän tiedetään ja mitä vähemmän luullaan, sitä lujempi suomalaisesta luontosuhteesta muodostuu. ' +
    'Metsästys ja Kalastus haluaa rakentaa ja puolustaa vastuullista luontosuhdetta tuottamalla ja jakamalla tietoa, jotta huhuille ja luuloille ei jäisi tilaa. ' +
    'Siinä tarvitsemme myös Sinun panostasi.',
  ctaText2: ', luulojen pahin vihollinen on tieto. Juuri nyt tiedon tarve on suurimmillaan ja luuloista on tavallista enemmän haittaa. Metsästys ja Kalastus keskittyy tietoon, jotta luuloille ei jäisi mahdollisuuksia. Siinä tarvitsemme sinun apuasi.',
  solrUrl: '/solr/metsastysjakalastus/',
  dfpNetworkId: '135812570',
  containerName: 'metsastysjakalastus',
  orderLink: 'https://tilaus.metsastysjakalastus.fi/tilaus?promoName=OM_MK&promoCreative=onlinetarjous&',
  facebookLink: 'https://www.facebook.com/metsakala/',
  twitterLink: 'https://twitter.com/metsakala',
  feedLink: 'https://wp.metsastysjakalastus.fi/feed',
  instaLink: 'https://www.instagram.com/metsakala/',
  siteLogo: 'https://metsastysjakalastus.fi/assets/logos/MK-Logo.svg',
  backgroundVideo: '',
  paywallOptimize: 'HxVPkBQkRWGK8F8Xq_jiAA'
}
