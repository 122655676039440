import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from '../util/Links'
import HTML from '@otavamedia/om-component-library/lib/util/HTML'
import ArticleDate from './ArticleDate'
import { SubscriberIcon } from '../../widgets/Icons'
import './ArticleMeta.scss'
import track from 'react-tracking'

@track({ gtmContext: ['RelatedArticle'] })
class RelatedArticle extends Component {
  static propTypes = {
    article: PropTypes.object.isRequired,
  }

  render () {
    const { article } = this.props
    return (
      <div>
        <Fragment>
          <Link to={article}>
            <HTML>{article.title}</HTML>
            <ArticleDate date={article.createdDate}/>
            {article.forSubscribers && <div styleName="subscriber-icon-container"><SubscriberIcon/></div>}
          </Link>
        </Fragment>
      </div>
    )
  }
}

export default RelatedArticle
