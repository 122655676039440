import React, { Component } from 'react'
import './DigimagBox.scss'
import PropTypes from 'prop-types'
import track from 'react-tracking'
import { connect } from 'kea'
import magazineStore from '@otavamedia/om-component-library/lib/kea/weeklyMagazine'
import flatMap from 'lodash/flatMap'
import { Link } from '../general/util/Links'
import { ArrowRightPrimaryIcon } from '../widgets/Icons'
import HTML from '@otavamedia/om-component-library/lib/util/HTML'
import DigimagHero from '../navigation/DigimagHero'
import shuffle from 'lodash/shuffle'

@connect({
  props: [
    magazineStore, [
      'magazine as digimag',
      'previousArticle',
      'nextArticle',
    ],
  ],
})
class DigimagBox extends Component {
  _mounted = false
  state = {}

  static propTypes = {
    digimag: PropTypes.object,
    article: PropTypes.object,
    previousArticle: PropTypes.object,
    nextArticle: PropTypes.object,
  }

  constructor (props) {
    super()
    this.state.articles = this.updateArticles(props, true)
  }

  updateArticles (props, returnState) {
    if (props.digimag && props.digimag.toc) {
      const articles = shuffle(
        flatMap(props.digimag.toc, 'articles')
          .filter((a) => a.id !== props.article.id)
          .sort((a, b) => a.views < b.views ? 1 : -1)
          .slice(0, 10))
        .slice(0, 5)
      return returnState ? articles : this.setState({ articles })
    }
  }

  async loadData () {
  }

  componentDidMount () {
  }

  componentWillUnmount () {
  }

  UNSAFE_componentWillReceiveProps (props) {
    this.updateArticles(props)
  }

  render () {
    const { articles } = this.state
    const { digimag } = this.props
    if (!articles || !articles.length || !digimag.published) {
      return null
    }
    return (
      <div styleName="mag-box">
        <div styleName="links">
          <div styleName="list">
            <h2>Näitä luetaan</h2>
            <ul>
              {articles.map((art, idx) => (
                <li styleName="full-row" key={idx}>
                  <Link to={art}><ArrowRightPrimaryIcon/><span><HTML>{art.originalTitle}</HTML></span>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div>
          <DigimagHero digimag={digimag} toc={false} showTM={true} mode="block"/>
        </div>
      </div>
    )
  }
}

export default track({ gtmContext: ['DigimagBox'] })(DigimagBox)
