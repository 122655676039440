import React, { Component, Fragment } from 'react'
import './PrevNextBox.scss'
import PropTypes from 'prop-types'
import track from 'react-tracking'
import { connect } from 'kea'
import magazineStore from '@otavamedia/om-component-library/lib/kea/weeklyMagazine'
import flatMap from 'lodash/flatMap'
import { Link } from '../general/util/Links'
import shuffle from 'lodash/shuffle'

@connect({
  props: [
    magazineStore, [
      'magazine as digimag',
      'previousArticle',
      'nextArticle',
    ],
  ],
})
class PrevNextBox extends Component {
  _mounted = false
  state = {}

  static propTypes = {
    digimag: PropTypes.object,
    article: PropTypes.object,
    previousArticle: PropTypes.object,
    nextArticle: PropTypes.object,
  }

  constructor (props) {
    super()
    this.state.articles = this.updateArticles(props, true)
  }

  updateArticles (props, returnState) {
    if (props.digimag && props.digimag.toc) {
      const articles = shuffle(
        flatMap(props.digimag.toc, 'articles')
          .filter((a) => a.id !== props.article.id)
          .sort((a, b) => a.views < b.views ? 1 : -1)
          .slice(0, 10))
        .slice(0, 5)
      return returnState ? articles : this.setState({ articles })
    }
  }

  async loadData () {
  }

  componentDidMount () {
  }

  componentWillUnmount () {
  }

  UNSAFE_componentWillReceiveProps (props) {
    this.updateArticles(props)
  }

  render () {
    const { articles } = this.state
    const { previousArticle, nextArticle, article, digimag } = this.props
    if (!articles || !articles.length || !digimag.published) {
      return null
    }
    return (
      <div styleName="prev-next">
        <div styleName="left">
          {previousArticle && previousArticle.link
            ? <Fragment>
              <div styleName="arrow">{'\uf060'}</div>
              <div><Link to={previousArticle}>{'Edellinen' + (article.isAd ? ' mainos' : ' artikkeli')}</Link></div>
            </Fragment>
            : null}
        </div>
        <div styleName="right">
          {nextArticle && nextArticle.link
            ? <Fragment>
              <div><Link to={nextArticle}>{'Seuraava' + (article.isAd ? ' mainos' : ' artikkeli')}</Link></div>
              <div styleName="arrow">{'\uf061'}</div>
            </Fragment>
            : null}
        </div>
      </div>
    )
  }
}

export default track({ gtmContext: ['PrevNextBox'] })(PrevNextBox)
