import React, { Component } from 'react'
import Modal from 'react-modal'
import WP from '@otavamedia/om-component-library/lib/lib/WP'
import articleTypes from '../components/general/article/ArticleTypes'
import HTML from '@otavamedia/om-component-library/lib/util/HTML'
import ArticleHTML from '../components/general/util/ArticleHTML'
import '../components/weeklyMagazine/MagazineArticle.scss'
import merge from 'lodash/merge'
import PropTypes from 'prop-types'

const modalStyle = () => {
  const shared = {
    overlay: {
      background: 'rgba(0, 0, 0, 0.85)',
    },
    content: {
      border: 'none',
      borderRadius: 0,
      boxShadow: '0 1px 4px 0 rgba(0, 0, 0, 0.1)',
      position: 'absolute',
      padding: 0,
    }
  }

  const mobile = {
    overlay: {
      top: 0,
      bottom: 0,
    },

    content: {
      maxWidth: '100%',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0
    }
  }

  const desktop = {
    overlay: {
      top: 0,
    },

  }

  return window.innerWidth <= 991 ? merge({}, shared, mobile) : merge({}, shared, desktop)
}

export default class DigimagHelp extends Component {
  static propTypes = {
    close: PropTypes.func,
  }

  constructor (props) {
    super(props)
    this.loadPage()
    this.state = { view: null }
  }

  async loadPage () {
    const page = await WP.getForURL(WP.url + '/digilehden-lukuohje')
    this.setState({ view: page.data })
  }

  closeModal () {
    this.props.close()
  }

  renderHeader = (article, title, video, shouldRenderHeroImage) => {
    return (
      <header styleName="header">
        {shouldRenderHeroImage ? this.renderHeaderImage(video, article.featuredMedia, article.mobileHero) : null}
        <div style={{ textAlign: 'center' }}
          styleName={` ${shouldRenderHeroImage ? (article.mobileHero ? 'on-hero-image on-hero-image-mobile' : 'on-hero-image') : ''}`}>
          {article.groupHeader && <span styleName="group-header">{article.groupHeader}</span>}

          {article.vignette
            ? (
              <div styleName="vignette">
                {this.renderVignetteAndTopic(article)}
              </div>
            )
            : null
          }
          <h1 style={{ marginTop: '1rem' }} styleName={article.capitalizeTitle ? 'capitalized' : ''}>
            <HTML>{title}</HTML></h1>
          {article.ingress ? <div styleName="ingress"><HTML>{article.ingress}</HTML></div> : null}
        </div>
      </header>
    )
  }

  render () {
    const {
      view: article
    } = this.state

    let modalContent
    if (article) {
      const {
        articleType,
        content,
        pagination,
        headerVideoMp4,
        headerVideoWebm,
        title,
      } = article

      modalContent = <div style={{
        margin: 'auto',
        width: '100%',
        background: '#fff',
        color: '#00091e',
        overflowY: 'scroll',
        height: 'calc(100% - 3.5rem)'
      }}>
        <article styleName="article article-container" id="magazine-article"
          style={{ padding: '0 24px', width: '99%', maxWidth: '1440px', margin: 'auto' }}>
          {this.renderHeader(article, title, headerVideoMp4 || headerVideoWebm, false)}
          <div styleName={`body-container ${articleType === articleTypes.MAGAZINE_COMMENT ? 'comment' : ''}`}>
            <div styleName="article-text" className={'article-body '}>
              <ArticleHTML options={{ pagination, isModal: true }}>{content}</ArticleHTML>
            </div>
          </div>
        </article>
      </div>
    }

    return <Modal isOpen={true} onRequestClose={() => this.closeModal()} style={modalStyle()}>
      <div style={{
        flex: '0 1 auto',
        height: '3.5rem',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        borderBottom: '1px solid rgb(217, 218, 220)'
      }}>
        <button style={{
          padding: '0 1rem 0 2.25rem',
          background: 'none',
          border: 'none',
          borderLeft: '1px solid #d9dadc'
        }} onClick={() => this.closeModal()}>
          <span aria-label="Sulje">Sulje</span><span aria-label="Sulje"
            style={{ color: '#004f9d', paddingLeft: '1rem' }}>×</span>
        </button>
      </div>
      {modalContent}
    </Modal>
  }
}
