import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './DfpAdSlot.scss'

export default class AdDebugInfoBox extends Component {
  static propTypes = {
    slotId: PropTypes.string,
    adUnit: PropTypes.string,
    uniqueId: PropTypes.string,
    sizes: PropTypes.array,
  }

  extraDebugFields = {
    adUnit: (value) => value,
    uniqueId: (value) => value,
    sizes: (value) => value.map((item) => {
      return <span key={item}>{item.join('x')} </span>
    }),
  }

  constructor () {
    super()

    this.state = {
      expanded: false,
    }
  }

  render () {
    const {
      slotId,
    } = this.props

    const {
      expanded,
    } = this.state

    const classes = ['debugInfoBox']

    if (expanded) classes.push('expanded')

    return (
      <div styleName={classes.join(' ')} onClick={this.handleOnClick} role="presentation">
        <div styleName="basic">
          {slotId}
        </div>
        <div styleName="extra">
          <ul>
            {Object.keys(this.props).map((propKey) => {
              if (Object.keys(this.extraDebugFields).includes(propKey)) {
                return (
                  <li
                    key={propKey}
                    data-prop={propKey}
                  >
                    {this.extraDebugFields[propKey](this.props[propKey])}
                  </li>
                )
              }
              return null
            })}
          </ul>
        </div>
      </div>
    )
  }

  handleOnClick = () => {
    this.setState({ expanded: !this.state.expanded })
  }
}
