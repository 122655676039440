import React, { Component } from 'react'
import PropTypes from 'prop-types'

import GoodBad from './GoodBad'
import WP from '@otavamedia/om-component-library/lib/lib/WP'
import { ShowAll } from '../../widgets/ShowAll'
import { SubscriberIcon } from '../../widgets/Icons'
import { withRouter } from 'react-router-dom'
import { connect } from 'kea'
import application from '@otavamedia/om-component-library/lib/kea/application'
import auth from '@otavamedia/om-component-library/lib/kea/auth'
import { Link } from '../util/Links'
import HTML from '@otavamedia/om-component-library/lib/util/HTML'
import ProductAds from './ProductAds'
import ArticleDate from './ArticleDate'
import ProductComments from './ProductComments'

import './ProductCard.scss'

@connect({
  props: [
    application, [
      'view',
    ],
    auth, [
      'canAccessArticle'
    ]
  ],
})
class ProductCard extends Component {
  static propTypes = {
    card: PropTypes.object.isRequired,
    view: PropTypes.object.isRequired,
    canAccessArticle: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
  }

  state = {
    linkedArticles: [],
    productCardIds: []
  }

  constructor (props) {
    super(props)
    this.loadLinked(props)
  }

  async loadLinked (props) {
    if (props.card && props.card.postsCardIsLinkedTo && (props.card.postsCardIsLinkedTo.length > 1 || props.view.id !== this.props.card.cardOwnerPost ||
      (this.props.card.otherProductCards && this.props.card.otherProductCards.length))) {
      const cardResponse = (this.props.card.otherProductCards && this.props.card.otherProductCards.length)
        ? await WP.getFromPostTypes(['om_product'], { include: this.props.card.otherProductCards }, 'posts', 'productcard')
        : null
      const linkedCards = cardResponse && cardResponse.data && cardResponse.data.length ? cardResponse.data : []
      const otherArticles = linkedCards.flatMap(card => card.postsCardIsLinkedTo).filter(x => x)

      const response = this.props.card.postsCardIsLinkedTo
        ? await WP.getFromPostTypes(Object.keys(WP.postTypes), { include: otherArticles.concat(this.props.card.postsCardIsLinkedTo) })
        : null

      let linkedArticles = response && response.data && response.data.length ? response.data : []

      // there is a separate link to main article, remove it from list
      const mainArticleIdx = linkedArticles.findIndex(post => post.id === this.props.card.cardOwnerPost)
      let mainArticle = linkedArticles[mainArticleIdx]
      if (mainArticleIdx >= 0) {
        linkedArticles.splice(mainArticleIdx, 1)
      } else {
        const response = this.props.card.cardOwnerPost
          ? await WP.getFromPostTypes(Object.keys(WP.postTypes), { include: [this.props.card.cardOwnerPost] })
          : null
        mainArticle = response && response.data && response.data.length ? response.data[0] : null
      }

      // don't link to current article
      const currentArticleIdx = linkedArticles.findIndex(post => post.id === props.view.id)
      if (currentArticleIdx >= 0) {
        linkedArticles.splice(currentArticleIdx, 1)
      }

      const productCardIds = linkedCards.map(card => card.id)
      productCardIds.push(props.card.id)
      linkedArticles = linkedArticles.filter(article => article.categories && article.categories.some(cat => [12199, 1052, 8, 1060].includes(cat.id)))
      linkedArticles.forEach((article) => {
        article.activeCard = article.linkedProductCards.find(articleCard => productCardIds.find((cardId) => articleCard.id === cardId))
      })

      this.setState({ linkedArticles, mainArticle, productCardIds })
    } else {
      if (this.props.view.id === this.props.card.cardOwnerPost) {
        this.setState({ mainArticle: this.props.view })
      } else {
        const response = this.props.card.cardOwnerPost
          ? await WP.getFromPostTypes(Object.keys(WP.postTypes), { include: [this.props.card.cardOwnerPost] })
          : null
        this.setState({ mainArticle: response && response.data && response.data.length ? response.data[0] : null })
      }
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps, nextContext) {
    this.loadLinked(nextProps)
  }

  render () {
    const { card, location, view } = this.props
    const { mainArticle, linkedArticles } = this.state
    const showLink = mainArticle && location.pathname !== mainArticle.link
    const payWall = !this.props.canAccessArticle(view)
    const { productAds, relatedAds, productData } = card

    if (!card) {
      return null
    }
    return <div className="aligncenter" styleName="main">
      {showLink
        ? <div styleName="product-link">
          <div>
            <ShowAll url={mainArticle.link} inline
              linkText={'Lue koko artikkeli' + (mainArticle.printmag ? ` (${window.om_constants.abbreviation} ${mainArticle.printmag.name})` : '')}/>
          </div>
          {mainArticle.forSubscribers && <div styleName="subscriber-icon"><SubscriberIcon/></div>}
        </div>
        : null}
      <GoodBad data={card.productData} short={payWall}/>
      {productData.id && card.allowCommenting ? <ProductComments id={productData.id}/> : null}
      {linkedArticles.length
        ? <div styleName="linked-articles">
          <div styleName="header">
          Muut testit ja vertailut tästä tuotteesta
          </div>
          <div styleName="list">
            {linkedArticles.map((article, idx) => <div key={idx}>
              <div styleName="list-item">
                <div styleName="title">
                  <Link to={article}><HTML>{article.title}</HTML></Link>
                  <span styleName="meta">
                    <ArticleDate date={article.createdDate} dateOnly={true} />
                  </span>
                </div>
              </div>
            </div>)}
          </div>
        </div>
        : null }
      {(productAds && productAds.length) || (relatedAds && relatedAds.length)
        ? (
          <ProductAds ads={productAds} related={relatedAds}/>
        )
        : null
      }
    </div>
  }
}

export default withRouter(ProductCard)
