import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import { connect } from 'kea'
import application from '@otavamedia/om-component-library/lib/kea/application'
import auth from '@otavamedia/om-component-library/lib/kea/auth'
import HTML from '@otavamedia/om-component-library/lib/util/HTML'
import BestProductLoader from '../components/general/util/BestProductLoader'
import { CaretDown } from '../components/widgets/Icons'
import { ErrorPlaceholder, withErrorBoundary } from '../components/general/util/ErrorBoundaries'
import TopRowAd from '../components/general/util/TopRowAd'
import TestCard from '../components/widgets/TestCard'
import Image from '../components/general/util/Image'
import WP from '@otavamedia/om-component-library/lib/lib/WP'
import RenderedError from '../components/general/util/RenderedError'
import { STATUS } from '@otavamedia/om-component-library/lib/lib/request-state'
import { pushPageDataToGTM, stripHTML } from '@otavamedia/om-component-library/lib/lib/utils'
import { IMAGE_SIZE } from '@otavamedia/om-component-library/lib/entities/ImageModel'
import VideoOverlay from '../components/widgets/VideoOverlay'

import './BestProductCollection.scss'
import track from 'react-tracking'

const getPageFromUrl = (link) => {
  const regex = /^(.*\/)([0-9]+)(\/)$/
  return link && parseInt(link.replace(regex, '$2'))
}

@connect({
  actions: [
    application, [
      'setViewData',
      'setRendered'
    ]
  ],
  props: [
    application, [
      'view',
    ],
    auth, [
      'premiumUser',
    ],
  ]
})
class Videos extends Component {
  static propTypes = {
    view: PropTypes.object,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    setRendered: PropTypes.func.isRequired,
    premiumUser: PropTypes.bool,
  }

  state = {
    data: null,
    status: STATUS.NOT_REQUESTED,
    page: getPageFromUrl(window.location.href) || 1,
    maxPages: 1,
    categories: [
      { id: 'hPY_0FF_LzR6e9JbonpJOQ', name: 'Autot' },
      { id: 'Dj2de_V9McPnU-vrWD4Oag', name: 'Muu tekniikka' },
      { id: 'PBWuU-XC99MGPbsW-lEIBg', name: 'Koeajot' },
    ],
    order: '-'
  }

  constructor () {
    super()
    this.loadPage()
  }

  async componentDidMount () {
    pushPageDataToGTM({})
    this.loadData()
    // const categories = await WP.getVideoCategories()
    // this.setState({categories})
  }

  componentWillUnmount () {
    this.props.setRendered(false)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.location.key !== this.props.location.key) {
      this.setState({
        data: null,
        page: getPageFromUrl(window.location.href) || 1,
      }, () => this.loadData())
    }
  }

  async loadPage () {
    const page = await WP.getForURL(WP.url + '/videot/')
    page.data.headerVideoMp4 = { url: window.om_constants.backgroundVideo }
    this.actions.setViewData(page.data)
    this.props.setRendered(true)
  }

  async loadData () {
    try {
      this.setState({ status: STATUS.REQUESTED })
      const data = await WP.getVideos(this.state.page, this.state.order, this.state.secondaryTerm)
      this.setState({
        data: [...(this.state.data || []), ...data.items],
        maxPages: Math.ceil(data.total / 9),
        status: STATUS.DONE,
      })
    } catch (e) {
      this.setState({
        status: STATUS.ERROR,
        data: e,
      })
    }
  }

  addPage = () => {
    this.setState({
      page: this.state.page + 1
    }, this.loadData)
  }

  showVideo (video) {
    this.setState({
      activeVideo: video
    })
  }

  closeVideo () {
    this.setState({
      activeVideo: null
    })
  }

  handleFormChange = (e) => {
    const { target } = e
    // console.log(target.name, target.value)

    this.setState({
      [target.name]: target.value,
      data: null,
      page: 1,
    }, this.loadData)
  }

  renderVideos (videos) {
    const { page, maxPages, status } = this.state

    if (!videos.length) {
      return <div>Videoita ei löytynyt.</div>
    }
    const items = videos.map((p, i) => (
      <li styleName="result-item" key={`${i}-${p.id}`}>
        <button onClick={() => { this.showVideo(p); return false }}>
          <TestCard article={{
            featuredMedia: {
              src: p.meta.poster['240p'] || p.meta.poster.image,
              alt: '',
              sizes: { medium: { source_url: p.meta.poster['240p'] } }
            },
            categories: [p.meta.category],
            title: p.meta.title,
            createdDate: p.meta.created
          }}/>
        </button>

      </li>
    ))
    const loading = status === STATUS.REQUESTED
    return (
      <Fragment>
        <ul styleName="result-list" data-loading={loading}>
          {items}
        </ul>
        {
          loading
            ? <BestProductLoader />
            : (
              page < maxPages
                ? (
                  <div styleName="load-more">
                    <button styleName="load-more" onClick={() => this.addPage()}>
                      Lataa lisää
                    </button>
                  </div>
                )
                : null
            )
        }
      </Fragment>
    )
  }

  render () {
    const { status, data, categories, activeVideo } = this.state

    const {
      content,
      title,
      excerpt,
      createdDate,
      modifiedDate,
      link,
      featuredMedia,
      headerVideoMp4
    } = this.props.view

    const fullLink = window.location.origin + link
    const metaTags = {
      'og:locale': 'fi_FI',
      'og:type': 'article',
      'og:title': stripHTML(title),
      'og:description': stripHTML(excerpt),
      'og:url': fullLink,

      // og:type is article, more tags for it:
      'article:published_time': createdDate,
      'article:modified_time': modifiedDate,

      'http:status': 200,
    }

    /* eslint-disable jsx-a11y/no-onchange */
    /* eslint-disable jsx-a11y/label-has-for */
    /* eslint-disable jsx-a11y/no-static-element-interactions */
    /* eslint-disable jsx-a11y/click-events-have-key-events */
    return <Fragment>
      <Helmet>
        <title>
          {`Videot - ${window.om_constants.siteName}`}
        </title>
        <link rel="canonical" href={fullLink}/>
        <meta name="description" content={metaTags['og:description']}/>
        {Object.entries(metaTags).map(([key, value], i) => Array.isArray(value)
          ? (
            value.map(x => (
              <meta name={key} key={`${key}-${i}`} content={x}/>
            ))
          )
          : (
            <meta name={key} key={`${key}-${i}`} content={value}/>
          ))}
      </Helmet>
      <div styleName="main-image with-video">
        {headerVideoMp4
          ? <video styleName="header-video" muted playsInline autoPlay loop> {/* eslint-disable-line */}
            <source src={headerVideoMp4.url}/>
          </video>
          : (featuredMedia
            ? <Image data={featuredMedia} size={IMAGE_SIZE.LARGE} sizes={'(max-width: 1440px) 100vw, 1440px'}/>
            : null)
        }
      </div>
      <div styleName='magazine-content on-hero-image'>
        <article styleName="article-container">
          <header>
            <h1><HTML>{title}</HTML></h1>
          </header>
          <div styleName='article-body'>
            <HTML>{content}</HTML>
          </div>
        </article>
        {!!activeVideo && <VideoOverlay activeVideo={activeVideo} closeVideo={() => this.closeVideo()} />}
        <form styleName="filterform">
          <div styleName="result-filters">
            <div>
              <label htmlFor="sort">Kategoria</label>
              <div styleName="select-div">
                <select id="category-select" name="secondaryTerm" onChange={this.handleFormChange} value={this.state.secondaryTerm}>
                  <option value="">Kaikki</option>
                  {categories && categories.length
                    ? categories.map((x, i) => (
                      <option value={x.id} key={i}>{x.name}</option>
                    ))
                    : <option value="">Kategorioita ladataan...</option>}
                </select>
                <div styleName="select-caret"><CaretDown color="red"/></div>
              </div>
            </div>

            <div>
              <label htmlFor="sort">Järjestä</label>
              <div styleName="select-div">
                <select id="sort-select" name="order" onChange={this.handleFormChange} defaultValue="-">
                  <option value="-">Uusimmat</option>
                  <option value="+">Vanhimmat</option>
                </select>
                <div styleName="select-caret"><CaretDown color="red"/></div>
              </div>
            </div>
          </div>
        </form>

        <section styleName="results">
          {data
            ? (
              this.renderVideos(data)
            )
            : status === STATUS.ERROR
              ? (
                <RenderedError error={data}/>
              )
              : (
                <BestProductLoader/>
              )}
        </section>
      </div>
      <TopRowAd isArticleView={false} display={true}/>
    </Fragment>
  }
}

export default track({ gtmContext: ['Videos'] })(withErrorBoundary(
  Videos,
  ErrorPlaceholder()
))
