import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from '../general/util/Links'
import HTML from '@otavamedia/om-component-library/lib/util/HTML'
import quoteImage from '../../assets/ui/quote-icon.svg'
import './QuoteItem.scss'
import { ErrorPlaceholderBox, withErrorBoundary } from '../general/util/ErrorBoundaries'

class Article extends Component {
  static propTypes = {
    article: PropTypes.object,
    bw: PropTypes.bool,
    largeFont: PropTypes.bool,
  }

  render () {
    const { article, bw } = this.props
    return article
      ? (
        <div key={article.id}>
          <Link to={article}>
            <article styleName="quote-list-item">
              <div styleName="item-image">
                <img src={quoteImage} alt={'"'} />
              </div>
              <div styleName={`quote ${bw ? 'black-and-white' : ''}`}>
                <h2><HTML>{article.quote}</HTML></h2>
                <div styleName="header">
                  <div><HTML>{article.title}</HTML></div>
                </div>
              </div>
            </article>
          </Link>
        </div>
      )
      : null
  }
}

export default withErrorBoundary(
  Article,
  ErrorPlaceholderBox('Artikkelia ei saatu ladattua'),
)
