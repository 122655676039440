import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactPlaceholder from 'react-placeholder'
import WP from '@otavamedia/om-component-library/lib/lib/WP'
import { STATUS } from '@otavamedia/om-component-library/lib/lib/request-state'
import { errors as err } from '@otavamedia/om-component-library/lib/lib/errors'
import './Shortcuts.scss'
import { ErrorPlaceholderBox, withErrorBoundary } from '../general/util/ErrorBoundaries'
import EditorsPicksLoader from '../general/util/EditorsPicksLoader'
import { Link } from '../general/util/Links'
import HTML from '@otavamedia/om-component-library/lib/util/HTML'
import { ArrowRightPrimaryIcon } from './Icons'
import track from 'react-tracking'

class Shortcuts extends Component {
  _mounted = false

  constructor (props) {
    super(props)
    this.state = {
      shortcuts: [],
      status: STATUS.NOT_REQUESTED,
    }
  }

  loadData (category) {
    WP.getShortcuts(category)
      .then((shortcuts) => {
        if (this._mounted) {
          this.setState({ shortcuts, status: shortcuts ? STATUS.DONE : STATUS.ERROR })
        }
      })
      .catch((e) => this._mounted && this.setState({ status: STATUS.ERROR, error: e }))
      .finally(() => this.props.doneLoading && this.props.doneLoading())
  }

  componentDidMount () {
    this._mounted = true
    if (this.props.category !== -1) {
      this.loadData(this.props.category)
    }
  }

  UNSAFE_componentWillReceiveProps (props) {
    if (props.category !== -1 && props.category !== this.props.category) {
      this.loadData(props.category)
    }
  }

  componentWillUnmount () {
    this._mounted = false
  }

  render () {
    const { shortcuts, status } = this.state

    if (status === STATUS.ERROR) throw err.editorsPicksLoadError({ previousError: this.state.error })

    if (!shortcuts || !shortcuts.length) {
      return null
    }
    return (
      <div styleName="main">
        <h4 styleName="header">Oikopolut</h4>
        <div styleName="list">
          <ReactPlaceholder ready={status === STATUS.DONE} customPlaceholder={<EditorsPicksLoader items={3} />}>
            <ul>
              {shortcuts.map((article, idx) => (
                <li styleName="full-row" key={idx}>
                  <Link to={article}><ArrowRightPrimaryIcon/><span><HTML>{article.title}</HTML></span></Link>
                </li>
              ))}
            </ul>
          </ReactPlaceholder>
        </div>
      </div>
    )
  }
}

Shortcuts.propTypes = {
  category: PropTypes.number,
  doneLoading: PropTypes.func,
}

export default track({ gtmContext: ['Shortcuts'] })(withErrorBoundary(
  Shortcuts,
  ErrorPlaceholderBox('Oikopolkuja ei saatu ladattua'),
))
