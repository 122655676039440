import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './TotalPoll.scss'
import HTML from '@otavamedia/om-component-library/lib/util/HTML'
import WP from '@otavamedia/om-component-library/lib/lib/WP'

/* eslint-disable jsx-a11y/label-has-for */

class TotalPoll extends Component {
  static propTypes = {
    poll: PropTypes.number
  }

  state = {}

  constructor (props) {
    super(props)
    this.loadPoll(props.poll)
  }

  async loadPoll (pollId) {
    const data = await WP.getTotalPoll(pollId)
    const questions = Object.values(data.questions)
    const options = Object.values(questions[0].choices)
    this.setState({ question: questions[0], options, poll: data })
  }

  async submitVote () {
    const data = await WP.submitPoll(this.props.poll, this.state.question.uid, this.state.option)
    document.cookie = `voted${this.props.poll}=1`
    const questions = Object.values(data.questions)
    const options = Object.values(questions[0].choices)
    this.setState({ question: questions[0], options, poll: data, submitted: true, showResults: true })
  }

  render () {
    const { question, options, poll, showResults, submitted } = this.state
    if (!question) {
      return null
    }

    const voted = document.cookie.includes(`voted${this.props.poll}=1`)

    if (voted || showResults) {
      return <div styleName="vote-form">
        <h3><HTML>{poll.title}</HTML></h3>
        {submitted ? <p>Kiitos äänestäsi!</p> : null}
        <p>Äänestystilanne:</p>
        {options.map((option, key) => <label key={key}>{option.votes} ääntä: <HTML>{option.html}</HTML></label>)}
      </div>
    }

    return <div styleName="vote-form">
      <h3><HTML>{poll.title}</HTML></h3>
      <p><HTML>{question.content}</HTML></p>
      {options.map((option, key) => <label key={key}><input name="poll" type="radio" required={true}
        onChange={() => this.setState({ option: option.uid })}/> <HTML>{option.html}</HTML></label>)}
      <div styleName="button-row">
        <button type="button" styleName="show-results"
          onClick={() => this.setState({ showResults: true })}>Näytä tulokset
        </button>
        {!voted
          ? <button type="button" disabled={!this.state.option}
            onClick={() => this.submitVote()}>Äänestä</button>
          : null}
      </div>
    </div>
  }
}

export default TotalPoll
