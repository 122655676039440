import React, { Component } from 'react'
import PropTypes from 'prop-types'
import SwipeableViews from 'react-swipeable-views'
import { bindKeyboard } from 'react-swipeable-views-utils'
import MagazineArticleLoader from '../general/util/MagazineArticleLoader'
import MagazineArticle from '../weeklyMagazine/MagazineArticle'
import FeatureArticle from '../weeklyMagazine/FeatureArticle'
import { connect } from 'kea'
import magazineStore from '@otavamedia/om-component-library/lib/kea/weeklyMagazine'
import { withRouter } from 'react-router-dom'
import './ArticleSwiper.scss'
import get from 'lodash/get'
import application from '@otavamedia/om-component-library/lib/kea/application'

const styles = {
  slide: {
    WebkitOverflowScrolling: 'touch', // iOS momentum scrolling
  },
}

const pageTypes = {
  PLACEHOLDER: 'placeholder',
  ARTICLE: 'article'
}
const KeyboardSwipeableViews = bindKeyboard(SwipeableViews)

@connect({
  props: [
    magazineStore, [
      'previousArticle',
      'nextArticle',
      'firstArticle',
      'lastArticle'
    ],
    application, [
      'isFeatureArticle',
    ]
  ]
})
class ArticleSwiper extends Component {
  static propTypes = {
    nativeAd: PropTypes.object,
    grow: PropTypes.bool,
    history: PropTypes.object,
    isFeatureArticle: PropTypes.bool
  }

  constructor (props) {
    super()
    this.state = this.updatePages(props, true)
  }

  updatePages (props, returnState) {
    const pages = [pageTypes.PLACEHOLDER, pageTypes.ARTICLE, pageTypes.PLACEHOLDER]
    const index = 1
    if (returnState) {
      return { index, pages }
    }
    this.setState({ index, pages })
  }

  handleChangeIndex (index, lastIndex) {
    let link
    if (index > lastIndex) {
      link = get(this, 'props.nextArticle.link') || get(this, 'props.firstArticle.link')
    } else {
      link = get(this, 'props.previousArticle.link') || get(this, 'props.lastArticle.link')
    }
    if (link) {
      this.props.history.push(link)
    } else {
      this.setState({ index: 1 })
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    this.updatePages(nextProps)
  }

  render () {
    const { index, pages } = this.state
    const { isFeatureArticle } = this.props
    if (!get(this, 'props.firstArticle.link') && !window.location.href.match(/\/lehti/) && !window.location.href.match(/\/mainoslehti/)) {
      // single article, no magazine
      return isFeatureArticle ? <FeatureArticle {...this.props}/> : <MagazineArticle {...this.props}/>
    }
    return (
      <KeyboardSwipeableViews resistance ignoreNativeScroll index={index} animateTransitions={false}
        threshold={100} hysteresis={0.4}
        onChangeIndex={(idx, last) => this.handleChangeIndex(idx, last)}>
        {pages.map((page, i) => page === pageTypes.PLACEHOLDER
          ? <div style={styles.slide} key={i}>
            <MagazineArticleLoader/>
          </div>
          : <div style={styles.slide} key={i}>
            {isFeatureArticle ? <FeatureArticle {...this.props}/> : <MagazineArticle {...this.props}/>}
          </div>)}
      </KeyboardSwipeableViews>
    )
  }
}

export default withRouter(ArticleSwiper)
