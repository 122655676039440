import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import './WeeklyMagazine.scss'
import ArticleItem from './ArticleItem'
import { ErrorPlaceholder, withErrorBoundary } from '../general/util/ErrorBoundaries'
import QuoteItem from './QuoteItem'
import { isEditorLikeUser } from '@otavamedia/om-component-library/lib/lib/userManagement'
import { connect } from 'kea'
import auth from '@otavamedia/om-component-library/lib/kea/auth'

const SIZE_MAP = {
  large: 'lg',
  medium: 'md',
  small: 'sm',
}

@connect({
  props: [
    auth, [
      'userRoles',
    ],
  ]
})
class Column extends Component {
  renderPostGroup = group => {
    const { magazine, showDate, latest } = this.props
    const styles = group[group.type].blockStyle
    const posts = group[group.type].posts

    return posts.map((item, index) => {
      item.blockStyle = styles

      if (item.post.postStatus && item.post.postStatus !== 'publish' && !isEditorLikeUser(this.props.userRoles)) {
        return null
      }
      return <ArticleItem
        key={item.post.link.replace(/\//g, '')}
        article={item.post}
        size={SIZE_MAP[item.blockStyle.size]}
        noImage={item.post.withoutImage}
        backgroundColour={item.colour ? item.colour.background : ''}
        capitalizeTitle={item.effects.capitalizeTitle}
        equation={item.effects.title}
        magazine={magazine}
        showDate={showDate}
        latest={latest}
        related={item.related ? item.related : []}
      />
    })
      .filter(x => x)
  }

  renderSingleItem = item => {
    const { magazine, showDate, latest } = this.props
    if (item[item.type].post.postStatus && item[item.type].post.postStatus !== 'publish' && !isEditorLikeUser(this.props.userRoles)) {
      return null
    }

    switch (item.type) {
    case 'primary':
      return <ArticleItem
        article={item[item.type].post}
        size="lg"
        noImage
        largeFont
        backgroundColour={item[item.type].colour ? item[item.type].colour.background : ''}
        capitalizeTitle={item[item.type].effects.capitalizeTitle}
        magazine={magazine}
        related={item[item.type].related ? item[item.type].related : []}
        showDate={showDate}
        latest={latest}
        isPrimary={true}
      />
    case 'singularArticle':
      return <ArticleItem
        article={item[item.type].post}
        size={SIZE_MAP[item[item.type].blockStyle.size]}
        noImage={item[item.type].post.withoutImage}
        backgroundColour={item[item.type].colour ? item[item.type].colour.background : ''}
        capitalizeTitle={item[item.type].effects.capitalizeTitle}
        equation={item[item.type].effects.title}
        magazine={magazine}
        related={item[item.type].related ? item[item.type].related : []}
        imageLocation={item[item.type].imageLocation}
        showDate={showDate}
        latest={latest}
      />
    case 'quote':
      return <QuoteItem
        article={{ ...item[item.type].post, quote: item[item.type].quote }}
        largeFont
        backgroundColour={item[item.type].colour ? item[item.type].colour.background : ''}
        magazine={magazine}
        related={item[item.type].related}
      />
    default:
      // nothing
    }
  }

  render () {
    const { content } = this.props
    return (
      <Fragment>
        {content.type === 'postGroup'
          ? this.renderPostGroup(content)
          : this.renderSingleItem(content)
        }
      </Fragment>
    )
  }
}

Column.propTypes = {
  content: PropTypes.object.isRequired,
  magazine: PropTypes.string,
  showDate: PropTypes.bool,
  latest: PropTypes.string,
  userRoles: PropTypes.array,
}

export default withErrorBoundary(
  Column,
  ErrorPlaceholder(null)
)
