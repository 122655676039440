import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ArticleCard from '@otavamedia/om-component-library/lib/ArticleCard'
import HTMLParser from '@otavamedia/om-component-library/lib/lib/html-parser'
import styled from 'styled-components'
import track from 'react-tracking'
import { Link } from '../util/Links'
import { ErrorPlaceholderBox, withErrorBoundary } from '../util/ErrorBoundaries'
import GAMNativeAd from '../../widgets/GAMNativeAd'

import './ArticleListItem.scss'

const ArticleItem = styled(ArticleCard)`
  margin-bottom: 1.5rem;
  height: 100%;
  h2 {
    margin: 0;
    font-size: 16px;
    line-height: 20px;
    font-family: "Gotham-Black";
    letter-spacing: 0.1px;
    @media (min-width: 992px) {
      font-size: 20px;
      line-height: 28px;
      font-family: "Gotham-Black";
    }
    a {
      display: block;
    }
  }
  
  video {
    display: block;
  }
  .image-container {
    border-bottom: 4px solid #00091e;
  }

  .card-label {
    text-transform: uppercase;
    font-size: 0.75rem;
    line-height: 1rem;
  }

  .card-meta {
    font-family: "Gotham-Black";
    text-transform: uppercase;
    font-size: 12px;
    line-height: 1rem;
    padding: 1rem;
    padding-bottom: 0;
    justify-content: space-between;
    .date {
      font-family: "GothamNarrow-Medium", sans-serif;
    }
  }

  .meta-divider {
    padding: 0 0.5rem;
  }

  .category-list {
    white-space: normal;
  }

  .category-list li:first-of-type {
    margin-left: 0;
  }

  .category-list li:last-of-type {
    margin-right: 0;
  }

  .subscriber-icon {
    font-family: "GothamNarrow-Medium", sans-serif;
    font-size: 12px;
    display: flex;
    margin-left: auto;
    width: max-content;
  }

  .related-articles {
    margin-bottom: 0;
    padding: 0;
    border-top: 1px solid #d9dadc;

    li {
      list-style-type: none;
      padding-top: 1rem;
      padding-bottom: 0;
      padding-left: 1.5rem;

      &:before {
        content: '';
        position: absolute;
        margin-left: -1.25rem;
        margin-top: 2px;
        display: inline-block;
        height: 1rem;
        width: 1rem;
        background: url('/assets/ui/list-bullet-icon.svg') no-repeat;
        filter: var(--icon-filter);
        background-size: contain;
      }
    }

    .date {
      padding-left: 0.5rem;
      font-size: 0.75rem
    }
  }
  img {
    height: 100% !important;
    object-fit: cover;
  }
`

class ArticleListItem extends Component {
  static propTypes = {
    article: PropTypes.object,
    size: PropTypes.string.isRequired,
    noImage: PropTypes.bool,
    thumbnail: PropTypes.bool,
    lightFont: PropTypes.bool,
    relatedArticleListLimit: PropTypes.number,
    sticky: PropTypes.any,
    className: PropTypes.string,
    metaInsideContent: PropTypes.bool,
    notShown: PropTypes.object,
    label: PropTypes.object,
    sizes: PropTypes.string,
    imageSize: PropTypes.string,
  }

  static defaultProps = {
    thumbnail: false,
  }

  render () {
    const { article, size, className, metaInsideContent, relatedArticleListLimit = 1, notShown, label, sizes, imageSize } = this.props

    if (!article) {
      return null
    } else if (article.isAd) {
      return <GAMNativeAd key={article.id} fullSize={true}/>
    } else {
      return (
        <div styleName={`${size}`} key={article.id}>
          <ArticleItem
            LinkComponent={Link}
            categories={article.categories && article.categories.length ? [article.categories[0]] : []}
            article={article}
            htmlParser={HTMLParser.render}
            notShown={{ ingress: true, topic: true, ...notShown }}
            displaySubscriberIcon={article.forSubscribers}
            relatedArticleListLimit={relatedArticleListLimit}
            className={className}
            metaInsideContent={metaInsideContent}
            label={label}
            sizes={sizes}
            imageSize={imageSize}
          />
        </div>
      )
    }
  }
}

export default track({ gtmContext: ['ArticleListItem'] })(withErrorBoundary(
  ArticleListItem,
  ErrorPlaceholderBox('Artikkelia ei saatu ladattua'),
))
